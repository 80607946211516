import React from "react";
import { Layout, Row, Col, Typography, Space } from 'antd';
import { useTranslation } from "react-i18next";
import { FaInstagram, FaFacebookF, FaTiktok, FaYoutube } from "react-icons/fa";

//css
import "../../Styles/Modules/footer.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import User from "../../Hooks/Logged";
import ModalAuth from "../Auth/ModalAuth";
import Recovery from "../Auth/Recovery";
import Title from "antd/lib/skeleton/Title";
const { Footer } = Layout;
const { Text } = Typography;


class FooterPublic extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			modalAuth: false,
			modalRecovery: false
		}
	}

	render() {

		const { t, changeLanguage } = this.props;

		return (
			<Footer className="footer-public" id={"contact-us"}>
				<Row style={{ justifyContent: "space-between", maxWidth: '1400px', margin: 'auto', marginBottom: "2em" }}>
					<img src="img/zeus_logo.svg" alt="logo Zeus" />
					<Space size={16}>
						<a href="https://www.instagram.com/zeus.agave/" target="_blank">
							<FaInstagram size={25} fill="white" />
						</a>
						<a href="https://www.facebook.com/ZeusElOroAzul/" target="_blank">
							<FaFacebookF size={25} fill="white" />
						</a>
						<a href="https://www.tiktok.com/@zeuseloroazul" target="_blank">
							<FaTiktok size={25} fill="white" />
						</a>
						<a href="https://www.youtube.com/channel/UChJlofA0s0DHhNqQZ_dDcHg" target="_blank">
							<FaYoutube size={25} fill="white" />
						</a>
					</Space>
				</Row>
				<Row style={{ justifyContent: "space-between", marginTop: "1em", maxWidth: "1400px", display: "block", margin: "auto" }} >
					<Col className="footer-description" span={24} style={{ display:"flex", gap: 24, marginBottom: '2em' }}>
						<div> 
							<a href="mailto:soporte@zeusagave.com"><Text className="text-white">soporte@zeusagave.com</Text></a> <br /><br />
							<Text className="text-white">{t("footer.manager")}</Text> <br />
							<a href="mailto:anadavalos@zeusagave.com"><Text className="text-white">anadavalos@zeusagave.com</Text></a> <br />
							<Text className="text-white">USA </Text><a href="tel:+523471073340"><Text className="text-white">MX +52 347 107 3340</Text></a> <br /> <br />
							<Text className="text-white">Jessy Hermosillo (CEO)</Text> <br />
							<a href="mailto:jessyhermosillo@zeusagave.com"><Text className="text-white">jessyhermosillo@zeusagave.com</Text></a>
						</div>
						<div style={{flex: 1, justifyContent: "center" }}>

							<iframe className="gmap_iframe"  style={{minHeight: 150, width: "100%", maxWidth: "1000px", margin: "auto", display: "block" }} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6263.005869524544!2d-102.41276100455796!3d21.032463104750725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84296a965956b47b%3A0xe580034086b9b9e!2sRevoluci%C3%B3n%20Mexicana%2075%2C%20La%20Tinajita%2C%2047147%20San%20Miguel%20el%20Alto%2C%20Jal.%2C%20Mexico!5e0!3m2!1sen!2sus!4v1669319739435!5m2!1sen!2sus">
							</iframe>
						</div>
					</Col>
					<Col xs={24} style={{paddingTop: "1em"}}>
						<Row gutter={[24, 24]}>
							<Col xs={24} md={6}>
								<ul className="mt-1">
									<li><a href="https://play.google.com/store/apps/details?id=com.zeus.agave&pli=1" target="_blank"><img src="/img/google-play.png" alt="GOOGLE" height="36" /></a></li>
								</ul>
								<Text className="text-white">Zeus Oro Azul de los Altos SPR de RL de CV</Text> <br />
								<a href="tel:+523476881291"><Text className="text-white">+52 347 688 12 91</Text></a><br />
								<Text className="text-white">Revolución Mexicana 75, San Miguel El Alto Centro 47140, San Miguel el Alto, Jalisco Mexico</Text><br />
							</Col>
							<Col xs={24} md={6}>
								<Text strong className="text-white font-16">{t("footer.resources")}</Text>
								<ul className="mt-1">
									{/* <li>{t("footer.support")}</li>
									<li>{t("footer.newsletter")}</li> */}
									<li><Link to='/pdfs/CONTRATO-DE-INVERSION-AGAVE.pdf' target="_blank" style={{ color: '#FFF' }}>{t("footer.contract")}</Link></li>
									<li><Link to='/tyc#start' style={{ color: '#FFF' }}>{t("footer.terms")}</Link></li>
								</ul>
							</Col>
							{/* <Col xs={24} md={6}>
								<Text strong className="text-white font-16">{t("footer.resources")}</Text>
								<ul className="mt-1">
									<li>{t("footer.support")}</li>
									<li>{t("footer.newsletter")}</li>
									<li><Link to='/pdfs/CONTRATO-DE-INVERSION-AGAVE.pdf' target="_blank" style={{ color: '#FFF' }}>{t("footer.contract")}</Link></li>
									<li><Link to='/tyc' style={{ color: '#FFF' }}>{t("footer.terms")}</Link></li>
								</ul>
							</Col> */}
							<Col xs={24} md={6}>
								<Text strong className="text-white font-16">{t("footer.support")}</Text>
								<ul className="mt-1">
									{this.props.user ? (
										<li><Link to={this.props.user?.rol_id ? '/admin/dashboard' : '/customer/resume'} style={{ color: '#FFF' }}>{t("footer.account")}</Link></li>
									) : (
										<li style={{ cursor: 'pointer' }} onClick={() => this.setState({ modalAuth: true })}>{t("footer.account")}</li>
									)}
								 	{/* <li>{t("footer.help")}</li>
									<li><a href="#contact-us">
										<Text className="text-white">{t("footer.contact")}</Text>
									</a></li> */}
								</ul>
							</Col>
						</Row>
					</Col>
				</Row>
				{/* <Row style={{ justifyContent: "space-between" }}>
					<Col span={8}>

							<Text style={{ color: "white", fontSize: 20 }} strong>{t("footer.contact")}</Text>


						<img src="img/zeus_logo.svg" alt="logo Zeus" />
					</Col>
					<Col span={16}>
					</Col>
				</Row> */}
				{/* <Row>
					<Col xs={24} className="flex-left-column">
						<img src="img/zeus_logo.svg" alt="logo Zeus" />
					</Col>
				</Row>
				<Row gutter={[24, 24]}>
					<Col xs={24} md={8}>
						<Row gutter={[16, 16]} className="mt-1">
							<Col xs={24}>
								<Space direction="vertical" size={10}>
									<Text strong className="text-white font-16">{t("footer.contact")}</Text>
									<div>
										<Space size={16}>
											<a href="https://www.instagram.com/zeus.agave/" target="_blank">
												<FaInstagram size={25} fill="white" />
											</a>
											<a href="https://www.facebook.com/ZeusElOroAzul/" target="_blank">
												<FaFacebookF  size={25} fill="white" />
											</a>
											<a href="https://www.tiktok.com/@zeuseloroazul" target="_blank">
												<FaTiktok  size={25} fill="white" />
											</a>
											<a href="https://www.youtube.com/channel/UChJlofA0s0DHhNqQZ_dDcHg" target="_blank">
												<FaYoutube  size={25} fill="white" />
											</a>
										</Space>
									</div>
									<div>
										<Text className="text-white">{t("footer.hello")}</Text><br/>
										<Text className="text-white">{t("footer.questions")}</Text>
									</div>
									<div>
										<a href="mailto:soporte@zeusagave.com"><Text className="text-white">soporte@zeusagave.com</Text></a> <br/><br/>
										<Text className="text-white">{t("footer.manager")}</Text> <br/>
										<a href="mailto:josuerosas@zeusagave.com"><Text className="text-white">josuerosas@zeusagave.com</Text></a> <br/>
										<Text className="text-white">Ussa</Text><a href="tel:+5317109287"><Text className="text-white">+53 171 092 87</Text></a> <br/> <br/>
										<Text className="text-white">Jessy Hermosillo (CEO)</Text> <br/>
										<a href="mailto:jessyhermosillo@zeusagave.com"><Text className="text-white">jessyhermosillo@zeusagave.com</Text></a>
									</div>
									<Text className="text-white">Zeus Oro Azul de los Altos SPR de RL de CV</Text>
									<a href="tel:+523476881291"><Text className="text-white">+52 347 688 12 91</Text></a>
									<Text className="text-white">Revolución Mexicana 75, San Miguel El Alto Centro 47140, San Miguel el Alto, Jalisco Mexico</Text>
								</Space>	
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={16}>
						<Row gutter={[24, 24]}>
							<Col xs={24}>
								<div class="mapouter">
									<div class="gmap_canvas">
										<iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6263.005869524544!2d-102.41276100455796!3d21.032463104750725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x84296a965956b47b%3A0xe580034086b9b9e!2sRevoluci%C3%B3n%20Mexicana%2075%2C%20La%20Tinajita%2C%2047147%20San%20Miguel%20el%20Alto%2C%20Jal.%2C%20Mexico!5e0!3m2!1sen!2sus!4v1669319739435!5m2!1sen!2sus">
										</iframe>
									</div>
								</div>
							</Col>
							<Col xs={24}>
								<Row>
									<Col xs={24} md={8}>
										<Text strong className="text-white font-16">{t("footer.feature")}</Text>
										<ul className="mt-1">
											<li><a href="https://play.google.com/store/apps/details?id=com.zeus.agave&pli=1" target="_blank"><img src="/img/google-play.png" alt="GOOGLE" height="36" /></a></li>
										</ul>
									</Col>
									<Col xs={24} md={8}>
										<Text strong className="text-white font-16">{t("footer.resources")}</Text>
										<ul className="mt-1">
											<li>{t("footer.support")}</li>
											<li>{t("footer.newsletter")}</li>
											<li><Link to='/pdfs/CONTRATO-DE-INVERSION-AGAVE.pdf' target="_blank" style={{ color: '#FFF' }}>{t("footer.contract")}</Link></li>
											<li><Link to='/tyc' style={{ color: '#FFF' }}>{t("footer.terms")}</Link></li>
										</ul>
									</Col>
									<Col xs={24} md={8}>
										<Text strong className="text-white font-16">{t("footer.support")}</Text>
										<ul className="mt-1">
											{this.props.user ? (
												<li><Link to={this.props.user?.rol_id ? '/admin/dashboard' : '/customer/resume'} style={{ color: '#FFF' }}>{t("footer.account")}</Link></li>
											):(
												<li style={{cursor: 'pointer'}} onClick={() => this.setState({modalAuth: true})}>{t("footer.account")}</li>
											)}
											<li>{t("footer.help")}</li>
											<li><a href="#ContactUs">
												<Text className="text-white">{t("footer.contact")}</Text>
											</a></li>
										</ul>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={24} md={12} className="mt-3">
						<Text className="text-white">Copyright I See You Technologies</Text>
					</Col>
				</Row> */}
				<ModalAuth
					visible={this.state.modalAuth}
					onClose={() => { this.setState({ modalAuth: false }); }}
					openRecovery={() => this.setState({ modalAuth: false, modalRecovery: true })}
				/>

				<Recovery
					visible={this.state.modalRecovery}
					onClose={() => this.setState({ modalRecovery: false })}
				/>
			</Footer>
		)
	}
}

export default (props) => {
	const { t, i18n } = useTranslation();
	let user = useContext(User)

	const changeLanguage = (lng) => i18n.changeLanguage(lng);

	return <FooterPublic {...props} t={t} changeLanguage={changeLanguage} user={user} />;
}