import React, { Component } from "react";
import { Row, Col, Typography, Button, Modal, Input, Radio, Spin, Form, Divider, message, InputNumber } from 'antd';
import axios from 'axios'
import Decimal from "decimal.js";
import { FaRunning } from "react-icons/fa"
import { IconCategorias } from '../../Widgets/Iconos'
import '../../../Styles/Global/modales.css'
import { max } from "lodash";

const { Title, Paragraph, Text } = Typography;


/**
 *
 *
 * @export
 * @class ModalReventa
 * @extends {Component}
 */
class ModalReventa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tipo: 2,
            moneda: "MXN",
            inversion: {
                _id: null,
                plantas_compradas: 0,
                hacienda_id: {
                    nombre: '',
                    precio: 0
                }
            },
            tipo_cambio: 0,
            max_precio: 0,
            minimo_reventa: 20,
            maximo_reventa: 30,
            minimo_cantidad: 1,
            maximo_cantidad: 20
        }
    }

    formRef = React.createRef();

    async componentDidMount() {
        await this.getTipoCambio()

        if (this.props.reventa) {
            this.getReventa()
        } else {
            this.getInversion()
        }

    }


    /**
   * @method getInversion
   * @description Obtiene la informacion de la inversion
   */
    getInversion = (inversion_id) => {
        this.setState({ loading: true })
        console.log("this.props.inversion", this.props.inversion ?? inversion_id)
        axios.get('/inversion', {
            params: {
                id: this.props.inversion ?? inversion_id,
                movimiento: true
            }
        }).then(response => {
            console.log("response", response.data.data)
            
            if (!this.props.reventa) this.formRef.current.setFieldsValue({
                cantidad: (response.data.data.cantidad - response.data.data.cantidad_revendidas),
                precio: response.data.data.moneda == "USD" ? Decimal(response.data.data.hacienda_id.precio).mul(this.state.minimo_reventa).div(this.state.tipo_cambio).toDecimalPlaces(2).toNumber() : Decimal(response.data.data.hacienda_id.precio).mul(this.state.minimo_reventa).toDecimalPlaces(2).toNumber()
            })
            this.setState({ inversion: response.data.data, moneda: response.data.data.moneda, max_precio: response.data.data.moneda == "USD" ? Decimal(response.data.data.hacienda_id.precio).div(this.state.tipo_cambio).toDecimalPlaces(2).toNumber() : response.data.data.hacienda_id.precio })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    getReventa = () => {
        this.setState({ loading: true })
        console.log("this.props.reventa", this.props.reventa)
        axios.get('/customer/reventa', {
            params: {
                id: this.props.reventa,
            }
        }).then(response => {
            console.log("response get reventa", response)
            this.getInversion(response.data.inversion_original_id)
            this.setState({ tipo: response.data.tipo })
            this.formRef.current.setFieldsValue({
                cantidad: response.data.cantidad,
                precio: response.data.precio_reventa
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method getTipoCambio
   * @description Obtiene el tipo de cambio actual
   */
    getTipoCambio = async() => {
        return axios.get('/plantas')
            .then(res => {

                console.log("get tipo cambio",res)

                this.setState({
                    tipo_cambio: res.data.data.tipo_cambio,
                    minimo_reventa: res.data.data.minimo / 100 + 1 ,
                    maximo_reventa: res.data.data.maximo / 100 + 1,
                    minimo_cantidad: res.data.data.minimo_reventa,
                    maximo_cantidad: res.data.data.maximo_reventa,
                })
                
                

            })

           
    }


    submitForm = (values) => {
        if (this.props.reventa) {
            this.editReventa(values)
        }
        else {
            this.sendPeticion(values)
        }
    }

    editReventa = (values) => {
        let { inversion } = this.state;

        delete values.cantidad

        axios.put('/customer/reventa', {
            reventa_id: this.props.reventa,
            id: inversion._id,
            tipo: this.state.tipo,
            hacienda_id: inversion.hacienda_id._id,
            estatus: 1,
            ...values
        })
            .then(res => {

                message.success('Reventa editada')
                this.props.onClose()
            })
            .catch(error => {
                console.log(error)
                message.error('Ocurrio un error', error.message)
            })
    }



    sendPeticion = (values) => {

        let { inversion } = this.state;
        axios.post('/customer/reventa', {
            id: inversion._id,
            tipo: this.state.tipo,
            hacienda_id: inversion.hacienda_id._id,
            ...values
        })
            .then(res => {
                this.props.onClose()
                message.success('Peticion enviada')
            })
            .catch(error => {

                message.error('Ocurrio un error', error.message)
            })
    }

    render() {

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row>
                        <Col span={24}>
                            <Text className="text-center center">Mi Inversión</Text>
                        </Col>
                        <Col span={8}>
                            <Text className="block text-gray center ">Hacienda</Text>
                            <Text className="center">{this.state.inversion?.hacienda_id?.nombre}</Text>
                        </Col>
                        <Col span={8}>
                            <Text className="block text-gray center ">Cantidad disponible</Text>
                            <Text className="center">{(this.state.inversion?.cantidad - this.state.inversion?.cantidad_revendidas)}</Text>
                        </Col>
                        <Col span={8}>
                            <Text className="block text-gray center ">Precio Hacienda</Text>
                            <Text className="center"> $ {this.state.max_precio} {this.state.moneda} </Text>
                        </Col>
                    </Row>

                    <Divider></Divider>


                    <Form
                        layout="vertical"
                        ref={this.formRef}
                        className="mt-1"
                        onFinish={this.submitForm}>
                        <Form.Item
                            name="cantidad"

                            label="Cantidad de Plantas"
                            rules={[
                                { required: true, message: "Ingrese el monto de plantas deseado" },
                                ...(this.props.reventa != null) ? [] : [{
                                    type: 'number',
                                    max: ((this.state.inversion?.cantidad - this.state.inversion?.cantidad_revendidas) < this.state.maximo_cantidad ? (this.state.inversion?.cantidad - this.state.inversion?.cantidad_revendidas) : this.state.maximo_cantidad) ,
                                    message: `La cantidad de plantas no puede ser mayor a ${(this.state.inversion?.cantidad - this.state.inversion?.cantidad_revendidas) < this.state.maximo_cantidad ? (this.state.inversion?.cantidad - this.state.inversion?.cantidad_revendidas) : this.state.maximo_cantidad}`
                                }],
                                ...(this.props.reventa != null) ? [] : [{
                                    type: 'number',
                                    min: (this.state.minimo_cantidad),
                                    message: `La cantidad de plantas no puede ser menor a ${this.state.minimo_cantidad}`
                                }],
                                
                            ]} >
                            <InputNumber controls={false} disabled={(this.props.reventa != null)} className="input-landing width-100" />
                        </Form.Item>

                        <Form.Item
                            name="precio"
                            label="Precio de Venta"
                            rules={[
                                { required: true, message: "Ingrese el monto de venta deseado   " },
                                {
                                    type: 'number',
                                    min: (this.state.max_precio * this.state.minimo_reventa),
                                    message: `El precio de venta no puede ser menor a $ ${(this.state.max_precio * this.state.minimo_reventa).toMoney(true)} ${this.state.moneda}`
                                },
                                {
                                    type: 'number',
                                    max: (this.state.max_precio * this.state.maximo_reventa),
                                    message: `El precio de venta no puede ser mayor a $ ${(this.state.max_precio * this.state.maximo_reventa).toMoney(true)} ${this.state.moneda}`
                                }
                            ]} >
                            <InputNumber type="number" className="input-landing width-100" />
                        </Form.Item>


                        <Form.Item className="center text-center">
                            <Button htmlType="submit" type="primary">{this.props.reventa ? "Editar Reventa" : "Publicar en Venta"}</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    console.log("props", props)

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onClose}
        onClosed={onClose}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Revender</Title>
        </div>
        <ModalReventa {...props} />
    </Modal>

}