import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';

// componentes
import Transacciones from '../../Components/Admin/Transacciones/Transacciones'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
 export default function RouterTransacciones(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Transacciones  {...props}  />} />
        </Routes>
    )
}

