import React, { Component } from "react";
import { Layout, Row, Col, message, PageHeader, Tag, Button, Space, Typography, List, Card, } from 'antd';
import { IconEye } from '../../Widgets/Iconos'

import ModalDetalle from './ModalDetalle'


const { Content } = Layout;
const { Text, } = Typography;

const axios = require("axios").default;
const moment = require('moment');



const colors = ["#FA9100", "#FFDE59", "#87d068", "#ff4d4f"];
/**
 *
 *
 * @class Ventas
 * @extends {Component}
 */
export default class Ventas extends Component {
    constructor(props) {
        super(props);
        this.state = {

            data: [],
            sending: false,
            limit: 10,
            total: 1,
            page: 1,
            search: '',


            modaldetalle_visible: false,
            venta_id: undefined,
            venta_email: undefined,
        }
    }

    /**
    * @memberof Ventas
    *
    * @method componentDidMount
    * @description  Carga los datos de la vista
    *
    *
    **/
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getVentas();
    }



    /**
    * @memberof Ventas
    *
    * @method   getVentas
    * @description  Metodo que realiza el paginado de las Ventas
    * @param page  Number con la pagina a buscar, 
    *
    **/
    getVentas = (page = this.state.page) => {
        axios.get('/ventas', {
            params: {
                page: page,
                limit: this.state.limit
            }
        }).then(res => {
            let data = res.data.data.itemsList;
            let paginator = res.data.data.paginator
            
            this.setState({
                data: data,
                page: paginator.currentPage,
                total: paginator.itemCount,
                limit: paginator.perPage,
            });

        })
    }



    /**
     *
     *
     * @memberof Ventas
     * @description Renderiza el Tag correspondiente al status de la venta
     * @param status  1 = En proceso, 2= Aceptada , 3= Rechazada
     */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "Aceptada";
                break;
            case 3:
                text = "Rechazada";
                break;

            default:
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }



    render() {

        return (
            <Layout>
                <PageHeader className="admin-page-header" title="Ventas" />
                <Content className="admin-content content-bg pd-1">
                    <List
                        loading={this.state.loading}
                        className="component-list"
                        itemLayout="horizontal"
                        locale={{ emptyText: "Sin ventas" }}
                        dataSource={this.state.data}
                        pagination={{
                            onChange: (page) => this.getVentas(page),
                            current: this.state.page,
                            pageSize: this.state.limit,
                            total: this.state.total,
                            showSizeChanger: false,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left"

                        }}
                        header={<Row className="width-100 pr-1 pl-1" >
                            <Col span={8} className="center">
                                <Text strong>Usuario</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text strong>Total</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text strong>Fecha</Text>
                            </Col>
                            <Col span={4} className="center">
                                <Text strong>Estatus</Text>
                            </Col>

                        </Row>
                        }

                        renderItem={item => (
                            <List.Item className="component-list-item">
                                <Card className="card-list">
                                    <Row className="width-100 ">
                                        <Col xs={12} md={8} className="center">
                                            <Text >
                                                {item.cliente_id != null ?
                                                    `${item.cliente_id?.usuario_id?.nombres} 
                                                     ${item.cliente_id?.usuario_id?.apellido_paterno}
                                                     ${item.cliente_id?.usuario_id?.apellido_materno}`
                                                    : 'Sin Cliente Asignado'}
                                            </Text>
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            <Text >$ {item.total?.toMoney && item.total?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            <Text >{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            {this.renderTag(item.status)}
                                        </Col>
                                        <Col xs={24} md={4} className="center">
                                            <Space direction="horizontal">
                                                <Button
                                                    type="primary"
                                                    className="btn-primary"
                                                    title="Detalle Venta"
                                                    onClick={() => this.setState({ modaldetalle_visible: true, venta_id: item._id })}
                                                    icon={<IconEye />}>
                                                </Button>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </Content>
                <ModalDetalle
                    visible={this.state.modaldetalle_visible}
                    id={this.state.venta_id}
                    close={() => {
                        this.setState({ modaldetalle_visible: false, venta_id: undefined })
                    }}
                />
            </Layout>
        )
    }
}

