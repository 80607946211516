import React, { Component } from 'react';
import { Row, Col, Typography, Button, Popconfirm, Spin, PageHeader, Layout, Space, message, List, Card, Dropdown, Menu, } from 'antd'
import { EllipsisOutlined, FileSyncOutlined, CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import { IconBtnDelete, IconBtnEdit, IconEye, IconArrowBack, IconMail } from '../../Widgets/Iconos'
import { CardInfoHacienda, CardPlantasValores } from '../../Widgets/Cards';
import FloatingButton, { FloatingStack } from '../../Widgets/FloatingButton/FloatingButton';
import ModalResumen from './Modales/ModalResumen';
import { useNavigate, useParams } from 'react-router-dom';
const { Content } = Layout;
const { Title, Text } = Typography

/**
 *
 *
 * @export
 * @class HaciendaCompleta
 * @extends {Component}
 * @description Vista donde se listan todos los HaciendaCompleta
 */
class HaciendaCompleta extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    /**
     *Creates an instance of HaciendaCompleta.
     * @param {*} props
     * @memberof HaciendaCompleta
     */
    constructor(props) {
        super(props)
        this.state = {
            hacienda: {},
            resumenes: [],
            loading: false,
            page: 1,
            limit: 10,
            total: 0,
            plantas: {
                nuevo: {
                    precio: undefined,
                    cantidad: undefined
                },
                actual: {
                    precio: undefined,
                    cantidad: undefined
                }
            }
        }
    }


    componentDidMount() {
        
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getHacienda()
        this.getResumenes()
        this.getPlantasValues()
    }

    /**
    * @memberof HaciendaCompleta
    * @method getHacienda
    * @description Obtiene la informacion de la hacienda
    */
    getHacienda = () => {
        axios.get('/hacienda', {
            params: {
                _id: this.props.params.hacienda_id,
            }
        }).then(response => {
            
            this.setState({ hacienda: response.data.data })

        }).catch(error => {
            
            message.error('Error al cargar la Hacienda')
        })
    }

    /**
     *
     *
     * @memberof HaciendaCompleta
     * @method getResumenes
     * @param {object} {page, limit, search} - Datos de paginado y busqueda
     * @description Obtiene los resumenes de la hacienda
     */
    getResumenes = ({
        page,
        limit,
        search,
    } = this.state) => {
        axios.get('/resumenes', {
            params: {
                hacienda_id: this.props.params.hacienda_id,
                tipo: 2,
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            
            console.log('data mov',data)
            this.setState({
                total: data.data.total,
                resumenes: data.data.data
            })

        }).catch(error => {
            
            console.log('error ',error)
            message.error('Error al cargar Movimientos')
        })
    }

    /**
     *
     *
     * @memberof HaciendaCompleta
     * @method getPlantasValues
     * @description obtiene los valores de kilo-precio y kilos-planta
     */
    getPlantasValues = () => {
        axios.get('/plantas')
            .then(({ data }) => {
                
                this.setState({
                    plantas: {
                        nuevo: {
                            cantidad: data.data.kgs_planta,
                            precio: data.data.precio_kilo
                        },
                        actual: {
                            cantidad: data.data.kgs_planta,
                            precio: data.data.precio_kilo
                        }
                    }
                })

            }).catch(error => {
                
                message.error('Error al cargar los valores de plantas')
            })
    }

    /**
     *
     *
     * @memberof HaciendaCompleta
     * @method saveChanges
     * @description guarda los cambios en los valores de plantas
     */
    saveChanges = () => {

        const { plantas } = this.state


        axios.post('/plantas/add', {
            precio_kilo: plantas.nuevo.precio,
            kgs_planta: plantas.nuevo.cantidad
        })
            .then(res => {
                
                message.success("Cambios guardados")
            })
            .catch(error => {
                
                message.error("Error al guardar cambios")
            })

    }

    /**
     *
     *
     * @param {*} id identificador del resumen
     * @memberof HaciendaCompleta
     * @method showReporte
     * @description Abre un link del reporte en una nueva pestaña
     */
    showReporte = (id) => {
        let link = new URL(axios.defaults.baseURL + '/resumenes/reporte');
        link.searchParams.append('Authorization', sessionStorage.getItem('token'))
        link.searchParams.append('id', id)

        window.open(link.href, '_blank')
    }

    /**
     *
     *
     * @param {*} [id=null] identificador del resumen (Opcional)
     * @memberof HaciendaCompleta
     * @method sendReportes
     * @description Envia una notificacion por correo a cada cliente
     */
    sendReportes = (id = null) => {
        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            const ids = id ? [id] : this.state.resumenes.map(resumen => resumen._id)

            axios.post('/resumenes/reportar', {
                ids
            })
                .then(values => {
                    message.success(id ? "Reporte enviado" : "Reportes enviados")
                })
                .catch(error => {
                    message.error("Error al enviar reporte" + (id ? "" : "s"))
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        })
    }

    /**
     *
     *
     * @memberof HaciendaCompleta
     * @method activeHacienda
     * @description Activa nuevamente la hacienda
     */
    activeHacienda = () => {
        if (this.state.loading) return

        this.setState({ loading: true }, () => {

            axios.put('/resumenes/close-hacienda', {
                hacienda_id: this.props.params.hacienda_id,
            })
                .then(values => {
                    message.success("Hacienda Activada")
                    this.props.navigate('/admin/haciendas')
                })
                .catch(error => {
                    message.error("Error al activar hacienda")
                })
                .finally(() => {
                    this.setState({ loading: false })
                })
        })
    }

    render() {

        const { hacienda } = this.state

        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Hacienda Completa"
                        backIcon={<IconArrowBack />}
                        onBack={this.back}
                        extra={<Button
                            type="primary"
                            className='btn-secondary'
                            icon={<CheckOutlined style={{ color: '#FFFFFF' }} />}
                            onClick={() => this.activeHacienda()}
                        >
                            Activar Hacienda
                        </Button>}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <CardInfoHacienda
                                    extra={<Dropdown overlay={<Menu>
                                        <Menu.Item onClick={() => this.setState({ modalHacienda: true })}>
                                            Editar
                                        </Menu.Item>
                                    </Menu>}>
                                        <EllipsisOutlined />
                                    </Dropdown>}
                                    nombre={hacienda?.nombre}
                                    descripcion={hacienda?.descripcion}
                                    fecha_creacion={hacienda?.fecha_creacion}
                                    hectareas={hacienda?.hectareas}
                                    rendimiento={0}
                                />
                            </Col>
                            <Col span={8} style={{ display: 'flex' }}>
                                <CardPlantasValores
                                    setValues={({ cantidad, precio }) => this.setState(state => {
                                        state.plantas = {
                                            ...state.plantas,
                                            nuevo: {
                                                cantidad,
                                                precio
                                            }
                                        }

                                        return state
                                    })}
                                    reset={() => {
                                        this.setState(state => {
                                            state.plantas = {
                                                ...state.plantas,
                                                nuevo: state.plantas.actual
                                            }

                                            return state
                                        })
                                    }}
                                    values={{
                                        cantidad: this.state.plantas.nuevo.cantidad,
                                        precio: this.state.plantas.nuevo.precio
                                    }}
                                />
                            </Col>
                        </Row>

                        <Title level={3} className="mt-1 text-cyan">Inversiones Realizadas</Title>

                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Inversiones" }}
                            dataSource={this.state.resumenes}
                            pagination={{
                                onChange: (page) => this.setState({ page }, () => this.getResumenes()),
                                current: this.state.page,
                                pageSize: this.state.limit,
                                total: this.state.total,
                                showSizeChanger: false,
                                hideOnSinglePage: false,
                                position: 'bottom',
                                className: "flex-left"

                            }}
                            renderItem={({ _id, cliente, plantas, invertido, ganancia, obtenido }) => {

                                const { nuevo, actual } = this.state.plantas
                                const values = {
                                    ganancia: undefined,
                                    obtenido: undefined
                                }

                                if (
                                    (nuevo.precio && nuevo.cantidad)
                                    &&
                                    (nuevo.precio !== actual.precio || nuevo.cantidad !== actual.cantidad)
                                ) {
                                    const subGanancia = nuevo.precio * nuevo.cantidad * plantas
                                    const comision = subGanancia * 0.35

                                    values.obtenido = subGanancia
                                    values.ganancia = subGanancia - comision
                                }

                                return (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row className="width-100 " gutter={[0, 12]}>
                                                <Col xs={12} md={4} className="">
                                                    <Text className="">{cliente?.usuario?.nombres} {cliente?.usuario?.apellido_paterno} {cliente?.usuario?.apellido_materno}</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text className="text-gray">{plantas.toMoney && plantas.toMoney(true)}</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text className="text-gray">$ {invertido.toMoney && invertido.toMoney(true)} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text className="text-cyan">$ {values.obtenido?.toMoney(true) || obtenido.toMoney(true)} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Text className="text-cyan">$ {values.ganancia?.toMoney(true) || ganancia.toMoney(true)} MXN</Text>
                                                </Col>
                                                <Col xs={12} md={4} className="center">
                                                    <Space wrap>
                                                        <Button type="primary" className='btn-primary' icon={<IconMail />} onClick={() => this.sendReportes(_id)} />
                                                        <Button type="primary" className='btn-primary' icon={<IconEye />} onClick={() => this.showReporte(_id)} />
                                                        <Button type="primary" className='btn-primary' icon={<IconBtnEdit />} onClick={() => this.setState({ modal_visible: true, resumen_id: _id })} />
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )
                            }}
                        />
                        <FloatingStack>
                            <FloatingButton title="Guardar Cambios" item icon={<CheckOutlined style={{ color: '#ffffff' }} />} onClick={this.saveChanges} />
                            <FloatingButton title="Mandar Reportes" item icon={<IconMail />} onClick={() => this.sendReportes()} />
                        </FloatingStack>
                    </Content>
                </Spin>

                <ModalResumen
                    visible={this.state.modal_visible}
                    resumen_id={this.state.resumen_id}
                    onCancel={() => this.setState({ modal_visible: false, resumen_id: undefined })}
                    onSuccess={() => this.setState({ modal_visible: false, resumen_id: undefined }, () => {
                        this.getResumenes()
                    })}
                />
            </>
        )
    }
}


export default function (props){

    const params = useParams()
    const navigate = useNavigate()

    return <HaciendaCompleta  {...props} params={params} navigate={navigate}/>
}
