import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Modal, Spin, Typography, message} from 'antd';
import { useNavigate } from "react-router-dom";
import { User, SetUser } from '../../Hooks/Logged';
import FormatedInput from '../Widgets/Inputs/FormatedInput';

const axios = require("axios").default;
const { Title } = Typography

/**
 *
 *
 * @class UpdatePassword
 * @extends {Component}
 */
class UpdatePassword extends Component {



    /**
     *Creates an instance of UpdatePassword.
     * @param {*} props
     * @memberof UpdatePassword
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            isAdmin: false,
            isLogged: false,
            loading: false,
            isRecovery: false,
            isRecoveryLoading: false,
            redirect: false,
            form: {}
        }
    }

    recoveryPassword = React.createRef();

    componentDidMount(){
        console.log("props", this.props)
        // email: this.props.searchParams.get("email"),
        // token: this.props.searchParams.get("token"),
    }


     /**
     * @memberof Login
     *
     * @method redirectTo
     * @description  Redirecciona a cierto link.
     *
     **/
    redirectTo = (to) => {
        this.props.navigate(to)
    };


    /**
     *
     *
     * @memberof UpdatePassword
     * @method onFinish
     * @param {object} values - Objecto que contiene los valores del formulario
     * @description Actualiza la clave de acceso
     */
    onFinish = async values => {
        console.log("values", values)
        this.setState({loading: true})
        axios.put('/password/recovery/update', {...values, token: this.props.token})
            .then(({ data, headers }) => {
                const { setUser } = this.props;
                message.success("Inicie sesión con su nueva contraseña")
                this.props.onLogin()
            })
            .catch((error) => {
                this.setState({loading: false})
                Modal.error({
                    title: "No fue posible actualizar.",
                    content: "El token es invalido o el correo no existe."
                })
            }).catch(()=>this.setState({loading: false}))
    };
    render() {
        return (
            <>  
                <Title level={3} className="mt-2 mb-2 text-center">Nueva Contraseña</Title>
                <Spin spinning={this.state.loading}>
                    <Form 
                        name="basic" 
                        layout={"vertical"} 
                        onFinish={this.onFinish} 
                        initialValues={this.props} 
                        ref={this.recoveryPassword}
                    >
                        <Form.Item name="token" noStyle >
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            label="Correo Electrónico"
                            name="email"
                            rules={[{
                                required: true,
                                message: 'Para poder iniciar sesión, debe haber ingresado su correo electrónico'
                            }]}
                        >
                            <FormatedInput trim className="input-login" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu  contraseña'
                                },
                                {
                                    min: 8,
                                    message: 'La contraseña debe de tener mínimo 8 caracteres'
                                },
                                {
                                    pattern: new RegExp("^(?=.*[0-9])(?=.*[a-z]|[A-Z]).+$"),
                                    message: 'Debe tener números y letras'
                                },
                                {
                                    pattern: new RegExp("^(?=.*[~`!@#$%^&*()\-_+={}[\]\|\/:;\"'<>,.?¿¡]).+$"),
                                    message: 'Debe de tener un caracter especial'
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password className="input-login" />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirmar Contraseña"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, confirme su contraseña!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Las contraseñas no coinciden.');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password className="input-login" />
                        </Form.Item>

                        <Form.Item >
                            <Button type="primary" htmlType="submit" block size="large" className="button-login">
                                Cambiar Contraseña
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </>
        )
    }
}




export default function (props) {
    const { visible = false, onClose = () => { } } = props

    let user = useContext(User)
    const setUser = useContext(SetUser)
    const navigate = useNavigate()

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        width={450}
        className="modal-border"
    >
        <Row className="mb-1">
            <Col span={12} className="flex-left">
                <img src="/img/zeus_logo.svg" width="150" />
            </Col>
        </Row>
        <UpdatePassword {...props} user={user} setUser={setUser} navigate={navigate}/>
           
    </Modal>        
}
