import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber, } from 'antd';
import ColorPicker from '../../Widgets/ColorPicker';

const { Title } = Typography;
const axios = require('axios').default;


/**
 * @class ModalCuenta
 * @description Modal para el CRUD de Cuentas
 */
class ModalCuenta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
        }
    }

    ModalCuentas = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
            if(this.props.cuenta !== undefined) this.getCuenta() 
    }


    /**
     * @memberof ModalCuenta
     * @method onFinish
     * @description Cuando se envia el formulario
     * 
     */
    onFinish = (values) => {
        if (this.props.cuenta != undefined) {
            this.updateCuenta(values)
        } else {
            this.addCuenta(values)
        }
    }


    /**
     * @memberof ModalCuenta
     * @method addCuenta
     * @description Agregamos una Cuenta
     */
    addCuenta = (values) => {
        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/cuenta/add', {
                ...values
            }).then(response => {
                message.success('Cuenta creada')
                this.props.onCancel()
            }).catch(error => {
                
                message.error('Error al crear la cuenta.')
            })
        })
    }

    /**
     * @memberof ModalCuenta
     * @method updateCuenta
     * @description Actualizamos los valores de la Cuenta
     */
    updateCuenta = (values) => {
        this.setState({ loading: true })
        axios.put('/cuenta/update', {
            ...values,
            id: this.props.cuenta,
        }).then(response => {
            message.success('Cuenta Actualizada')
            this.props.onCancel()
        }).catch(error => {
            message.error('Error al actualizar la Cuenta')
            
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalCuenta
     * @method updateCuenta
     * @description Actualizamos los valores de la Cuenta
     */
    getCuenta = () => {
        axios.get('/cuenta', {
            params: {
                id: this.props.cuenta,
            }
        }).then(response => {
            
            this.setState({
                cuenta: response.data.data,
            })
            this.ModalCuentas.current.setFieldsValue({
                ...response.data.data
            })

        }).catch(error => {
            

        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <Form
                layout="vertical"
                ref={this.ModalCuentas}
                onFinish={this.onFinish}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Nombre de la Cuenta"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre"
                                }]}
                            >
                                <Input className="input-landing" placeholder="Nombre" maxLength={50}></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Titular de la Cuenta"
                                name="titular"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre del titular"
                                }]}
                            >
                                <Input className="input-landing" placeholder="Titular" maxLength={50} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Banco"
                                name="banco"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Nombre del Banco"
                                }]}
                            >
                                <Input className="input-landing" placeholder="Banco" maxLength={70}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Número de Cuenta"
                                name="cuenta"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Número de Cuenta"
                                }]}
                            >
                                <Input className="input-landing" placeholder="Número" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row >
                        <Col span={24} className="center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Cuenta</Title>
        </div>
        <ModalCuenta {...props} />
    </Modal>

}