

import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Spin, Typography, List } from 'antd';

const axios = require('axios').default;
const { Title } = Typography;
const { TextArea } = Input;
const formItemLayout = { labelCol: { span: 24, }, wrapperCol: { span: 24, } };


/**
 *
 *
 * @class FormCategorias
 * @extends {React.Component}
 * @description Formulario de categorias
 */
class FormCategorias extends Component {


    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }


    /**
     * @methodOf FormCategorias
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id != null) {
            this.getCategoria()
        }
    }




    /**
    * @memberof FormCategorias
    *
    * @method getCategoria
    * @description  trae la informacion de una categoria
    *
    **/
    getCategoria = (values) => {
        this.setState({ loading: true })
        axios.post('/categorias/get', {
            id: this.props.id,
            ...values
        }).then(response => {
            this.formRef.current?.setFieldsValue({
                nombre: response.data.data.nombre,
                descripcion: response.data.data.descripcion,
            })

        }).catch(error => {
            message.error('Error al obtener la informacion')
            
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberof FormCategorias
    *
    * @method update
    * @description  Actualiza la informacion de una categoria
    *
    **/
    update = (values) => {
        axios.put('/categorias/update', {
            id: this.props.id,
            nombre: values.nombre,
            descripcion: values.descripcion,
        })
            .then((response) => {
                
                message.success("Categoria Actualizada");
                this.props.onClose()
            })
            .catch((error) => {
                
                Modal.warning({
                    title: 'Error',
                    content: error.response.data.message
                });
            })
    }

    /**
    * @memberof FormCategorias
    *
    * @method save
    * @description  Añade una categoria
    **/
    save = (values) => {
        axios.post('/categorias/add', {
            nombre: values.nombre,
            descripcion: values.descripcion,
        })
            .then((response) => {
                message.success("Categoría creada");
                this.props.onClose();
            })
            .catch((error) => {
                
                Modal.warning({
                    title: 'Error',
                    content: error.response.data.message

                });
            })
    }


    /**
   *
   *
   * @memberof FormCategorias
   * 
   * @method onFinish
   * @description Cuando se guarda eltamano
   */
    onFinish = values => (this.props.id !== null) ? this.update(values) : this.save(values)

    render() {

        const { loading } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">  {`${this.props.id ? 'Editar' : 'Nueva'}  Categoria`}</Title>
                <Form  {...formItemLayout} layout="vertical" ref={this.formRef} onFinish={this.onFinish}   >
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[{ required: true, message: "Por favor, ingrese el nombre" }]}
                    >
                        <Input placeholder="Nombre" maxLength={50}></Input>
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="descripcion"
                        rules={[{ required: true, message: "Por favor, ingrese la descripción" }]}
                    >
                        <TextArea placeholder="Descripción" maxLength={100}></TextArea>
                    </Form.Item>

                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary">
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {

    let { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            className="modal-zeus"

        >
            <FormCategorias {...props} />
        </Modal>
    )
}