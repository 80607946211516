import React, { Component, useState, useEffect, useContext } from "react";
import { Row, Col, Typography, Form, Input, Select, message, DatePicker, Popover } from 'antd';

import axios from "axios";
import { User, SetUser } from '../../../Hooks/Logged'
import FormatedInput from "../../Widgets/Inputs/FormatedInput";

const { Title, Text } = Typography;

/**
 * @class Personal
 * @description Modal que contiene los steps para buscar un credito
 */
const Personal = React.forwardRef((props, ref) => {

    let [form] = Form.useForm()
    const setUser = useContext(SetUser)
    let user = useContext(User)


    const submit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        axios.put('/usuarios/update-registro', {
            status: 3,
            id: user._id,
            ...values
        }).then(({data, headers}) => {  
            setUser(data.user);
            props.next()
        })
       
    }




    React.useImperativeHandle(ref, () => ({ submit }));

    return (
        <>
            <Form
                layout={"vertical"}
                form={form}
                onFinish={onFinish}
                style={{ minHeight: '480px' }}
                initialValues={{ ...props.data, ...user }}
            >
                <Row justify="center">
                    <Col span={24} className="flex-column mb-3 mt-3">
                        <Title level={3} className="modal-title">Información personal</Title>
                        <Text className="modal-subtitle">Toda la información de este formulario, solo se te pedirá 1 vez para la creación de tu cuenta.</Text>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name="nombre"
                            label="Nombre"
                            rules={[{
                                required: true,
                                message: 'Ingresa tu nombre',
                            }]}
                        >
                            <FormatedInput trim multiple maxLength={50}/>
                        </Form.Item>

                        <Form.Item
                            name="apellido_paterno"
                            label="Apellido Paterno"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu apellido paterno',
                                },
                            ]}
                        >
                            <FormatedInput trim multiple maxLength={60}/>
                        </Form.Item>
                        <Form.Item
                            name="apellido_materno"
                            label="Apellido Materno"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu apellido materno',
                                },
                            ]}
                        >
                            <FormatedInput trim multiple maxLength={60}/>
                        </Form.Item>
                        <Form.Item
                            name="fecha_nacimiento"
                            label="Fecha de Nacimiento"
                            className="width-100 "
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu Fecha de Nacimiento',
                                },
                            ]}
                        >
                            <DatePicker className=" width-100 " />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )

})

export default Personal

