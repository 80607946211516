import React, { Component, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, Button, Modal, Divider, Typography, Spin, Checkbox, message } from 'antd';
import { User, SetUser } from '../../Hooks/Logged';
import axios from 'axios'
import FormatedInput from '../Widgets/Inputs/FormatedInput';


const { Title, Paragraph } = Typography;

/**
 *
 * @function Login
 * @description Vista para hacer login
 * @export
 */
class Login extends Component {


    constructor(props) {
        super(props)
        this.state = {
            log: false,
            loading: false,
        }
    }

    componentDidMount() {
        if (this.props.user?.rol_id) {
            this.props.navigate("/admin/dashboard")
        } else if (this.props.user) {
            this.props.navigate("/customer/resume")
        }
    }

    /**
     * @memberof Login
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al usuario
     *
     * @returns response (array)
     **/
    handleSubmit = (values) => {
        console.log("values", values)
        this.setState({ loading: true })
        axios.post('/login', values)
            .then(({ data, headers }) => {

                const { setUser } = this.props;

                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.post["Authorization"] = headers.authorization;
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

                setUser(data.user);


                //Si no tiene un rol asignado se tomara como un usuario cliente
                if (!data.user.rol_id) {
                    if (data.user.status < 4) {

                        /** 
                         * 0 -> Desactivado
                         * 1 -> Normal 
                         * 2 -> Lleno información básica
                         * 3 -> Llenó información persona
                         * 4 -> Llenó Dirección
                         */
                        switch (data.user.status) {
                            case 0:
                                message.error("No es posible iniciar sesión")
                                break;
                            case 1:
                                this.redirectTo('/customer/resume')
                                break;
                            case 2:
                                message.info("Es necesario completar tus datos")
                                this.props.changeView(2)
                                break;
                            case 3:
                                message.info("Es necesario completar tus datos")
                                this.props.changeView(3)
                                break;
                        }
                        // this.props.changeView(2)
                    } else {
                        this.redirectTo('/customer/resume')
                    }
                } else {
                    this.redirectTo('/admin/dashboard')
                }

            })
            .catch((error) => {

                console.log("error ", error )
                const { response } = error
                Modal.error({
                    title: 'Error',
                    content: response.data?.message
                });

            })
            .finally(e =>                 this.setState({ loading: false }))
    };


    /**
    * @memberof Login
    *
    * @method redirectTo
    * @description  Redirecciona a cierto link.
    *
    **/
    redirectTo = (to) => {
        this.props.navigate(to)
    };

    render() {
        return (
            <Row justify="center">
                <Col span={24} className="login-form">
                    <Row justify="center" className="">
                        <Col xs={{ span: 23 }} md={18} className="">
                            <Title level={3} className="text-center">¡Bienvenido!</Title>
                            <Paragraph className="text-center">Para comenzar, inicia sesión o regístrate.</Paragraph>
                            <Spin spinning={this.state.loading}>
                                <Form
                                    initialValues={{
                                        keep_session: true
                                    }}
                                    ref={e => window.e = e}
                                    onFinish={this.handleSubmit}
                                    layout={"vertical"}
                                    className="login-form"
                                    requiredMark={false}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{
                                            required: true,
                                            message: 'Por favor ingresa tu email'
                                        }, {
                                            pattern: /[^A-Z].+/,
                                            message: 'El email es sin mayusculas'
                                        }]}
                                    >
                                        <FormatedInput trim multiple placeholder="E-mail" className="hm-login-input" />
                                    </Form.Item>
                                    <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Por favor ingresa tu  contraseña' }]}

                                        extra={<Form.Item noStyle name="keep_session" valuePropName='checked'><Checkbox style={{ marginTop: 10 }}>Mantener la sesión iniciada</Checkbox></Form.Item>}
                                    >
                                        <Input type="password" placeholder="Contraseña" className="hm-login-input" />
                                    </Form.Item>
                                    <div className="text-center text-purple hover" onClick={() => this.props.openRecovery()}>¿Olvidaste tu contraseña?</div>
                                    <div className="text-center hover text-purple" onClick={() => this.props.changeView()}>¿No tienes Cuenta? ¡Registrate ahora!</div>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="mt-2" block >Iniciar sesión </Button>
                                    </Form.Item>
                                </Form>
                                <Divider />
                                {/* <Button className="mb-2" block>Iniciar Sesión con facebook </Button> */}
                            </Spin>
                        </Col>
                    </Row>
                </Col>
            </Row>

        )
    }
}

export default function (props) {

    let user = useContext(User)
    const setUser = useContext(SetUser)
    const navigate = useNavigate()

    return <Login {...props} user={user} setUser={setUser} navigate={navigate} />
}