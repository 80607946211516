import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Drawer, Space, InputNumber, DatePicker, Upload, Select, Empty } from 'antd';

//componentes
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { SelectCliente,SelectInversiones } from '../../Widgets/Inputs/Inputs';

const { Title, Text } = Typography;
const { Option } = Select;
const moment = require('moment');
const axios = require('axios').default;

/**
 *
 *
 * @class ModalTransaccion
 * @extends {Component}
 */
class ModalTransaccion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            estatus_disabled: true,

            cliente_visible: true,
            inversion_visible: true,
            data: false,
            return: false,
            transaccion: {},
            haciendas: [],
            files_to_delete: [],

            inversiones: {
                selected: undefined,
                page: 1,
                limit: 10,
                search: undefined,
                data: []
            },

            clientes: {
                _id: undefined,
                page: 1,
                limit: 10,
                search: undefined,
                data: []
            }
        }
    }

    modalTransaccion = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.transaccion_id) {
            this.getTransaccion()
        }
    }

    /**
    * @method getTransaccion
    * @description Obtiene la informacion del transaccion
    */
    getTransaccion = () => {
        this.setState({ loading: true })
        axios.get('/transaccion', {
            params: {
                id: this.props.transaccion_id,
                movimiento: true
            }
        }).then(response => {

            console.log('res transaccion', response)
            let transaccion = response.data.data

            let cliente_visible = transaccion.cliente_id != null,
                inversion_visible = transaccion.inversion_id != null;
            


            let clientes = { data: transaccion?.cliente_id ? [transaccion?.cliente_id] : [] }
            let inversion = { data: transaccion?.inversion_id ? [transaccion?.inversion_id] : [] }


            this.setState({
                cliente_visible,
                inversion_visible,
                clientes: clientes,
                inversiones: inversion,
                transaccion: response.data.data,
                precio_planta: transaccion.hacienda_id?.precio,
                comprobantes: transaccion.comprobantes,
                estatus_disabled: transaccion.inversion_id ? false : true,
            })


            if (transaccion.pos == true) {
                this.modalTransaccion?.current?.setFieldsValue({
                    ...transaccion,
                    cliente_id: transaccion?.cliente_id?._id,
                    fecha: moment(transaccion.fecha),
                    comprobantes: transaccion.comprobantes.map((img, index) => ({
                        uid: index,
                        name: img.filename,
                        filename_server: img.filename_server,
                        status: 'done',
                        url: `${axios.defaults.baseURL}/upload/${img.filename_server}`,
                    }))
                })
            }
            else {
                this.modalTransaccion?.current?.setFieldsValue({
                    ...transaccion,
                    cliente_id: transaccion.cliente_id?._id ? {
                        value: transaccion.cliente_id?._id,
                        key: transaccion.cliente_id?._id,
                        label: transaccion.cliente_id?.nombre + " " + transaccion.cliente_id?.apellido_paterno + " " + transaccion.cliente_id?.apellido_materno
                    } : null,
                    inversion_id: transaccion.inversion_id?._id ? {
                        value: transaccion.inversion_id?._id,
                        key: transaccion.inversion_id?._id,
                        label: transaccion.inversion_id?.folio
                    } : null,
                    fecha: moment(transaccion.fecha),
                    hacienda_id: transaccion.hacienda_id?._id,
                    cantidad: transaccion?.movimiento?.cantidad,
                    comprobantes: transaccion.comprobantes.map((img, index) => ({
                        uid: index,
                        name: img.filename,
                        filename_server: img.filename_server,
                        status: 'done',
                        url: `${axios.defaults.baseURL}/upload/${img.filename_server}`,
                    }))
                })
            }


        }).catch(error => {
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method updateTransaccion
    * @description Actualiza la informacion de la transaccion
    * @param {object} values - Objecto que contiene los valores modificados del formulario
    */
    updateTransaccion = (values) => {
        this.setState({ loading: true })
        axios.put('/transacciones/update', {
            ...values,
            transaccion_id: this.props.transaccion_id,
            comprobantes: this.state.comprobantes,
        }).then(response => {
            message.success('Transacción actualizada')
            if (this.state.files_to_delete.length > 0) {
                for (const file of this.state.files_to_delete) {
                    this.removeFile(file.filename_server)
                }
            }

            this.props.onCancel(true)
        }).catch(error => {

            let msg = 'Error al actualizar la transacción'
            if (error?.response?.data?.message)
                msg = error?.response?.data?.message
            message.error(msg)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} values
     * @memberof ModalTransaccion
     * @method addTransaccion
     * @param {object} values Objecto que contiene los valores modificados del formulario
     * @description Crea una nueva transaccion
     */
    addTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transaccion/add', {
            ...values,
            comprobantes: this.state.comprobantes,
        }).then(response => {
            console.clear()
            console.log("response", response);
            message.success('Transacción creada')
            if (this.state.files_to_delete.length > 0) {
                for (const file of this.state.files_to_delete) {
                    this.removeFile(file.filename_server)
                }
            }

            this.props.onCancel(true)
        }).catch(error => {
            console.log("error", error);
            if(error?.response?.status === 403)
                message.error(error.response.data.message)
            else
                message.error("Error al crear la transacción")
            
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Cierra la transaccion
    */
    onFinish = (values) => {
        if (this.props.transaccion_id)
            this.updateTransaccion(values)
        else
            this.addTransaccion(values)
    }


    /**
    * @method onChangeCantidad
    * @param {number} value - Cantidad de plantas
    * @description Cuando la cantidad de plantas cambia, se actualiza el monto 
    */
    onChangeCantidad = (value) => {
        this.modalTransaccion.current.setFieldsValue({
            monto: this.state.precio_planta * value
        })
    }

    /**
     *
     * @memberof ModalComprobante
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            let comprobantes = this.state.comprobantes

            comprobantes.push({
                filename: file.response.name,
                filename_server: file.response.filename,
            })

            this.setState({ comprobantes })

            e.file.filename_server = file.response.filename

        }


        return e && e.fileList;
    }

    /**
     *
     * @memberof ModalComprobante
     *
     * @method removeFile
     * @description Quita el archivo del state de comprobantes para ponerlos en files_to_delete, asi cuando el usuario guarde, se eliminan, y no se borren automaticamente
     */
    deleteImage = (file) => {
        let comprobantes = this.state.comprobantes
        let index = comprobantes.findIndex(img => img.filename_server === file.filename_server)
        if (index != -1) {
            let to_delete = comprobantes[index]
            comprobantes.splice(index, 1)
            this.state.files_to_delete.push(to_delete)
        }
    }

    /**
     *
     * @memberof ModalComprobante
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => { })
            .catch(res => { })
    };


    render() {

        const { transaccion, loading } = this.state
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        const disabled = (this.props.transaccion_id !== undefined)

        const { cliente } = this.props

        return (
            <Form
                layout="vertical"
                ref={this.modalTransaccion}
                onFinish={this.onFinish}
                className="pd-1"
                initialValues={{
                    moneda: "MXN",
                    fecha: moment(),
                    cliente_id: cliente ? {
                        value: cliente._id,
                        key: cliente._id,
                        label: cliente.nombre + " " + cliente.apellido_paterno + " " + cliente.apellido_materno
                    } : null,
                    inversion_id: this.props.inversion_id ? this.props.inversion_id : null,
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Concepto"
                                name="concepto"
                                rules={[
                                    { required: true, message: "Ingrese el concepto" }
                                ]} 
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input placeholder="Descripción"></Input>
                            </Form.Item>
                        </Col>
                        {(this.state.cliente_visible ?
                            <Col span={24}>
                                <Form.Item
                                    label="Cliente"
                                    name="cliente_id"
                                >
                                    <SelectCliente
                                        disabled={disabled || this.props.inversion_id || this.props.cliente_id}
                                        allowClear={true}
                                        onSelect={(cliente_id) => {
                                            this.setState({cliente_id})
                                            this.modalTransaccion.current?.setFieldsValue({
                                                inversion_id: undefined
                                            })
                                        }}
                                        onClear={() => {
                                            this.modalTransaccion.current?.setFieldsValue({
                                                inversion_id: undefined
                                            })
                                        }}

                                    />
                                </Form.Item>
                            </Col>
                            : null)}
                        {(this.state.inversion_visible ?
                            <Col span={24}>
                                <Form.Item
                                    label="Inversiones"
                                    name="inversion_id"
                                >   
                                <SelectInversiones
                                        cliente_id={this.props.cliente_id ?? this.state.cliente_id}
                                        params={{
                                            monto_pendiente: true // inversiones con monto pendiente mayor a 0
                                        }}
                                        disabled={disabled || this.props.inversion_id}
                                        onSelect={(_id) => {
                                            this.setState(state => {
                                                state.inversiones.selected = state.inversiones.data.find(inversion => inversion._id === _id)
                                                state.estatus_disabled = false
                                                return state
                                            })
                                        }}
                                        onClear={() => {
                                            this.setState(state => {
                                                state.inversiones.selected = undefined
                                                state.estatus_disabled = true
                                                return state
                                            })
                                        }}
                                       
                                    />
                                </Form.Item>
                            </Col>
                            : null)}
                            
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[
                                    { required: true, message: "Ingrese la fecha" }
                                ]} 
                            >
                                <DatePicker disabled={disabled} />
                            </Form.Item>
                        </Col>
                        {transaccion.pos == false && this.state.inversion_visible  ?
                            <Col xs={24} md={12} >
                                <Form.Item
                                    label="Hacienda"
                                >
                                    <CustomAvatar
                                        image={transaccion.hacienda_id?.imagenes?.length > 0 ? transaccion.hacienda_id?.imagenes[0]?.filename : null}
                                        name={transaccion.hacienda_id?.nombre}
                                    />
                                    <Text className="ml-1">{transaccion.hacienda_id?.nombre}</Text>
                                </Form.Item>
                            </Col>
                            : null}


                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[
                                    { required: true, message: "Seleccione el tipo" }
                                ]} 
                            >
                                <Select disabled={disabled}>
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={2}>Egreso</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Tipo de Pago"
                                name="metodo_pago"
                                rules={[
                                    { required: true, message: "Seleccione el tipo de pago" }
                                ]} 
                            >
                                <Select disabled={disabled}>
                                    <Option value={1}>Efectivo</Option>
                                    <Option value={2}>Tarjeta</Option>
                                    <Option value={3}>Transferencia</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Moneda"
                                name="moneda"
                            >
                                <Select disabled={disabled}>
                                    <Option value={"MXN"}>MXN</Option>
                                    <Option value={"USD"}>USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Monto"
                                name="monto"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => {
                                            if (this.state.inversiones.selected) {
                                                const { monto_pendiente, monto } = this.state.inversiones.selected
                                                const tipo = getFieldValue('tipo')

                                                if (monto_pendiente < value && tipo === 1) return Promise.reject(new Error("El monto es mayor al pendiente de la inversion"))
                                                if (monto < value && tipo === 2) return Promise.reject(new Error("El monto es mayor al total de la inversion"))
                                            }

                                            if (value <= 0) return Promise.reject(new Error("El monto debe ser mayor a 0"))

                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <InputNumber
                                    disabled={disabled}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} className="center">
                            <Form.Item
                                name="comprobantes"
                                align="center"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                            >
                                <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    accept="image/*"
                                    onRemove={this.deleteImage}
                                >

                                    {uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} className="center">
                            <Form.Item className="m-0">
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Transacción</Title>
        </div>
        <ModalTransaccion {...props} />
    </Modal>

}