
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Modal, Typography, Button, Space, Pagination, Popconfirm, List, Card, message, Tag, Tooltip } from "antd";
import { IconBtnEdit, IconBtnDelete, IconEye } from '../../../Widgets/Iconos';
import ModalMovimientoDetalle from './ModalMovimientoDetalle';
import moment from 'moment';

const axios = require('axios')

const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class Movimientos
 * @extends {React.Component}
 */
export default class Movimientos extends React.Component {


    /**
     *Creates an instance of Movimientos.
     * @param {*} props
     * @memberof Movimientos
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            data: [],
            currentPage: 1,
            itemCount: 0,
            perPage: 10,
            search:'',



            visible: false,
            movimiento_id: false,
        }
    }

    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getMovimientos()
    }

    /**
     * @method getMovimientos
     * @description Solicita un listado de movimientos
     */
    getMovimientos = (page = this.state.currentPage) => {
        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/inventarios/movimientos', {
            params: {
                page,
                search,
                limit: this.state.perPage
            }
        }).then(res => {
            console.log('getMovimientos', res.data.data)
            const data = res.data.data

            this.setState({
                movimientos : data.itemsList,
                itemCount   : data.paginator.itemCount,
                pageCount   : data.paginator.pageCount,
                loading     : false,
            })

        }).catch(error => {
            message.error('Error al traer los movimientos')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Movimientos de Productos"
                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <List
                            loading={this.state.loading}
                            className="component-list "
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.movimientos}
                            header={<Row className="header-list width-100 pl-1 pr-1 ">
                                <Col xs={12} md={3} xl={3} className=" center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={12} md={12} xl={7} className="center">
                                    <Text strong>Producto</Text>
                                </Col>
                                <Col xs={12} md={12} xl={3} className="center">
                                    <Text strong>Tipo</Text>
                                </Col>
                                <Col xs={12} md={12} xl={2} className="center">
                                    <Text strong>Cantidad</Text>
                                </Col>
                                <Col xs={24} md={12} xl={7} className="center">
                                    <Text strong>Usuario</Text>
                                </Col>
                                <Col xs={24} md={5} xl={2} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item ">
                                    <Card className="card-list">
                                        <Row className="width-100">
                                            <Col xs={12} md={3} xl={3} className=" center">
                                                <Text strong>{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col xs={12} md={12} xl={7} className="center">
                                                <Text strong>{item.variante_id?.sku_variante}</Text>
                                            </Col>
                                            <Col xs={12} md={12} xl={3} className="center">
                                                {
                                                    item.tipo === 1 ? (
                                                        <Tag color="#A2D9CE">Entrada</Tag>
                                                    ) : (
                                                        <Tag color="#FFC300">Salida</Tag>
                                                    )
                                                }
                                            </Col>
                                            <Col xs={12} md={12} xl={2} className="center">
                                                <Text strong>{item.cantidad}</Text>
                                            </Col>
                                            <Col xs={24} md={12} xl={7} className="center">
                                                <Text strong>{`${item.usuario_id?.nombres} ${item.usuario_id?.apellido_paterno ? item.usuario_id?.apellido_paterno : ''} ${item.usuario_id?.apellido_materno ? item.usuario_id?.apellido_materno : ''}`}</Text>
                                            </Col>
                                            <Col xs={24} md={5} xl={2} className="center">
                                                <Space direction="horizontal">
                                                    <Button
                                                        icon={<IconEye />}
                                                        type="primary"
                                                        title={"Ver Detalles"}
                                                        onClick={() => this.setState({ visible: true, movimiento_id: item._id })}
                                                    />
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}

                        />
                        <Row className="mt-2 pb-3">
                            <Pagination
                                current={this.state.currentPage}
                                total={this.state.itemCount}
                                pageSize={this.state.perPage}
                                showSizeChanger={true}
                                onChange={(page, size) => {
                                    this.setState({
                                        currentPage: page,
                                        perPage: size
                                    }, () => {
                                        this.getMovimientos()
                                    })}
                                }

                            />
                        </Row>
                        
                    </Content>
                </Spin>
                <ModalMovimientoDetalle
                    visible={this.state.visible}
                    movimiento_id={this.state.movimiento_id}
                    onCancel={() => {
                        this.setState({
                            visible: false,
                            movimiento_id: undefined
                        })
                    }}
                />
            </>
        )
    }
}