import React, { Component, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import Sidebar from '../Components/Nav/Sidebar'

//Routes 
import RouterInversion from './Customer/RouterInversion'
import RouterResumen from './Customer/RouterResumen'
import RouterCuenta from './Customer/RouterCuenta'
import RouterCuentas from './Customer/RouterCuentas'
import RouterReventa from './Customer/RouterReventa';
import RouterBeneficiarios from './Customer/RouterBeneficiarios';

import Cotizador from '../Components/Public/Cotizador'


import '../Styles/Theme/Public/antd-zeus-public-theme.css'
import Theme from '../Components/ThemeEdit/index'
import RouterReventas from './Customer/RouterReventas';
import Soporte from '../Components/Customer/Soporte';

const { Content } = Layout;

class CustomerRoutes extends Component {

	componentDidMount() {

	}

	render() {
		return (
			<Layout >
				<Sidebar  {...this.props} />
				<Content style={{ minHeight: '100vh' }}>
					<Routes>
						<Route
							path="resume/*"
							element={<RouterResumen />}
						/>

						<Route
							path="invertir/*"
							element={<RouterInversion />}
						/>

						<Route
							path="mi-cuenta/*"
							element={<RouterCuenta />}
						/>

						<Route
							path="cuentas/*"
							element={<RouterCuentas />}
						/>
						<Route
							path="reventa/*"
							element={<RouterReventa />}
						/>
						<Route
							path="cotizador"
							element={<Cotizador />}
						/>
						<Route
							path="beneficiarios"
							element={<RouterBeneficiarios />}
						/>
						<Route
							path="reventas"
							element={<RouterReventas />}
						/>

						<Route
							path="theme"
							element={<Theme />}
						/>

					</Routes>
				</Content>
				<Soporte />

			</Layout>
		)
	}
}

export default (props) => <CustomerRoutes {...props} />