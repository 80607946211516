import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select
const axios = require("axios");

/**
 * @const SelectUsuario
 * @description Select para los usuarios registrados en el sistema
 */
const SelectUsuario = (props) => {

    const { value, onChange, placeholder = "Seleccione el usuario", onSelect = () => { }, disabled = false, className = "", params = {}, bordered } = props

    const [usuarios, setUsuarios] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getUsuarios
     * @description Obitiene los usuarios
     */
    const getUsuarios = ({ page, limit, search } = usuarios) => {

        axios.get('/usuarios', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            console.log("response", response);
            setUsuarios({
                data: response.data.data.itemsList,
                total: response.data.data.itemCount,
                page: response.data.data.currentPage,
            })


        }).catch(error => {
            console.log("error", error);
            message('Error al obtener los usuarios')
        })
    }

    //componentDidMount
    useEffect(() => {
        getUsuarios()
    }, [])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search) => getUsuarios({ search })}
            onSelect={(usuario) => {
                onChange(usuario.value)
                onSelect(usuario.value)
            }}
            value={value}
        >
            {
                usuarios.data.map(({ _id, nombre, apellido_paterno, apellido_materno }) => <Option value={_id}>{nombre} {apellido_paterno} {apellido_materno ?? ''}</Option>)
            }
        </Select>
    );
}



export default SelectUsuario;