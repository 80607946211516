import React, { useState, useEffect } from 'react';
import { Typography } from 'antd'
import Countdown from "react-countdown";

const { Text } = Typography
const moment = require('moment')



/**
 * @const renderer
 * @description renderiza el timer
 */
const renderer = ({ days ,hours, minutes, seconds, completed }) => {
    if (completed) {
        return <span>You are good to go!</span>;
    } else {
        
        return (<Text className="text-purple">
                {String(hours).padStart(2, '0')} : {String(minutes).padStart(2, '0')} : {String(seconds).padStart(2, '0')}
            </Text>
        );
    }
};


/**
 * @const Timer
 * @description Componente timer de forma regresiva
 * 
 * @param start String fecha de inicio
 * @param duracion int dias 
 */
const Timer = ({start, duracion}) => {

    let inicio = moment(start)

    let año = inicio.year().toString()
    let mes = inicio.month().toString()
    let dia = inicio.date().toString()
    let hora = inicio.hour().toString()
    let minuto = inicio.minute().toString()

    return <Countdown date={new Date(new Date(año, mes, dia, hora, minuto).getTime() + (duracion * 24 * 60 * 60 * 1000))} renderer={renderer} />
}


export default Timer
