import React, { useContext } from "react";
import { Layout, Row, Col, Button, Typography, Card, InputNumber, Form, PageHeader } from 'antd';
import ReactECharts from 'echarts-for-react';
import Header from './Header'
import Footer from './Footer'
import { IconAgabeColor, IconDolar, IconKilo, IconCalendar, IconInvest } from '../Widgets/Iconos'
import WOW from "wowjs";
import "../../Styles/Modules/landing.scss";
import { User } from "../../Hooks/Logged";
import TypeWriterEffect from "react-typewriter-effect";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

import { AiOutlineInstagram, AiOutlineFacebook } from "react-icons/ai";
import { FaTiktok, FaInstagram, FaFacebookF } from "react-icons/fa";
import VideoPlayer from "./VideoPlayer";
import { useSearchParams } from "react-router-dom";

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const axios = require('axios')


/**
 *
 *
 * @export
 * @class Landing
 * @extends {React.Component}
 */
class Landing extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      cantidad_plantas: 0,
      precio_plantas: 0,
      precio_kilo: 0,
      inversion: 0,
      ganancia: 0,
      sub_ganancia: 0,
      comision: 0,
      proyecciones: [],
      precio_kilo_default: 0,

      //simlador
      hectareas: 0,
      solares: 0,
      total_invertido: 0,
      utilidad_bruta: 0,
      utilidad_neta: 0,
      utilidad_planta: 0,
      utilidad_mes: 0,
      utilidad_anio: 0,
      rendimiento_mes: 0,
      rendimiento_anio: 0,
      updateText: true,

      //flags
      navbar: false
    }
  }

  refInvestment = React.createRef();
  componentDidMount() {
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    this.getDefaultCotizacion();
  }

  setSimulateData = () => {
    let data = this.state.proyecciones
    this.setState({
      option: {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          boundaryGap: false,
          data: ['Año 1', 'Año 2', 'Año 3', 'Año 4', 'Año 5', 'Año 6']
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: 'Proyeccion',
          type: 'line',
          stack: '总量',
          data: data
        },
        ]
      }
    })
  }



  /**
   *
   *
   * @memberof Landing
   * @method getDefaultCotizacion
   * @description Obtener los valores de cotizacion
   */
  getDefaultCotizacion = () => {
    axios.get('/plantas')
      .then(res => {
        if (res.data.data != null) {
          let valores = this.refInvestment.current.getFieldsValue()

          let values = {
            kilos_planta: res.data.data.kgs_planta,
            precio_plantas_venta: res.data.data.precio_kilo,
            cantidad_plantas: 10
          }
          this.setState({ precio_kilo_default: res.data.data.precio_kilo })
          this.refInvestment.current.setFieldsValue(values)
          this.calculateInvestment({ ...valores, ...values })
          this.refInvestment.current.setFieldsValue(values)
        }

      })
      .catch(error => {

      })

  }





  /**
   *
   *
   * @memberof Landing
   * @method calculateInvestment
   * @param {object} values Valores del formulario de inversion
   * @description Calcula los datos de inversion
   */
  calculateInvestment = (values) => {

    let { cantidad_plantas, precio_planta, total_invertido, precio_plantas_venta, anios, kilos_planta } = values;

    total_invertido = cantidad_plantas * precio_planta;
    let utilidad_bruta = anios <= 2 ? cantidad_plantas * precio_plantas_venta : cantidad_plantas * precio_plantas_venta * kilos_planta
    let utilidad_neta = anios === 6 ? utilidad_bruta - (utilidad_bruta / 100) * 35 : utilidad_bruta

    let utilidad_planta = utilidad_neta / cantidad_plantas
    let utilidad_mes = utilidad_neta / (12 * anios)
    let utilidad_anio = utilidad_neta / anios

    let rendimiento_mes = Math.round((utilidad_mes / total_invertido) * 100)
    let rendimiento_anio = Math.round((utilidad_anio / total_invertido) * 100)

    this.refInvestment.current.setFieldsValue({
      total_invertido
    })

    this.setState({
      hectareas: cantidad_plantas / 3990,
      solares: cantidad_plantas / 700,
      total_invertido,
      utilidad_bruta,
      utilidad_neta,
      utilidad_planta,
      utilidad_mes,
      utilidad_anio,
      rendimiento_mes,
      rendimiento_anio

    })

    //variables ????
    var a = [0.2, 0.5, 1.3, 0.3, 0.4, 0.75, 1],
      o = 0,
      i = 0,
      n = 0;
    let proyecciones = [];

    if (anios < 3) {
      o = (precio_plantas_venta - precio_planta) / anios
    } else {						//29 = precio plantas default 
      i = (precio_plantas_venta - this.state.precio_kilo_default) / (anios - 2)
    }

    if (o === 0) {
      n = cantidad_plantas * (precio_planta + precio_planta * a[0])
    } else {
      n = cantidad_plantas * (precio_planta + o / 2)
    }

    for (let l = 1; l <= anios; l++) {
      if (l < 3) {
        if (o === 0) {
          n = cantidad_plantas * (precio_planta + precio_planta * a[l])
        } else {
          n = cantidad_plantas * (precio_planta + o * l)
        }
      } else if (l === 3) {
        n = cantidad_plantas * this.state.precio_kilo_default * (kilos_planta * a[l])
      } else if (l > 3) {
        n = cantidad_plantas * (this.state.precio_kilo_default + i * (l - 2)) * (kilos_planta * a[l])
      }

      proyecciones.push(Math.round(100 * n) / 100)
    }
    if (values.precio_planta === undefined || values.cantidad_plantas === undefined || values.kilos_planta === undefined) return;


    this.setState({ proyecciones }, () => {
      this.setSimulateData()
    })

  }

  reloadText = () => {
    this.state.updateText ? this.setState({ updateText: false }) : this.setState({ updateText: true })
  }


  render() {
    return (
      <Layout className="hm-layout-content landing layout-dashboard"> 
        <PageHeader className="site-page-header" ghost={false} title="Cotizador" />
        <Row style={{ marginTop: "100px", padding: "0 1.5rem" }}>
          <Col span={24} className=" text-center">
            <Title level={2} className="text-subtitle">
              {t("landing.simulator.title")}
            </Title>
            <Paragraph className="text-parrafo">
              {t("landing.simulator.desc1")}
            </Paragraph>
          </Col>
          <Col span={24}>
            <Text className=" text-parrafo text-center center">
              {t("landing.simulator.disclaimer")}
            </Text>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={20}>
            <Form
              layout="vertical"
              className="width-100 mt-3 mb-3"
              requiredMark={false}
              ref={this.refInvestment}
              onFinish={this.calculateInvestment}
              initialValues={{
                cantidad_plantas: 1,
                precio_planta: 10,
                total_invertido: 1,
                precio_plantas_venta: 1,
                anios: 6,
                kilos_planta: 1,
              }}
              onValuesChange={(_, values) => {
                this.calculateInvestment(values);
              }}
            >
              <Row justify="center" gutter={[32, 32]}>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="cantidad_plantas"
                    labelCol={24}
                    label={
                      <Text className="text-input">{t("landing.simulator.plants")}</Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.plants_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      prefix={<IconAgabeColor />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="precio_planta"
                    labelCol={24}
                    label={
                      <Text className="text-input">{t("landing.simulator.price")}</Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.price_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconDolar />}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="total_invertido"
                    labelCol={24}
                    label={<Text className="text-input">{t("landing.simulator.investment")}</Text>}
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.investment_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconInvest />}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="precio_plantas_venta"
                    labelCol={24}
                    label={
                      <Text className="text-input">{t("landing.simulator.price_kilo")}</Text>
                    }
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.price_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconDolar />}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="anios"
                    labelCol={24}
                    label={<Text className="text-input">{t("landing.simulator.years")}</Text>}
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.years_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconCalendar />}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      min={0}
                      max={6}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Form.Item
                    name="kilos_planta"
                    labelCol={24}
                    label={<Text className="text-input">{t("landing.simulator.weight")}</Text>}
                    rules={[
                      {
                        required: true,
                        message: t("landing.simulator.weight_required"),
                      },
                    ]}
                  >
                    <InputNumber
                      size="large"
                      className="input-landing width-100"
                      prefix={<IconKilo />}
                      addonAfter={<Text>Kg</Text>}
                      formatter={(value) => {
                        setTimeout(() => {
                          return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        })
                      }

                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/*Calculos*/}
        <Row justify="center">
          <Col span={20}>
            <Text className="text-cyan-semi-dark">{t("landing.simulator.hectares")}: </Text>
            <Text>{this.state.hectareas.toMoney(true)}</Text>
          </Col>
          <Col span={20}>
            <Text className="text-cyan-semi-dark">{t("landing.simulator.plots")}: </Text>
            <Text>{this.state.solares.toMoney(true)}</Text>
          </Col>
        </Row>
        <Row justify="center" className="row-divider">
          <Col xs={24} md={12}>
            <Text className="text-cyan-semi-dark font-24">
              {t("landing.simulator.to_invest")}:
            </Text>
            <Text className="font-24">
              $ {this.state.total_invertido.toMoney(true)}
            </Text>
          </Col>
          <Col xs={24} md={12}>
            <Text strong className="text-cyan-semi-dark font-24">
              {t("landing.simulator.net_profit")}:
            </Text>
            <Text strong className="font-24">$ {this.state.utilidad_neta.toMoney(true)} <small>($ {this.state.utilidad_bruta} - 35%)</small></Text>
          </Col>
        </Row>
        <Row justify="center" className="row-divider">
          <Col xs={24} md={12}>
            <Text strong className="text-cyan-semi-dark font-28">
              {t("landing.simulator.gross_profit")}:
            </Text>
            <Text strong className="font-28">
              $ {this.state.utilidad_bruta.toMoney(true)}
            </Text>
          </Col>
          <Col xs={24} md={12}>
            <Text className="text-cyan-semi-dark font-24">
              {t("landing.simulator.profit_per_plant")}:
            </Text>
            <Text className="font-24">
              $ {this.state.utilidad_planta.toMoney(true)}
            </Text>
          </Col>
        </Row>
        <Row justify="center" className="row-divider">
          <Col xs={24} md={12}>
            <Text className="text-cyan-semi-dark font-24">
              {t("landing.simulator.monthly_return")}:
            </Text>
            <Text className="font-24">
              {this.state.rendimiento_mes.toMoney(true)} %
            </Text>
          </Col>
          <Col xs={24} md={12}>
            <Text className="text-cyan-semi-dark font-24">
              {t("landing.simulator.monthly_profit")}:
            </Text>
            <Text className="font-24">
              $ {this.state.utilidad_mes.toMoney(true)}
            </Text>
          </Col>
        </Row>
        <Row justify="center" className="row-divider">
          <Col xs={24} md={12}>
            <Text className="text-cyan-semi-dark font-24">
              {t("landing.simulator.yearly_return")}:
            </Text>
            <Text className="font-24">
              {this.state.rendimiento_anio.toMoney(true)} %
            </Text>
          </Col>
          <Col xs={24} md={12}>
            <Text className="text-cyan-semi-dark font-24">
              {t("landing.simulator.yearly_profit")}:
            </Text>
            <Text className="font-24">
              {this.state.utilidad_anio.toMoney(true)}
            </Text>
          </Col>
        </Row>

        <Row justify="center">
          <Col span={20}>
            <Row className="mt-3 mb-3" gutter={[16, 16]}>
              <Col sm={24}>
                {this.state.option ? (
                  <ReactECharts
                    option={this.state.option}
                    width="100%"
                    style={{ height: 500, width: "100%" }}
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>

      </Layout>
    );
  }
}


export default function View(props) {
  const { t, i18n } = useTranslation()
  const changeLanguage = (lng) => i18n.changeLanguage(lng)
  let user = useContext(User)




  // /email-:email/token-:token
  return <Landing user={user} changeLanguage={changeLanguage} t={t} {...props} />;
}
