import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'

const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectCliente
 * @description Select para los clientes registrados en el sistema
 */
const SelectCliente = (props) => {

    const { value, onChange, placeholder = "Seleccione el cliente", onSelect = () => {}, disabled = false, className = "", params = {}, bordered, allowClear, onClear = () => {} } = props

    const [ clientes, setClientes ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getClientes
     * @description Obitiene los clientes
     */
    const getClientes = ({page, limit, search} = clientes) => {

        axios.get('/clientes', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setClientes(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error('Error al obtener los clientes')
        })
    }

    //componentDidMount
    useEffect(() => {
        getClientes()
    }, [])

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={true}
            showSearch
            filterOption={false}
            labelInValue
            onSearch={(search)=> getClientes({search})}
            onSelect={(cliente)=> {
                onChange(cliente.value)
                onSelect(cliente.value)
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                clientes?.data?.map(({ _id, nombre, apellido_paterno, apellido_materno }) => <Option value={_id}>{nombre ?? ""} {apellido_paterno ?? ""} {apellido_materno ?? ''}</Option>)
            }
        </Select>
    );
}



export default SelectCliente;