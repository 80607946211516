import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'
import { useTranslation } from 'react-i18next';



const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectPais
 * @description Select para los paises registrados en el sistema
 */
const SelectPais = (props) => {

    const { value, onChange, placeholder = "Seleccione el pais", onSelect = () => {}, disabled = false, className = "" } = props

    const [ paises, setPaises ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })

    const { t, i18n } = useTranslation();


    /**
     * @const getPaises
     * @description Obitiene los paises
     */
    const getPaises = ({page, limit, search} = paises) => {
        console.log("search", search);

        axios.get('/paises', {
            params: {
                search,
                locale: i18n.language
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setPaises({
                ...paises,
                data: response.data.data,
            })

        }).catch(error => {
            message('Error al obtener los paises')
        })
    }

    //componentDidMount
    useEffect(() => {
        getPaises()
    }, [])

    return (
        <Select
            {...props}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            //labelInValue
            onSearch={(search)=> getPaises({search})}
            onSelect={(pais)=> {
                onChange(pais)
                onSelect(pais)
            }}
            value={value}
        >
            {
                paises.data.map(({ _id, nombre_es, nombre_en }) => <Option value={_id}>{(i18n.language.includes("es")) ? (nombre_es) : (nombre_en)}</Option>)
            }
        </Select>
    );
}



export default SelectPais;