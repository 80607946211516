
import React, { Component } from 'react';
import { Row, Col, Typography, Button, Form, Input, Switch, Statistic, Spin, PageHeader, Layout, message, Card, Divider, Image } from 'antd'
import axios from 'axios';
import { IconArrowBack, } from '../../../Widgets/Iconos'
import { useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Text } = Typography
// responsividad del formulario
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12, offset: 6 },
        lg: { span: 12, offset: 6 },
        xl: { span: 12, offset: 6 },
        xxl: { span: 12, offset: 6 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 12, offset: 6 },
        lg: { span: 12, offset: 6 },
        xl: { span: 12, offset: 6 },
        xxl: { span: 12, offset: 6 }
    },
};
/**
 *
 *
 * @export
 * @class Inventarios
 * @extends {Component}
 * @description Vista donde se listan todos los Inventarios
 */
class Inventarios extends Component {



    /**
     *Creates an instance of Inventarios.
     * @param {*} props
     * @memberof Inventarios
     */
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            estatus: 0, // 0: Normal, 1: Faltante, 2: Sobrante,
            maximo: 0,
            minimo: 0,
            variante: {
                nombre: 'variante',
                descripcion: 'Descripcion del producto '
            },
            producto: {
                nombre: 'producto',
                descripcion: 'Descripcion del producto ',
                imagenes: []
            }
        }
    }
    formRef = React.createRef();
    formInvRef = React.createRef();

    componentDidMount() {
        
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getInventarios();
        this.getVariante()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.setState({ search: this.props.search }, () => this.getInventarios())
        }
    }

    /**
    * @method getInventarios
    * @description Obtiene el Inventario de ese producto-variante
    */
    getInventarios = () => {
        this.setState({ loading: true })
        axios.get('/inventario/get', {
            params: {
                variante_id: this.props.params.variante_id,
            }
        }).then(res => {
            
            const data = res.data.data

            let faltante = data.inv_minimo - data.existencias
            let sobrante = data.existencias - data.inv_maximo

            this.setState({
                data: data,
                estatus: faltante > 0 ? 1 : sobrante > 0 ? 2 : 0,
                minimo: data.inv_minimo,
                maximo: data.inv_maximo,
            })
            this.formRef.current?.setFieldsValue({
                existencias: data.existencias
            })
            this.formInvRef.current?.setFieldsValue({
                faltante: faltante > 0 ? faltante : 0,
                sobrante: sobrante > 0 ? sobrante : 0,
                inv_minimo: data.inv_minimo,
                inv_maximo: data.inv_maximo,
            })
        }).catch(error => {
            
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     *
     *
     * @memberof Inventarios
     * @description Obtiene la informacion de la variante del producto para visualizarla 
     */
    getVariante = () => {
        axios.get('/variantes/get', {
            params: {
                id: this.props.params.variante_id,
            }
        }).then(res => {
            
            this.setState({
                variante: res.data.data.variante,
                producto: res.data.data.variante.producto_id
            })
        })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }


    /**
     *
     *
     * @memberof Inventarios
     * @description metodo que se ejecuta al guardar existencias
     * @param {object} values - Objecto que contiene los valores del formulario
     */
    onSubmit = (values) => {

        if (this.state.loading) return

        
        this.setState({ loading: true }, () => {
            axios.put('/inventario/update', {
                variante_id: this.props.params.variante_id,
                existencias: values.existencias,
                tipo: (values.tipo) ? 1 : 2,
                cantidad: values.cantidad
            })
                .then((res) => {
                    message.success('Existencias Actualizadas');
                    this.formRef.current?.setFieldsValue({
                        cantidad: null
                    })
                    this.getInventarios()
                }).catch((error) => {
                    message.error('Error al actualizar')
                    
                }).finally(() => this.setState({ loading: false }))
        })
    }

    /**
     *
     *
     * @param {*} values
     * @memberof Inventarios
     * @method updateInventario
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     * @description Actualiza los datos de inventario minimo y maximo 
     */
    updateInventario = (values) => {

        this.setState({ loading: true })

        axios.put('/inventario/update', {
            variante_id: this.props.params.variante_id,
            inv_minimo: values.inv_minimo,
            inv_maximo: values.inv_maximo
        })
            .then((res) => {
                message.success('Inventario Actualizado');
                this.getInventarios()
            }).catch((error) => {
                message.error('Error al actualizar')
                
            }).finally(() => this.setState({ loading: false }))
    }

    back = () => {
        this.props.navigate(-1);
    }

    render() {

        const { variante, producto } = this.state;
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader className="admin-page-header"
                        title="Inventario"
                        subTitle={variante.nombre}
                        onBack={this.back}
                        backIcon={<IconArrowBack />}
                    />

                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[8, 8]} className="width-100">
                            <Col sm={24} md={12} className="width-100" >
                                <Card className='heigth-100 card-zeus' title="Producto">
                                    <Row className="width-100">
                                        <Col span={8} md={12}>
                                            <Image
                                                placeholder
                                                {...(
                                                    producto.imagenes.length > 0 ? {
                                                        src: axios.defaults.baseURL + "/upload/" + producto.imagenes[0].filename
                                                    } : {}
                                                )}
                                                style={{ height: 80, width: 80 }} />

                                        </Col>
                                        <Col span={16} md={12}>
                                            <Text>{producto.nombre}</Text><br />
                                            <Text ellipsis className='text-gray'>{producto.descripcion}</Text>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                            <Col sm={24} md={12} className="width-100" >
                                <Card className='heigth-100 card-zeus width-100' title="Variante">
                                    <Text>{variante.nombre}</Text><br />
                                    <Text ellipsis className='text-gray'>{variante.descripcion ? variante.descripcion : 'Sin descripción'}</Text>
                                </Card>
                            </Col>
                            <Col sm={24} md={24} className="width-100"  >
                                <Card title="Inventario" className='card-zeus'>
                                    <Row>
                                        <Col xs={24} md={12}>
                                            <Form {...formItemLayout} ref={this.formInvRef} layout="vertical" onFinish={this.updateInventario}>
                                                <Row className="mb-3" justify='center'>
                                                    <Col align={'center'} span={24} xs={12} lg={8}>
                                                        <Form.Item name="faltante"  >
                                                            <Statistic title="Faltante" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col align={'center'} span={24} xs={12} lg={8}>
                                                        <Form.Item name="sobrante"  >
                                                            <Statistic title="Sobrante" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider></Divider>
                                                <Form.Item
                                                    name="inv_minimo"
                                                    label="Inventario Minimo"
                                                    className='width-100'
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (Number(getFieldValue('inv_maximo')) > Number(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject('Ingrese un valor menor al inventario máximo');
                                                            }
                                                        })
                                                    ]}
                                                >
                                                    <Input placeholder="0" type="number" min={0} />
                                                </Form.Item>
                                                <Form.Item
                                                    name="inv_maximo"
                                                    label="Inventario Máximo"
                                                    className='width-100'
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (Number(getFieldValue('inv_minimo')) < Number(value)) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject('Ingrese un valor mayor al inventario mínimo');
                                                            }
                                                        })
                                                    ]}
                                                >
                                                    <Input placeholder="0" type="number" min={0} />
                                                </Form.Item>
                                                <Form.Item className="text-center">
                                                    <Button htmlType="submit" type="primary">
                                                        Guardar Cambios
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form   {...formItemLayout} layout="vertical" ref={this.formRef} onFinish={this.onSubmit}   >
                                                <Row className="mb-3">
                                                    <Col align={'center'} span={24} xs={24} lg={24}>
                                                        <Form.Item name="existencias"  >
                                                            <Statistic title="Existencias actuales" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Divider></Divider>
                                                <Form.Item name="cantidad" label="Cantidad" >
                                                    <Input placeholder="0" type="number" min={0} />
                                                </Form.Item>
                                                <Form.Item name="tipo" label="Movimiento" valuePropName="checked" >
                                                    <Switch checkedChildren="Entrada" unCheckedChildren="Salida" />
                                                </Form.Item>
                                                <Form.Item className="text-center">
                                                    <Button htmlType="submit" type="primary">
                                                        Agregar movimiento
                                                    </Button>
                                                </Form.Item>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props){

    const params = useParams()
    const navigate = useNavigate()

    return <Inventarios  {...props} params={params} navigate={navigate}/>
}