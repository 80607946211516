import { Select, Space, Typography, message } from "antd"
import CustomAvatar from "../../../Widgets/Avatar/Avatar"
import axios from "axios"
import React from "react"

const { Text } = Typography

class SelectVendedor extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            vendedores: {
                data: [],
                page: 1,
                limit: 10,
                search: undefined,
                loading: false
            },
            item: this.props.item
        }
    }


    componentDidUpdate(){
        if (this.state.item?._id !== this.props.item?._id) {
            this.setState({ item: this.props.item })
        }

    }


    getVendedores = ({ page, limit, search } = this.state.vendedores, { vendedores } = this.state) => {
        this.setState({ vendedores: { ...this.state.vendedores, page, limit, loading: true } })
        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
            }
        })
            .then(response => {
                vendedores.data = (page === 1) ? response.data.data.itemsList : [...vendedores.data, ...response.data.data.itemsList];
                vendedores.total = response.data.data.itemCount
                vendedores.pages = response.data.data.pageCount
                vendedores.loading = false;

                this.setState({ vendedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.vendedores.loading = false
                    return state
                })
            })
    }

    asignarVendedor = (vendedor_id, ids) => {
        this.setState({ loading: true })

        axios.post('/clientes/asignar-vendedor', {
            vendedor_id, ids
        }).then(success => {
            message.success("Vendedor asignado")
        }).catch(error => {
            message.error("Error al asignar vendedor")
        })
    }

    render() {

        const { item } = this.state

        return (
            <Select
                ref={e => this.selectRef = e}
                notFoundContent={this.state.vendedores?.loading ? "Cargando Usuarios..." : "Sin datos"}
                loading={this.state.vendedores?.loading}
                bordered={false}
                size={'large'}
                className="w-100 "
                placeholder={"Sin Vendedor"}
                value={
                    item ? {
                        key: item?._id,
                        value: item?._id,
                        label: <>{item?.nombres || item.nombre} {item?.apellido_paterno} {item?.apellido_materno}</>
                    } : null
                }
                showSearch
                filterOption={false}
                onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !this.state.vendedores?.loading && this.state.vendedores.page < this.state.vendedores.pages) ? this.getVendedores({ page: this.state.vendedores.page + 1 }) : null}
                onSearch={(search) => this.getVendedores({ search, page: 1 })}
                onSelect={(value) => this.asignarVendedor(value, [item._id])}
                onDropdownVisibleChange={(e) => {
                    if (!e)
                        this.setState({
                            vendedores: {
                                data: [],
                                page: 1,
                                limit: 10,
                                search: undefined,
                                loading: false
                            },
                            value: undefined
                        })
                    else {
                        this.getVendedores()
                    }
                }}
            >
                {
                    this.state.vendedores.data.map(vendedor => {
                        return (
                            <Select.Option key={vendedor._id} value={vendedor._id}>
                                {vendedor?.nombres} {vendedor?.apellido_paterno} {vendedor?.apellido_materno}
                            </Select.Option>
                        )
                    })
                }
            </Select>
        )
    }
}

export default SelectVendedor