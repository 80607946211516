import React, { Component } from "react";
import { FilePdfOutlined } from "@ant-design/icons";
import { Row, Col, Space, Button, List, Typography, Card, message, Statistic, Popconfirm, Popover } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
//componentes
import CustomAvatar from '../../../Widgets/Avatar/Avatar';
import { IconBtnDelete, IconBtnEdit } from '../../../Widgets/Iconos';
import ModalBeneficiario from "../ModalBeneficiario";
import FloatingButton from '../../../Widgets/FloatingButton/FloatingButton';


const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class Compras
 * @extends {Component}
 * @description Vista del listado de compras realizadas por el cliente
 */
class Compras extends Component {

    constructor(props) {
        super(props)
        this.state = {
            beneficiario_id: null,
            modalBeneficiario: false,
            beneficiarios: {
                data: [],
                limit: 10,
                page: 1,
                total: 0,
                pages: 0,
               
            }, 
            cliente_id: this.props.cliente_id
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if(this.props.cliente_id){
            this.getBeneficiarios()
        }
        
    }

    /**
    * @memberof Compras
    * @description Obtiene todas todas las transacciones realizadas por el cliente relacionadas a POS
    * @param values pagina
    */
    getBeneficiarios = ({
        page = this.state.beneficiarios.page,
        limit = this.state.beneficiarios.limit,
    } = this.state.beneficiarios) => {

        this.setState({loading: true})
        axios.get('/beneficiarios', {
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id,
                pos: true,
                tipo: 1 //ingreso
            }
        })
        .then(({ data }) => {

            this.setState({
                beneficiarios: data
            })
            
        })
        .catch(err => {
            
            message.error('No se pudieron cargar los beneficiarios.')
        })
        .finally(() => this.setState({ loading: false }))

    }


    render() {
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list "
                    size="large"
                    itemLayout="horizontal"
                    dataSource={this.state.beneficiarios.data}
                    locale={{ emptyText: "Sin Compras" }}
                    pagination={{
                        current: this.state.beneficiarios.page,
                        pageSize: this.state.beneficiarios.limit,
                        total: this.state.beneficiarios.total,
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page) => this.getBeneficiarios({page}),
                        hideOnSinglePage: true
                    }}
                    header={<Row className=" width-100 pl-1 pr-1 ">
                        <Col  xs={10}>
                            Nombre
                        </Col>
                        <Col  xs={5}>
                            Teléfono
                        </Col>
                        <Col  xs={5}>
                            Email
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                        <Card className="card-list hover">
                            <Row gutter={[16, 16]}>

                                <Col xs={10}>
                                    <Text>{item.nombre}</Text>
                                </Col>
                                <Col xs={5}>
                                    <Text>{item.telefono}</Text>
                                </Col>
                                <Col xs={5}>
                                    <Text>{item.email}</Text>
                                </Col>
                                <Col xs={24} lg={4} align="right">
                                    <Space size="small" direction="horizontal">
                                        <Button
                                            type="primary"
                                            
                                            icon={<EditOutlined />}
                                            title="Editar"
                                            onClick={() => this.setState({ modalBeneficiario: true, beneficiario_id: item._id })}
                                        />
                                        <Popconfirm
                                            placement="topRight"
                                            
                                            title="¿Deseas eliminar este beneficiarios?"
                                            onConfirm={() => axios.delete('/beneficiario/delete',
                                                { params: { beneficiario_id: item._id } }).then(() => {
                                                    this.getCliente()
                                                    message.success('Beneficiario eliminado')
                                                }).catch(error => {
                                                        console.log(error)
                                                        message.error('Error al eliminar el beneficiario')
                                                    
                                                })}
                                            okText="Sí"
                                            cancelText="No"
                                        >
                                            <Button
                                                
                                                type="primary"
                                                icon={<DeleteOutlined />}
                                                title="Eliminar"
                                                danger
                                            />
                                        </Popconfirm>
                                    </Space>
                                </Col>
                            </Row>
                        </Card>
                    </List.Item>
                    )}

                />
                
                <FloatingButton title="Nueva Inversión" onClick={() => this.setState({ modalBeneficiario: true, beneficiario_id: undefined })} />
                

				<ModalBeneficiario
					visible={this.state.modalBeneficiario}
					onClose={() => {
						this.setState({ modalBeneficiario: false, beneficiario_id: undefined })
						this.getBeneficiarios(this.state.page)
					}}
					beneficiario_id={this.state.beneficiario_id}
					cliente_id={this.props.cliente_id}
				/>
            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Compras {...props} />)
}