import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import Soporte from '../../Components/Admin/Soporte/Soporte'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
export default  function RouterSoporte(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Soporte {...props} />} />
        </Routes>
    )
}

// RouterAreas;
