import React, { Component } from 'react';
import { Row, Col, Typography, Tag, PageHeader, Layout, List, Card, Avatar, Divider, message } from 'antd'
import axios from 'axios';
import { IconCoin, IconListon, IconUsers } from '../../Widgets/Iconos'
import { CardStatisticsSmall } from '../../Widgets/Cards'

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography
const moment = require('moment');
const colors = ["#FA9100", "#FFDE59", "#87d068", "#ff4d4f"];
/**
 *
 *
 * @export
 * @class DashboardPOS
 * @extends {Component}
 * @description Vista de informacion general del POS
 */
export default class DashboardPOS extends Component {


    /**
     *Creates an instance of DashboardPOS.
     * @param {*} props
     * @memberof DashboardPOS
     */
    constructor(props) {
        super(props)
        this.state = {

            ventas: {
                page: 1,
                total: 0,
                limit: 10,
                data: [],
                loading: false,
                search: ''
            },
            ventas_hoy: 0.00,
            ventas_totales: 0.00,
            compras_totales: 0.00,
            porcentaje_ventas: 0.00,
            productos: []
        }
    }


    componentDidMount() {
        this.props.clearSearch()
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDashboard();
        this.getVentas();
    }

    /**
    * @memberof DashboardPOS
    *
    * @method   getDashboard
    * @description  Metodo que realiza el paginado de las Ventas
    * @param page  Number con la pagina a buscar, 
    *
    **/
    getDashboard = () => {
        axios.get('/pos/dashboard',)
            .then(({ data }) => {
                

                if (data.compras_totales > 0) {
                    let today = data.ventas_hoy?.ventas_totales ? data.ventas_hoy?.ventas_totales : 0.00;
                    let all = data.ventas_totales?.ventas_totales ? parseFloat(data.ventas_totales?.ventas_totales) : 0.00;
                    this.setState({
                        ventas_hoy: parseFloat(today),
                        ventas_totales: all,
                        productos: data.productos,
                        compras_totales: parseFloat(data.compras_totales),
                        porcentaje_ventas: parseFloat(data.porcentaje_ventas)
                    })
                }
            })
            .catch(error => {
                message.error('Ocurrio un error al cargar los datos')
                
            })
    }

    /**
    * @memberof DashboardPOS
    *
    * @method   getVentas
    * @description  Metodo que trae las ultimas 10 ventas del POS
    * @param {object} {page, limit, search} - Datos de paginado y busqueda
    **/
    getVentas = ({
        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search,
    } = this.state.ventas) => {
        this.setState(state => {
            state.ventas.loading = true;
            state.ventas.page = page;
            state.ventas.limit = limit;
            return state;

        })
        axios.get('/pos/ventas', {
            params: {
                page,
                limit,
                search
            }
        }).then(res => {
            let data = res.data.data.itemsList;
            let paginator = res.data.data.paginator
            
            this.setState(state => {
                if (page === 1)
                    state.ventas.data = data
                else
                    state.ventas.data = [...state.ventas.data, ...data]

                state.ventas.total = paginator.itemCount
                state.ventas.pages = paginator.pageCount
                state.ventas.loading = false
                return state;
            })

        })
            .catch(error => {
                
                message.error('Ocurrio un error al cargar las ventas')
            })
    }

    /**
     *
     *
     * @memberof DashboardPOS
     * @description Renderiza el Tag correspondiente al status de la venta
     * @param status  1 = En proceso, 2= Aceptada , 3= Rechazada
     */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "Aceptada";
                break;
            case 3:
                text = "Rechazada";
                break;

            default:
                break;
        }
        return <Tag color={colors[status]} className="admin-tag admin-tag-green">{text}</Tag>
    }
    render() {
        const { ventas_totales, ventas_hoy, compras_totales, porcentaje_ventas } = this.state;
        

        return (
            <>
                <PageHeader className="admin-page-header" title="Dashboard" />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={15}>
                            <Title className="text-cyan-semi-dark" level={2}>Punto de Venta</Title>
                            <Paragraph className="text-cyan-semi-dark">{moment().format('dddd')} {moment().format('DD MMM YYYY')}</Paragraph>
                            <Divider className='divider-cyan divider-medium'></Divider>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={8}>
                                    <CardStatisticsSmall title={'Ventas Totales'} monto={ventas_totales} porcentaje={0} icon={<IconCoin />} />
                                </Col>
                                <Col xs={24} md={8}>
                                    <CardStatisticsSmall title={'Ventas Hoy'} monto={ventas_hoy} porcentaje={porcentaje_ventas} icon={<IconListon />} />
                                </Col>
                                <Col xs={24} md={8}>
                                    <CardStatisticsSmall title={'Compras Completadas'} monto={compras_totales} money={false} porcentaje={0} icon={<IconUsers />} />
                                </Col>
                                <Col xs={24}>
                                    <Card>
                                        <Title level={3} className="text-cyan-semi-dark">Ordenes Completadas</Title>
                                        <List
                                            loading={this.state.ventas.loading}
                                            className="component-list"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: "Sin Ordenes" }}
                                            dataSource={this.state.ventas.data}
                                            header={<Row className="width-100 pr-1 pl-1 border-header-list" >
                                                <Col span={6} className="center ">
                                                    <Text className='text-cyan-semi-dark' strong>Fecha</Text>
                                                </Col>
                                                <Col span={6} className="center ">
                                                    <Text className='text-cyan-semi-dark' strong>Productos</Text>
                                                </Col>
                                                <Col span={6} className="center ">
                                                    <Text className='text-cyan-semi-dark' strong>Monto Total</Text>
                                                </Col>
                                                <Col span={6} className="center ">
                                                    <Text className='text-cyan-semi-dark' strong>Estatus</Text>
                                                </Col>

                                            </Row>
                                            }

                                            renderItem={item => (
                                                <List.Item className="component-list-item">
                                                    <Row className="width-100 ">
                                                        <Col span={6} className="center ">
                                                            <Text>{moment(item.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                                                        </Col>
                                                        <Col span={6} className="center ">
                                                            <Text>{item.productos}</Text>
                                                        </Col>
                                                        <Col span={6} className="center ">
                                                            <Text>$ {(item.total.toMoney) ? item.total.toMoney(true) : 0} MXN</Text>
                                                        </Col>
                                                        <Col span={6} className="center ">
                                                            <Text>{this.renderTag(item.status)}</Text>
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={9}>
                            <Card className='heigth-100 '>
                                <List
                                    loading={this.state.loading}
                                    className="component-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Sin Productos" }}
                                    dataSource={this.state.productos}
                                    header={
                                        <div >  <Title level={3} className='text-cyan-semi-dark' style={{ marginTop: '10px' }}>Mas Vendidos</Title>
                                            <Divider className='divider-cyan '></Divider>
                                        </div>}
                                    size="large"
                                    renderItem={item => (
                                        <List.Item.Meta
                                            className='pb-1 mb-05'
                                            avatar={<Avatar size="large" src={`${axios.defaults.baseURL}/upload/${item.variante_id?.producto_id?.imagenes[0]?.filename}`} />}
                                            title={item.variante_id?.sku_variante}
                                            description={`${item.total} Comprados`}
                                        />
                                    )}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}
