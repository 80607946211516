import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, Popconfirm, Spin, Modal,
    PageHeader, Layout, Space, message, List, Card, Dropdown, Tag, Radio, Menu, Select, Checkbox, Tooltip, Input
} from 'antd'
import { Link, useNavigate } from "react-router-dom";
import axios, { } from 'axios';
import { DeleteOutlined, PlusOutlined, UserAddOutlined, WarningFilled } from '@ant-design/icons';

import useSearchParams from '../../../Hooks/SearchParams';
import User from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';

//componentes
import ModalCliente from './ModalCliente';
import DrawerFiltros from '../../Widgets/DrawerFiltros/DrawerFiltros'
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'
import { IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'

import ModalDetalle from "./ModalClienteDetalle"

import Sort from "../../Widgets/Sort"

import '../../../Styles/Modules/Admin/clientes.scss'
import moment from 'moment';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import SelectVendedor from './Clientes/SelectVendedor';

const { Content } = Layout;
const { Text } = Typography
const { Option } = Select
const { confirm } = Modal


const controller = new AbortController();
/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class ClientesList extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,

            clientes: {
                data: [],
                page: page ?? 1,
                limit: limit ?? 10,
                filters: filters || [],
                total: 0,
                sort: sort || {
                    createdAt: -1
                },
            },
            vendedores: {
                data: [],
                limit: 10,
                page: 1,
                search: undefined,
                loading: false
            },
            vendedor_id: undefined,
            seleccionados: {},
            panels: [],
            modalCliente: false,
            search: this.props.search || search,

            clearCheckbox: false,
            visibleFilters: false,
            optionsVisible: false
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getClientes({ filters: this.state.filters });
        this.getVendedores()

        this.props.updateFilterSearch(false)
    }

    componentWillUnmount(prevProps) {
        this.props.updateFilterSearch(true)
        // if (prevProps.search !== this.props.search) {
        //     this.setState({ search: this.props.search }, () => this.getClientes({ page: 1 }))
        // }
    }

    /**
    * @memberOf Clientes
    * @method getClientes
    * @description Obtiene ls lista de Clientes
    */
    getClientes = ({

        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search,
        filters = this.state.clientes.filters,
        sort = this.state.clientes.sort,

    } = this.state.clientes, { clientes } = this.state
    ) => {
        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search
        if (filters && filters?.length > 0) params.filters = JSON.stringify(filters)

        this.props.setParams(params)
        let clearCheckbox = (page === 1)

        this.setState({ clientes: { ...this.state.clientes, page, limit, search, filters, sort }, clearCheckbox, loading: true })
        axios.get('/clientes', {
            params: {
                page: clientes.data.length < 1 && page !== 1 ? 1 : page,
                limit: clientes.data.length < 1 && page !== 1 ? limit * page : limit,
                search,
                filters,
                sort
            }
        })
            .then(({ data }) => {

                console.log(data)
                this.setState({
                    clientes: {
                        ...this.state.clientes,
                        data: data.data,
                        total: data.total,
                        search
                    }
                })

            })
            .catch(error => {
                //console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }


    getVendedores = ({ page, limit, search } = this.state.vendedores, { vendedores } = this.state) => {

        vendedores.loading = true;
        vendedores.page = page;
        vendedores.limit = limit;

        this.setState({ vendedores })

        axios.get('/usuarios', {
            params: {
                page, limit, search,
                clientes: false
            }
        })
            .then(response => {
                vendedores.data = (page === 1) ? response.data.data.itemsList : [...vendedores.data, ...response.data.data.itemsList];
                vendedores.total = response.data.data.itemCount
                vendedores.pages = response.data.data.pageCount
                vendedores.loading = false;

                this.setState({ vendedores })
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.setState(state => {
                    state.vendedores.loading = false
                    return state
                })
            })
    }

    renderEstatus = (status) => {

        switch (status) {
            case 0:
                return <Tag color="#FF4D4F">Desistió / Perdido</Tag>
            case 1:
                return <Tag color="#FFE608">Registrado</Tag>
            case 2:
                return <Tag color="#5465FF">Contactado</Tag>
            case 3:
                return <Tag color="#F2802E">Ha invertido</Tag>
            case 4:
                return <Tag color="#00CD98">Pagado</Tag>
            default:
                return <Tag color="#FFE608">Registrado</Tag>
        }
    }

    updateEstatus = (cliente_id, estatus) => {
        axios.put('/clientes/update', {
            cliente_id,
            estatus
        }).then(success => {
            message.success("Estatus de cliente actualizado")
            this.getClientes()
        }).catch(error => {
            message.error("Error al actualizar el estatus")
        })
    }

    /**
     *
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Renderizamos los filtros que estén en el arreglo.
     */
    renderFiltros = ({ filters = [] } = this.state.clientes) => {

        return filters.map(filtro => {
            return <Tag
                closable
                closeIcon={<div className="point" onClick={event => this.deleteFilter(event, filtro)}></div>}
                style={{ lineHeight: '15px', paddingTop: '3px', paddingBottom: '3px' }}
            >
                <small>{filtro?.objectName?.toUpperCase().replace("_", " ")}<br></br></small>
                {filtro?.filter?.range?.join(' - ')}
                {filtro?.filter?.nombre || filtro?.filter?.label || filtro?.filter?.razon_social || filtro?.filter?.email || filtro?.filter?.folio}
            </Tag>
        });
    }

    /**
     *
     * @param {*} event
     * @param {*} filtro
     * @memberof Clientes
     * @description Para eliminar los filtros
     */
    deleteFilter = (event, filtro) => {
        event.preventDefault()
        try {
            let { filters } = this.state.clientes
            let { _id, name } = filtro

            filters = filters.filter(f => {
                if (f.name === name) {
                    if (f.name === 'fecha') return false
                    return (f._id !== _id)
                }
                return true
            })

            this.setState({ clientes: { ...this.state.clientes, filters } }, () => this.getClientes())
        } catch (error) {
            console.log(error)
            this.setState({ visibleFilters: true })
        }
    }

    asignarVendedor = (vendedor_id) => {
        this.setState({ loading: true })

        axios.post('/clientes/asignar-vendedor', {
            vendedor_id, ids: Object.keys(this.state.seleccionados)
        }).then(success => {
            message.success("Vendedor asignado")
            this.getClientes({ page: 1 })
            this.getVendedores({ search: undefined })
        }).catch(error => {
            message.error("Error al asignar vendedor")
        })
    }

    /**
     *
     *
     * @param {*} key
     * @memberof Transacciones
    */
    setSort = (key) => {
        let value;
        switch (this.state.clientes.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getClientes({
            sort: {
                ...this.state.clientes.sort,
                [key]: value
            }
        })
    }


    /**
     *
     *
     * @param {*} key
     * @memberof Transacciones
     */
    setSort = (key) => {
        let value;
        switch (this.state.clientes.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getClientes({
            sort: {
                ...this.state.clientes.sort,
                [key]: value
            }
        })
    }
    render() {


        console.log("this.state", this.state)

        const { clientes, loading } = this.state

        return (
            <>
                {/* <Spin spinning={this.state.loading}> */}
                <PageHeader
                    className="admin-page-header"
                    title={<Row className="width-100" justify="space-between" align="middle">
                        <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                            <span className="ant-page-header-heading-title" >
                                Clientes
                            </span>
                        </Col>
                        <Col span={24}>
                            {this.renderFiltros()}
                        </Col>
                    </Row>}
                    extra={<Space>
                        <Link to={`/admin/crm/clientes`} >
                            <Button > Ver Agrupado </Button>
                        </Link>
                        <Button
                            onClick={() => this.setState({ visibleFilters: true })}
                            className="button-plus"
                            icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                    </Space>}
                />
                <Content
                    className="admin-content content-bg clientes pd-1"
                    id="clientes-layout"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={12}>
                            <Space size={16}>
                                <Select
                                    style={{ width: 200 }}
                                    showSearch
                                    placeholder={"Asignar vendedor"}
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    disabled={!(this.props.editarClientes || this.props.asignarVendedor)}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(value) => this.getVendedores({ search: value })}
                                    onSelect={(value) => {
                                        if (Object.keys(this.state.seleccionados).length < 1) {
                                            message.info("Necesitas seleccionar algunos clientes")
                                            this.getVendedores({ search: undefined })
                                            return
                                        }

                                        this.asignarVendedor(value)
                                    }}
                                    notFoundContent={"No se encontro vendedor"}
                                >
                                    {
                                        this.state.vendedores.data.map(({ _id, nombre, apellido_paterno, apellido_materno }) => {
                                            return <Option key={_id} value={_id}>{`${nombre || ""} ${apellido_paterno || ""} ${apellido_materno || ""}`}</Option>
                                        })
                                    }
                                </Select>
                                <Tooltip
                                    title="Eliminar"
                                >
                                    <Button
                                        type="primary"
                                        size="large"

                                        disabled={!this.props.eliminarClientes}
                                        icon={<DeleteOutlined />}
                                        onClick={() => {
                                            if (Object.keys(this.state.seleccionados).length < 1) {
                                                message.info("Selecciona al menos 1 cliente")
                                                return
                                            }

                                            confirm({
                                                title: "¿Quieres eliminar estos clientes?",
                                                icon: <WarningFilled />,
                                                content: "Se eliminará todo lo relacionado a los clientes (inversiones, transacciones, etc.)",
                                                okText: "Eliminar",
                                                cancelText: "Cancelar",
                                                onOk: () => axios.delete('/clientes/delete-many', { params: { ids: Object.keys(this.state.seleccionados) } })
                                                    .then((response) => {
                                                        message.success(response?.data?.message)
                                                    })
                                                    .catch((error) => {
                                                        message.error(error?.response?.data?.message);
                                                    })
                                                    .finally(() => {
                                                        this.setState({ seleccionados: [] })
                                                        this.getClientes({ page: 1 })
                                                    }),
                                                onCancel: () => { }
                                            })
                                        }}
                                    />
                                </Tooltip>
                            </Space>
                        </Col>
                        <Col span={12} style={{ justifyContent: "end", textAlign: "end" }}>
                            <Input
                                placeholder='Buscar Clientes'
                                style={{ maxWidth: 350 }}
                                // value={this.state.clientes.search}
                                onChange={e => this.getClientes({ page: 1, search: e.target.value })}
                            />
                        </Col>
                        <Col xs={24}>
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin Clientes" }}
                                dataSource={clientes.data}
                                pagination={{
                                    current: clientes.page,
                                    pageSize: clientes.limit,
                                    total: clientes.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    onChange: (page, limit) => this.getClientes({ page, limit })
                                }}
                                header={
                                    <Row align="middle">
                                        <Col flex="none">
                                        </Col>
                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={4} style={{ color: "gray" }} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort['nombre']} onClick={() => this.setSort("nombre")} >
                                                        <Text ellipsis strong>Nombre del Cliente</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={4} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["email"]} onClick={() => this.setSort("email")} >
                                                        <Text ellipsis strong>Correo Electrónico</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["telefono"]} onClick={() => this.setSort("telefono")} >
                                                        <Text ellipsis strong>Número Telefónico</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["vendedor.nombre"]} onClick={() => this.setSort("vendedor.nombre")} >
                                                        <Text ellipsis strong>Vendedor</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={2} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["createdAt"]} onClick={() => this.setSort("createdAt")} >
                                                        <Text ellipsis strong>Fecha Registro</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={2} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["createdAt"]} onClick={() => this.setSort("createdAt")} >
                                                        <Text ellipsis strong>Fecha Inversión</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} >
                                                    <Sort
                                                        className="center"
                                                        sort={this.state.clientes?.sort && this.state.clientes?.sort["estatus"]} onClick={() => this.setSort("estatus")} >
                                                        <Text ellipsis strong>Estatus</Text>
                                                    </Sort>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="none">
                                                    {
                                                        !this.state.clearCheckbox && (
                                                            <Checkbox
                                                                onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        this.state.seleccionados[item._id] = true
                                                                    } else {
                                                                        delete this.state.seleccionados[item._id]
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    }
                                                </Col>
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis strong>{item.nombre} {item.apellido_paterno} {item.apellido_materno}</Text>
                                                        </Col>
                                                        <Col xs={4} className="center" >
                                                            <Text ellipsis >{item.email}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >{item.telefono}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            {(this.props.editarClientes || this.props.asignarVendedor) ? <SelectVendedor
                                                                item={item?.vendedor}
                                                            /> : (item.vendedor ? (
                                                                <Space direction="horizontal" size={8}>
                                                                    <CustomAvatar
                                                                        image={`${axios.defaults.baseURL}/upload/${item.vendedor?.avatar}`}
                                                                        name={`${item.vendedor?.nombre || ''} ${item.vendedor?.apellido_paterno || ''} ${item.vendedor?.apellido_materno || ''}`}
                                                                    />
                                                                    <Text ellipsis >{item.vendedor?.nombre}</Text>
                                                                </Space>
                                                            ) : (<Text ellipsis >Sin Vendedor Asignado</Text>)
                                                            )}
                                                        </Col>
                                                        <Col xs={2} className="center" >
                                                            {/* {console.log("item.createdAt", item.createdAt)} */}
                                                            <Text ellipsis >{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
                                                        </Col>
                                                        <Col xs={2} className="center" >
                                                        {console.log(item)}
                                                            {item.fecha_compra ? moment(item.fecha_compra).format("DD/MM/YYYY") : 'No ha invertido'}
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Dropdown
                                                                overlay={<Menu>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 0)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-1`} />
                                                                            <Text>Desistió / Cancelado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 1)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-2`} />
                                                                            <Text>Registrado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 2)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-3`} />
                                                                            <Text>Contactado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 3)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-4`} />
                                                                            <Text>Ha invertido</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 4)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`clientes-circle small circle-5`} />
                                                                            <Text>Pagado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                </Menu>}
                                                                className="clientes-dropdown"
                                                                trigger={['click']}
                                                                disabled={!this.props.editarClientes}
                                                            >
                                                                {this.renderEstatus(item.estatus)}
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Space wrap>
                                                                <Link to={`/admin/crm/clientes/detalle/${item._id}`} >
                                                                    <Button type='primary' disabled={!this.props.verClienteDetalles} className='btn-primary' icon={<IconEye />} />
                                                                </Link>
                                                                <Button
                                                                    type='primary'
                                                                    disabled={!this.props.editarClientes}
                                                                    className='btn-secondary'
                                                                    icon={<IconBtnEdit />} onClick={() => this.setState({ modalDetalle: true, cliente_id: item._id })} />
                                                                <Button type="primary" disabled={!this.props.eliminarClientes} danger
                                                                    className='btn-delete' title="Eliminar" icon={<IconBtnDelete />}
                                                                    onClick={() => {
                                                                        confirm({
                                                                            title: "¿Quieres eliminar este cliente?",
                                                                            icon: <WarningFilled />,
                                                                            content: "Se eliminará todo lo relacionado al cliente (inversiones, transacciones, etc.)",
                                                                            okText: "Eliminar",
                                                                            cancelText: "Cancelar",
                                                                            onOk: () => axios.delete('/clientes/delete', { params: { cliente_id: item._id } })
                                                                                .then((response) => {
                                                                                    message.success(response?.data?.message)
                                                                                    this.getClientes()
                                                                                })
                                                                                .catch((error) => {
                                                                                    message.error(error?.response?.data?.message);
                                                                                    this.getClientes()
                                                                                }),
                                                                            onCancel: () => { }
                                                                        })
                                                                    }}
                                                                />
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {/* </Spin> */}
                {this.props.crearClientes && <FloatingButton title="Nuevo Registro" disabled={!this.props.crearClientes} onClick={() => this.setState({ modalDetalle: true, cliente_id: null })} />}


                <DrawerFiltros
                    ref={this.drawerFiltros}
                    title="Filtrar Clientes"
                    csv="clientes"
                    updateFilters={filters => this.getClientes({
                        page: 1,
                        filters
                    })}
                    onClose={() => this.setState({ visibleFilters: false })}
                    visible={this.state.visibleFilters}
                    estatus={{
                        type: 'list',
                        data: [{
                            _id: 0,
                            label: "Desisitío / Perdido"
                        }, {
                            _id: 1,
                            label: "Registrado"
                        }, {
                            _id: 2,
                            label: "Contactado"
                        }, {
                            _id: 3,
                            label: "Ha invertido"
                        }, {
                            _id: 4,
                            label: "Pagado"
                        }]
                    }}
                    haciendas={false}
                    cuentas={false}
                    clientes={false}
                    ordenes={false}
                    ordenes_compras={false}
                    area_rubro={false}
                    clasificadores={false}
                    razones_sociales={false}
                    negocios={false}
                    proveedores={false}
                    inversiones={false}
                    vendedores_transaccion={false}
                />

                <ModalCliente
                    visible={this.state.modalCliente}
                    cliente_id={this.state.cliente_id}
                    onClose={() => {
                        this.setState({ modalCliente: false, cliente_id: undefined })
                        this.getClientes()
                    }}

                />

                <ModalDetalle
                    visible={this.state.modalDetalle}
                    cliente_id={this.state.cliente_id}
                    onCancel={() => {
                        this.setState({ modalDetalle: false, cliente_id: undefined })
                        this.getClientes()
                    }}
                />
            </>
        )
    }
}



export default function (props) {

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        crearClientes: ["clientes", "create"],
        editarClientes: ["clientes", "edit"],
        eliminarClientes: ["clientes", "delete"],
        verClienteDetalles: ["clientes", "detalles"],
        asignarVendedor: ["clientes", "assign-vendedor"]
    })

    const navigate = useNavigate();

    const [params, setParams] = useSearchParams();

    console.log("permisos", permisos)

    return <ClientesList {...props}
        user={user}
        tipo_acceso={user.rol_id?.tipo}
        {...permisos}
        navigate={navigate}
        // location={location}
        params={params}
        setParams={setParams}
    />
}