import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';
import ListaHaciendas from '../../Components/Admin/Haciendas/ListaHaciendas'
import HaciendaDetalle from '../../Components/Admin/Haciendas/HaciendaDetalle'
import HaciendaCompleta from '../../Components/Admin/Haciendas/HaciendaCompleta';
import HaciendaMensual from '../../Components/Admin/Haciendas/HaciendaMensual';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterInversion(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<ListaHaciendas  {...props}  />} />
            <Route path="detalle/:hacienda_id" element={<HaciendaDetalle  {...props}  />} />
            <Route path="reporte-mensual/:hacienda_id" element={<HaciendaDetalle  {...props}  />} />
            <Route path="completa/:hacienda_id" element={<HaciendaCompleta  {...props}  />} />
            <Route path="mensual/:hacienda_id" element={<HaciendaMensual  {...props}  />} />
        </Routes>
    )
}

 export default RouterInversion

