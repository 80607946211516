import React, { Component } from 'react';
import { Row, Col, List, PageHeader, Layout, Typography, Button, Statistic, message, Spin, Tooltip, Space } from 'antd'
import axios from 'axios';

import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { CheckCircleOutlined, CalendarOutlined, CloseOutlined } from '@ant-design/icons'
import { CardNominasContainer as CardContainer } from '../../Widgets/Cards'
import moment from 'moment';
import '../../../Styles/Modules/Admin/nominas.css'
import User from "../../../Hooks/Logged";
import ModalNomina from './ModalNomina';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import { AiOutlineFileExcel } from 'react-icons/ai';
const { Text } = Typography
const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Dashboard general de nominas
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Dashboard.
     * @param {*} props
     * @memberof Dashboard
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            nomina: {},
            modal_visible: false,
            pagos_pendientes: [],
            pagos_realizados: []
        }
    }
    static contextType = User


    componentDidMount() {
        this.getLatestNomina()

    }


    /**
     *
     *
     * @memberof Dashboard
     * @description Obtiene la última nomina o la nómina más reciente
     */
    getLatestNomina = () => {
        this.setState({ loading: true })
        axios.get("/nominas/ultima")
            .then(({ data }) => {
                this.setState({ nomina: { ...data } })
                this.getLatestPagos("pagos_pendientes")
                this.getLatestPagos("pagos_realizados")
            })
            .catch(e => {
                console.log("e", e)
                message.error('Error al cargar la nómina')
            }).finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof Dashboard
     * @description Obtiene pagos tanto los pendientes comom los realizados. 
     * @method getLatestPagos
     */
    getLatestPagos = (tipo) => {
        let params = {}
        params[tipo] = true

        axios.get("/empleados-nominas", {
            params: {
                nomina_id: this.state.nomina?._id,
                ...params
            }
        })
            .then(({ data }) => {
                let state = {}
                state[tipo] = data.data

                this.setState(state)
            })
            .catch(e => {
                console.log("e", e)
            })
    }


    render() {

        const { nomina } = this.state;
        const user = this.context

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Nóminas"

                    extra={[
                        <Tooltip title="Descargar Excel">
                            <Button
                                icon={<AiOutlineFileExcel style={{ position: "relative", top: "3" }} />}
                                type="primary"
                                size='large'
                                onClick={() => {
                                    return axios.request({
                                        url: '/nominas/csv/' + nomina?._id,
                                        method: 'GET',
                                        responseType: 'blob'
                                    }).then(({ data }) => {
                                        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                                        const link = document.createElement('a');
                                        link.href = downloadUrl;
                                        link.setAttribute('download', `ReporteNomina-${moment(nomina?.fecha).format("YYYY-MM-DD")}-${moment(nomina?.fecha_fin).format("YYYY-MM-DD")}.xlsx`); //any other extension
                                        document.body.appendChild(link);
                                        link.click();
                                        link.remove();
                                    })
                                }}
                                title={nomina?.estatus < 2 ? "Es necesario que esté autorizado para continuar" : "Ver Detalle"}
                            />

                        </Tooltip>
                    ]}
                />
                <Spin spinning={this.state.loading}>
                    <Content className="w-100 admin-content content-bg pd-1">
                        <Row className='' gutter={[18, 18]}>
                            <Col xs={24} sm={12} md={12}>
                                <Row className='w-100'>
                                    <CardContainer title="Nómina Actual"
                                        extra={<Button type='ghost' className='ant-btn-ghost-green' shape='round' disabled={!(nomina.estatus === 3 || nomina.estatus === undefined)}
                                            style={{ display: "block", float: "right", minWidth: 200 }} onClick={() => this.setState({ modal_visible: true })}
                                        >Crear Nómina</Button>}
                                    >
                                        <Text className='text-gray'>{moment(nomina.fecha).format("LL")} - {moment(nomina.fecha_fin).format("LL")}</Text>
                                        <Row className='mb-1'>
                                            <Col span={16} lg={24} md={24}>
                                                <Text>Monto Total: $ <strong>{nomina.monto_total ? nomina.monto_total.toMoney(true) : 0} mxn</strong></Text>
                                            </Col>
                                            <Col span={8} lg={24} md={24}>

                                                <Link to={"/admin/nominas/nomina/empleados/autorizar/" + nomina?._id}>
                                                    <Button type='ghost' className='ant-btn-ghost-green' shape='round'
                                                        style={{ display: "block", float: "right", minWidth: 200 }}
                                                        disabled={!(nomina.estatus === 1)}
                                                    >Autorizar</Button>
                                                </Link>

                                                <Link to={"/admin/nominas/nomina/empleados/" + nomina?._id}>
                                                    <Button
                                                        type='ghost'
                                                        className='ant-btn-ghost-green' shape='round'
                                                        style={{ display: "block", float: "right", minWidth: 200 }}
                                                        disabled={!(nomina.estatus === 0)}
                                                    >Cargar</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className='mb-1'>
                                            <Col span={16} lg={24} md={24}>
                                                <Text>Monto Pagado: $ <strong>{nomina.monto_pagado ? nomina.monto_pagado.toMoney(true) : 0} mxn</strong></Text>
                                            </Col>
                                            <Col span={8} lg={24} md={24}>
                                                <Link to={"/admin/nominas/nomina/finanzas/" + nomina?._id}>
                                                    <Button type='ghost' className='ant-btn-ghost-green' shape='round'
                                                        style={{ display: "block", float: "right", minWidth: 200 }}
                                                        disabled={!(nomina.estatus === 2)}
                                                    >Finanzas</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardContainer>
                                </Row>
                                <Row className='w-100 mt-1' >
                                    <CardContainer  >
                                        <Row>
                                            <Col span={16}>
                                                <Text>Historial de Nóminas</Text>
                                            </Col>
                                            <Col span={8}>
                                                <Link to="/admin/nominas/lista">
                                                    <Button type='ghost' className='ant-btn-ghost-green' shape='round' style={{ display: "block", float: "right", minWidth: 200 }}>Lista de Nóminas Prevías</Button>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </CardContainer>
                                </Row>
                            </Col>
                            <Col xs={24} sm={12} md={12}>
                                <Row className='w-100'>
                                    <CardContainer title="Autorización Nómina"  >
                                        <Row className='mb-1'>
                                            <Col span={16}>
                                                <Text>Cargada</Text>
                                            </Col>
                                            <Col span={8}>
                                                {(this.state.nomina?.estatus >= 1) ? <CheckCircleOutlined className="nomina-check" /> : <CloseOutlined className='nomina-nocheck' />}

                                            </Col>
                                        </Row>
                                        <Row className='mb-1'>
                                            <Col span={16}>
                                                <Text>Revisada</Text>
                                            </Col>
                                            <Col span={8}>
                                                {(this.state.nomina?.estatus >= 2) ? <CheckCircleOutlined className="nomina-check" /> : <CloseOutlined className='nomina-nocheck' />}

                                            </Col>
                                        </Row>
                                        <Row className='mb-2'>
                                            <Col span={16}>
                                                <Text>Finanzas</Text>
                                            </Col>
                                            <Col span={8}>
                                                {(this.state.nomina?.estatus > 2) ? <CheckCircleOutlined className='nomina-check' /> : <CloseOutlined className='nomina-nocheck' />}
                                            </Col>
                                        </Row>
                                    </CardContainer>
                                </Row>
                                <Row className='w-100 mt-1'>
                                    <CardContainer title="Próxima Nómina"   >
                                        <Row className='mb-1'>
                                            <Col span={16}>
                                                <Text>{moment(this.state.nomina.fecha).add(1, "week").format("LL")} - {moment(this.state.nomina.fecha_fin).add(1, "week").format("LL")}</Text>
                                            </Col>
                                            <Col span={8}>
                                                <CalendarOutlined className="nomina-calendar" />
                                            </Col>
                                        </Row>
                                    </CardContainer>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mt-1" gutter={[18, 18]}>
                            <Col xs={24} sm={24} md={12}>
                                <CardContainer title="Pagos Pendientes" className="card-half-screen" >
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.pagos_pendientes}
                                        renderItem={item => (
                                            <List.Item>
                                                <Row align="middle" className="width-100 pl-1 pr-1" gutter={[12, 12]}>
                                                    <Col flex={"none"}>
                                                        <CustomAvatar
                                                            {
                                                            ...(item.empleado_id?.usuario_id?.avatar ?
                                                                { image: item.empleado_id?.usuario_id?.avatar } :
                                                                { name: `${item.empleado_id?.nombre} ${item.empleado_id?.apellido}` }
                                                            )
                                                            }
                                                            style={{ marginRight: '8px' }}
                                                            size={"large"}
                                                        />
                                                    </Col>
                                                    <Col flex={"auto"}>
                                                        <Text style={{ marginBottom: 0, fontSize: 17 }}>{item.empleado_id?.nombre} {item.empleado_id?.apellido}</Text>
                                                    </Col>
                                                    <Col flex={"none"}>
                                                        <Statistic value={item.monto_autorizado} prefix="$" />
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </CardContainer>
                            </Col>
                            <Col xs={24} sm={24} md={12}>
                                <CardContainer title="Pagos Realizados" className="card-half-screen">
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.pagos_realizados}
                                        renderItem={item => (
                                            <List.Item>
                                                <Row align="middle" className="width-100 pl-1 pr-1" gutter={[12, 12]}>
                                                    <Col flex={"none"}>
                                                        <CustomAvatar
                                                            {
                                                            ...(item.empleado_id?.usuario_id?.avatar ?
                                                                { image: item.empleado_id?.usuario_id?.avatar } :
                                                                { name: `${item.empleado_id?.nombre} ${item.empleado_id?.apellido}` }
                                                            )
                                                            }
                                                            style={{ marginRight: '8px' }}
                                                            size={"large"}
                                                        />
                                                    </Col>
                                                    <Col flex={"auto"}>
                                                        <Text style={{ marginBottom: 0, fontSize: 17 }}>{item.empleado_id?.nombre} {item.empleado_id?.apellido}</Text>
                                                    </Col>
                                                    <Col flex={"none"}>
                                                        <Statistic value={item.monto_autorizado} prefix="$" />
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                                    />
                                </CardContainer>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
                <ModalNomina
                    visible={this.state.modal_visible}
                    onClose={() => {
                        this.setState({ modal_visible: false })
                        this.getLatestNomina()
                    }}
                />
            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();

    return <Dashboard {...props} navigate={navigate} params={params} />
}