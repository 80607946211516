import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, Divider, Select } from 'antd';
import moment from "moment";
import '../../../Styles/Modules/Admin/nominas.css';


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalEmpleadoNomina
 * @extends {Component}
 */
class ModalEmpleadoNomina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            imagenes: [],
            usuarios: [],
            usuario_id: undefined,
            empleados: {
                data: [],
                page: 1,
                limit: 20,
                pages: 0,
                total: 0,
                search: null,
            },
            modalidad: undefined,
        }
    }

    modalRef = React.createRef()

    componentDidMount() {

        this.getEmpleados()
        this.getNomina()
    }


    /**
    * @memberOf EmpleadosNomina
    * @method getEmpleados
    * @descripcion Obtiene los empleados
    * 
    * */
    getEmpleados = ({
        page = this.state.empleados.page,
        limit = this.state.empleados.limit,
        search = this.state.empleados.search
    } = this.state.empleados) => {
        this.setState({ loading: true })
        axios.get('/empleados', {
            params: {
                page,
                limit,
                search,
                sin_nomina: this.props.nomina_id,
                estatus: 1,
            }
        }).then(response => {
            this.setState({
                empleados: response.data
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los empleados')
        }).finally(() => this.setState({ loading: false }))

    }

    /**
     * @memberOf EmpleadosNomina
     * @method getEmpleadoNomina
     * @descripcion Obtiene los empleados de la nomina actual
     * 
     * */
    getEmpleadoNomina = () => {
        axios.get(`/empleado-nomina/${this.props.empleado_nomina_id}`, {

        }).then(response => {
            console.log("response", response.data);
            if (response.data.empleado_id.modalidad_pago === 3) {
                this.setState({
                    modalidad: 3,
                })
            }

            // horas_trabajadas_detalle
            let horas_trabajadas_detalle = this.state.dias_pagados?.map((e, index) => (Array(response?.data?.horas_trabajadas_detalle) ? (response?.data?.horas_trabajadas_detalle[index] ?? 0) : 0)) ?? []


            this.modalRef.current.setFieldsValue({
                ...response.data,
                horas_trabajadas_detalle
            })
            this.calcularTotales()


        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })

    }

    /**
     * @memberOf EmpleadosNomina
     * @method onFinish
     * @descripcion Se ejecuta al hacer submit al formulario
     * 
     * */
    onFinish = (values) => {
        if (this.props.empleado_nomina_id) {
            this.updateEmpleadoNomina(values)
        } else {
            this.addEmpleadoNomina(values)
        }

    }


    /**
     * @memberOf EmpleadosNomina
     * @method updateEmpleadoNomina
     * @descripcion Actualiza la informacion del emplado en la nomina
     * 
     * */
    updateEmpleadoNomina = (values) => {
        axios.put(`/empleado-nomina/`, {
            ...values,
            empleado_nomina_id: this.props.empleado_nomina_id
        }).then(response => {
            message.success('Registro actualizado')
            this.props.onCancel(true)
        }).catch(error => {
            console.log("error", error);
            message.error('Error al actualizar el registro')
        })

    }

    /**
     * @memberOf EmpleadosNomina
     * @method addEmpleadoNomina
     * @descripcion Añade un empleado a la nomina
     * 
     * */
    addEmpleadoNomina = (values) => {
        console.log("values", values);
        axios.post(`/empleado-nomina`, {
            ...values,
            nomina_id: this.props.nomina_id
        }).then(response => {
            message.success('Empleado registrado')
            this.props.onCancel(true)
        }).catch(error => {
            console.log("error", error);
            message.error('Error al registrar el empleado')
        })

    }


    /**
     * @memberOf EmpleadosNomina
     * @method onSelectEmpleado
     * @descripcion Realiza los calculos al seleccionar un empleado
     * 
     * */
    onSelectEmpleado = (empleado_id) => {
        let index = this.state.empleados.data.findIndex(e => e._id.toString() === empleado_id.toString())

        if (index !== -1) {


            if (this.state.empleados.data[index].modalidad_pago === 3) {
                this.setState({
                    modalidad: 3
                })
                this.modalRef.current.setFieldsValue({
                    percepciones: {
                        salario: this.state.empleados.data[index].salario
                    }
                })
            } else {
                this.modalRef.current.setFieldsValue({
                    percepciones: {
                        salario: this.state.empleados.data[index].salario
                    }
                })

                this.setState({ modalidad: this.state.empleados.data[index].modalidad_pago })
            }


            this.calcularTotales(this.state.empleados.data[index].salario)
        }

    }

    /**
     * @memberOf EmpleadosNomina
     * @method calcularTotales
     * @descripcion Realiza los calculos al seleccionar un empleado
     * 
     * */
    calcularTotales = (salario) => {
        let datos = this.modalRef.current.getFieldsValue()
        let extras = 0
        let monto_autorizado = 0
        let dias_pagados = datos.dias_pagados
        salario = salario ? salario : datos.percepciones.salario


        datos.deducciones = {
            ispt: datos?.deducciones?.ispt ?? 0,
            imss: datos?.deducciones?.imss ?? 0
        }

        datos.percepciones = {
            salario: datos?.percepciones?.salario ?? 0,
            horas_extras_trabajadas: datos?.percepciones?.horas_extras_trabajadas ?? 0,
            horas_extras: datos?.percepciones?.horas_extras ?? 0,
            prima_dominical: datos?.percepciones?.prima_dominical ?? 0,
            dias_festivos: datos?.percepciones?.dias_festivos ?? 0
        }

        datos.monto_autorizado = datos.monto_autorizado ?? 0
        datos.horas_extras_trabajadas = datos.horas_extras_trabajadas ?? 0
        datos.horas_trabajadas_total = datos.horas_trabajadas_total ?? 0



        let salarioSum = 0
        let totalHoras = 0

        if (this.state.modalidad === 3) {
            for (let i = 0; i < datos.horas_trabajadas_detalle.length; i++) {
                totalHoras += datos.horas_trabajadas_detalle[i]
            }

            // console.log("datos.horas_extras_trabajadas", datos.horas_extras_trabajadas)
            // console.log("datos.percepciones.horas_extras", datos.percepciones.horas_extras)
            extras = datos.horas_extras_trabajadas * datos.percepciones.horas_extras
            salarioSum = salario * totalHoras
        } else {
            if (!dias_pagados) return
            extras = datos.horas_extras_trabajadas * datos.percepciones.horas_extras
            salarioSum = salario * datos.dias_pagados
        }

        let deducciones = ((datos.deducciones.ispt * 100) + (datos.deducciones.imss * 100)) / 100
        let percepciones = salarioSum + datos.percepciones.prima_dominical + datos.percepciones.dias_festivos + extras

        monto_autorizado = ((percepciones * 100) - (deducciones * 100)) / 100

        console.log("monto_autorizado", monto_autorizado)
        console.log("monto_autorizado", percepciones)
        console.log("monto_autorizado", deducciones)

        this.modalRef.current.setFieldsValue({
            monto_autorizado,
            horas_trabajadas_total: totalHoras
        })
    }

    getNomina = () => {

        axios.get(`/nominas/${this.props.nomina_id}`, {

        }).then(response => {
            console.log("response", response.data);
            this.setState({
                nomina: response.data,
                dias_pagados: this.getDates(moment(response.data.fecha), moment(response.data.fecha_fin))
            }, () => {
                if (this.props.empleado_nomina_id) {
                    this.getEmpleadoNomina()
                }
            })



        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })

    }

    getDates(startDate, stopDate) {
        console.log("startDate", startDate);
        console.log("stopDate", stopDate);
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = currentDate.add(1, 'days');
        }
        console.log("dateArray", dateArray);
        return dateArray;
    }


    render() {

        window.x = this.modalRef

        return (
            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.onFinish}
                className="pd-1"
                initialValues={{
                    dias_pagados: 5,
                    percepciones: {
                        salario: 0,
                        prima_dominical: 0,
                        dias_festivos: 0
                    },
                    deducciones: {
                        ispt: 0,
                        imss: 0
                    },
                    monto_autorizado: 0

                }}
                onValuesChange={() => this.calcularTotales()}
            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[14, 24]}>
                        {this.props.empleado_nomina_id ? null : <Col xs={24} lg={24} >
                            <Form.Item
                                label="Empleado"
                                name="empleado_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el empleado"
                                }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Buscar empleado"
                                    filterOption={false}
                                    onSearch={(value) => this.getEmpleados({ search: value })}
                                    onSelect={this.onSelectEmpleado}
                                >
                                    {
                                        this.state.empleados.data?.map(empleado => <Option value={empleado._id}>{empleado.nombre} {empleado.apellido}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>}
                    </Row>

                    {this.state.modalidad === 3 ? <>
                        <Divider orientation="left" plain>
                            Dias Trabajados
                        </Divider>

                        {this.state.dias_pagados?.map((dia, index) => <>
                            <Row align="middle">
                                <Col xs={24} lg={14} >
                                    <Text className="dias_trabajados">{moment(dia).format('dddd[,] D [de] MMMM [del] YYYY')}</Text>
                                </Col>

                                <Col xs={24} lg={10} >
                                    <Form.Item
                                        name={["horas_trabajadas_detalle", index]}
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: "Por favor,ingreselos dias trabajados"
                                                },
                                            ]
                                        }
                                    >
                                        <InputNumber min={0} max={24} addonAfter={<small>hrs. trabajadas</small>} onFocus={event => event.target.select()} className="width-100" />
                                    </Form.Item>
                                </Col>
                            </Row>



                        </>)}
                        <Divider orientation="left" plain>
                            Total
                        </Divider>

                        <Row align="middle">
                            <Col xs={24} lg={14} >
                                <Text>Suma de horas trabajadas</Text>
                            </Col>

                            <Col xs={24} lg={10} >
                                <Form.Item
                                    name='horas_trabajadas_total'
                                >
                                    <InputNumber min={0} disabled={true} addonAfter={<Text>Horas trabajadas</Text>} className="width-100" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={14} >
                            <Text>Horas Extras Trabajadas</Text>
                        </Col>

                        <Col xs={24} lg={10} >
                            <Form.Item
                                name='horas_extras_trabajadas'
                            >
                                <InputNumber min={0} disabled={false} addonAfter={<small>hrs. trabajadas</small>} className="width-100" />
                            </Form.Item>
                        </Col>
                        </Row>
                    </> : <Row gutter={[14, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Días Trabajados"
                                name="dias_pagados"
                                rules={[{
                                    required: true,
                                    message: "Por favor,ingreselos dias trabajados"
                                }]}
                            >
                                <InputNumber min={0} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={14} >
                            <Text>Horas Extras Trabajadas</Text>
                        </Col>

                        <Col xs={24} lg={10} >
                            <Form.Item
                                name='horas_extras_trabajadas'
                            >
                                <InputNumber min={0} disabled={false} addonAfter={<small>hrs. trabajadas</small>} className="width-100" />
                            </Form.Item>
                        </Col>
                    </Row>}

                    <Divider orientation="left" plain>
                        Deducciones
                    </Divider>

                    <Row>
                        <Col xs={14} className="">
                            <Text>ISPT</Text>
                        </Col>
                        <Col xs={10} className="">
                            <Form.Item
                                name={['deducciones', 'ispt']}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={14} className="">
                            <Text>IMSS</Text>
                        </Col>
                        <Col xs={10} className="">
                            <Form.Item
                                name={['deducciones', 'imss']}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left" plain>
                        Percepciones
                    </Divider>

                    <Row>
                        <Col xs={14} className="">
                            {this.state.modalidad === 3 ? <Text>Tarifa por hora</Text> : <Text>Salario <small>(diario)</small></Text>}

                        </Col>
                        <Col xs={10} className="">
                            <Form.Item
                                name={['percepciones', 'salario']}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={14} className="">
                            <Text>Horas Extras Trabajadas</Text>
                        </Col>

                        <Col xs={14} className="">
                            <Text>Costo Horas Extra</Text>
                        </Col>
                        <Col xs={10} className="">
                            <Form.Item
                                name={['percepciones', 'horas_extras']}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={14} className="">
                            <Text>Prima Dominical</Text>
                        </Col>
                        <Col xs={10} className="">
                            <Form.Item
                                name={['percepciones', 'prima_dominical']}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={14} className="">
                            <Text>Días Festivos</Text>
                        </Col>
                        <Col xs={10} className="">
                            <Form.Item
                                name={['percepciones', 'dias_festivos']}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>


                        <Col xs={14} className=" mt-3">
                            <Text>Total</Text>
                        </Col>
                        <Col xs={10} className=" mt-3">
                            <Form.Item
                                name="monto_autorizado"
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0} className="width-100" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function Vista(props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>{props.empleado_nomina_id ? '' : 'Agregar'} Empleado</Title>
        </div>
        <ModalEmpleadoNomina {...props} />
    </Modal>

}