import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Cuentas from '../../Components/Customer/Cuentas/Cuentas'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterInversion(props) {

    return (
        <Routes>
            <Route  path="" element={<Cuentas  />} />
        </Routes>
    )
}

export default RouterInversion;