import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, InputNumber, DatePicker, Upload, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ColorPicker from '../../../Widgets/ColorPicker';
import Mapa from '../../../Widgets/Mapa/Mapa';

const { Title } = Typography;
const { Option } = Select;
const moment = require('moment');
const axios = require('axios').default;


/**
 *
 *
 * @class ModalResumen
 * @extends {Component}
 */
class ModalResumen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            resumen: {},
        }
    }

    modalRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getResumen()
        window.s = this.modalRef
    }


    /**
     * @memberof ModalResumen
     * @method onFinish
     * @description Cuando se envia el formulario
     * @param {object} values - Objecto que contiene los valores del formulario
     */
    onFinish = (values) => {

        this.update(values)
    }

    /**
     * @memberof ModalResumen
     * @method update
     * @param {object} values - Objecto que contiene los valores modificados del formulario
     * @description Actualizamos los valores del resumen
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/resumenes/update', {
            ...values,
            id: this.props.resumen_id,
        }).then(response => {
            message.success('Resumen Actualizado')
            this.props.onSuccess()
        }).catch(error => {
            
            message.error("Error al actualizar resumen")
            
        }).finally(() => this.setState({ loading: false }))
    }


    /**
     * @memberof ModalResumen
     * @method get
     * @description Obtenemos una hacienda del APi
     */
    getResumen = () => {
        this.setState({ loading: true })

        axios.get('/resumen', {
            params: {
                id: this.props.resumen_id
            }
        }).then(response => {
            let data = response.data.data;
            this.setState({
                resumen: data
            })

            this.modalRef.current?.setFieldsValue({
                ...data,
                cliente_id: data.cliente_id._id,
                hacienda_id: data.hacienda_id._id,
            })
        }).catch(error => {
            message.error('Error al cargar resumen')
            
        }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const {resumen} = this.state

        return (
            <Form
                layout="vertical"
                ref={this.modalRef}
                onFinish={this.onFinish}
                onValuesChange={(values, {precio_kilo, kilos_planta, plantas}) => {
                    if(values.precio_kilo || values.kilos_planta){
                        const subGanancia = precio_kilo * kilos_planta * plantas
                        const comision = subGanancia * 0.35

                        this.modalRef.current?.setFieldsValue({
                            obtenido: subGanancia,
                            ganancia: subGanancia - comision
                        })
                    }
                }}
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el cliente"
                                }]}
                            >
                                <Select disabled>
                                    {
                                        resumen.cliente_id ? (
                                            <Option value={resumen.cliente_id._id}>{`${resumen.usuario_id.nombres} ${resumen.usuario_id.apellido_paterno} ${resumen.usuario_id.apellido_materno}`}</Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Hacienda"
                                name="hacienda_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la hacienda"
                                }]}
                            >
                                <Select disabled>
                                    {
                                        resumen.hacienda_id ? (
                                            <Option value={resumen.hacienda_id._id}>{resumen.hacienda_id.nombre}</Option>
                                        ) : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Precio/Kilo"
                                name="precio_kilo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el precio kilo"
                                }]}
                            >
                                <InputNumber 
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" 
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Kilos/Planta"
                                name="kilos_planta"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese los kgs por plata"
                                }]}
                            >
                                <InputNumber 
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" 
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Plantas"
                                name="plantas"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el precio kilo"
                                }]}
                            >
                                <InputNumber
                                    disabled
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" 
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Obtenido"
                                name="obtenido"
                            >
                                <InputNumber 
                                    disabled
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" 
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="Ganancia"
                                name="ganancia"
                            >
                                <InputNumber 
                                    disabled
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100" 
                                />
                            </Form.Item>
                        </Col>
                       
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Editar Resumen</Title>
        </div>
        <ModalResumen {...props} />
    </Modal>

}