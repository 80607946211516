

import React, { Component } from "react";
import { Col, Form, message, Modal, List, Tag, Row, Divider, Statistic, Spin, Typography, } from 'antd';
import '../../../Styles/Global/modales.css';

const axios = require('axios').default;
const { Title, Text, Paragraph } = Typography;


const colors = ["#00000", "#87d068", "#FA9100"];


/**
 *
 *
 * @class ModalDetalle
 * @extends {React.Component}
 * @description Formulario Detalle de Venta
 */
class ModalDetalle extends Component {


    formModalCategoria = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            venta: {}
        }
    }


    /**
     * @methodOf ModalDetalle
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component,
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.getVenta(this.props.id)
    }



    /**
     *
     *
     * @memberof ModalDetalle
     * @description Trae la venta 
     * @param {string} id ObjectId de la venta
     */
    getVenta = (id) => {
        axios.get('/venta', {
            params: {
                id: id
            }
        })
            .then(res => {
                
                this.setState({
                    venta: res.data.data,
                    cliente: res.data.data.cliente_id,
                    detalles: res.data.data.detalles
                })
            }).catch(error => {
                message.error('Error al traer detalle de venta');
                
            })
    }


    /**
        *
        *
        * @memberof Ventas
        * @description Renderiza el Tag correspondiente al status de la venta
        * @param status  1 = En proceso, 2= Aceptada , 3= Rechazada
        */
    renderTag = (status) => {

        let text = "";
        switch (status) {
            case 1:
                text = "En Proceso";
                break;
            case 2:
                text = "Aceptada";
                break;
            case 3:
                text = "Rechazada";
                break;

            default:
                break;
        }
        return <Tag color={colors[status]}>{text}</Tag>
    }



    render() {
        const { venta, cliente, detalles } = this.state;
        return (

            <>
                <Spin spinning={false}>
                    <Title level={3} className="text-center">Detalle Venta</Title>
                    <Form layout="vertical">
                        <Divider orientation="left">Datos Cliente</Divider>

                        {cliente != null ? <Row justify="start" gutter={[24, 24]}>
                            <Col xs={12}>
                                <Paragraph >Nombre </Paragraph>
                                <Text type="secondary" >
                                    {`${cliente?.usuario_id?.nombres} 
                                    ${cliente?.usuario_id?.apellido_paterno}
                                    ${cliente?.usuario_id?.apellido_materno}`}</Text>
                            </Col>
                            <Col xs={12}>
                                <Paragraph >Correo </Paragraph>
                                <Text type="secondary" >{cliente?.usuario_id?.email} </Text>
                            </Col>
                            <Col xs={12}>
                                <Paragraph >Teléfono </Paragraph>
                                <Text type="secondary" >{cliente?.usuario_id?.telefono} </Text>
                            </Col>
                            <Col xs={12}>
                                <Paragraph >Dirección </Paragraph>
                                <Text type="secondary" >
                                    {cliente?.calles} #{cliente?.numero}, {(cliente?.codigo_postal) ? `CP. ${cliente?.codigo_postal},` : null}
                                    {cliente?.ciudad}
                                </Text>
                            </Col>
                        </Row>
                            : <Row justify="center" gutter={[24, 24]}>Sin Cliente Asignado</Row>}
                        <Row gutter={[8,8]}>
                            <Divider orientation="left" className="modal-divider">Productos</Divider>
                            <Col xs={24}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={detalles}
                                    className="pd-1"
                                    header={
                                        <Row className="width-100 ">
                                            <Col span={8}>Nombre</Col>
                                            <Col span={4}>Size</Col>
                                            <Col span={5}>Categoria</Col>
                                            <Col span={3}>Cantidad</Col>
                                            <Col span={4}>Precio</Col>
                                        </Row>
                                    }
                                    renderItem={item => (
                                        <List.Item key={item._id}>
                                            <Row className="width-100">
                                                <Col span={8} ><Text ellipsis >  {item.variante_id.producto_id.nombre}</Text></Col>
                                                <Col span={4} ><Text ellipsis >   {item.variante_id.size_id?.size}</Text></Col>
                                                <Col span={5} ><Text ellipsis >   {item.variante_id.producto_id?.categoria_id?.nombre}</Text></Col>
                                                <Col span={3} ><Text ellipsis >   {item.cantidad}</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {(item.total.toMoney)?item.total.toMoney(true):0}</Text></Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                    footer={
                                        [
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> Subtotal</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {(venta.subtotal ? venta.subtotal : 0).toMoney(true)}</Text></Col>
                                            </Row>,
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> IVA</Text></Col>
                                                <Col span={4} ><Text ellipsis > $ {(venta.iva ? venta.iva : 0).toMoney(true)}</Text></Col>
                                            </Row>,
                                            <Row className="width-100">
                                                <Col span={20} ><Text ellipsis strong> Total</Text></Col>
                                                <Col span={4} ><Text ellipsis strong type="success" style={{ fontSize: 18 }}> $ {(venta.total ? venta.total : 0).toMoney(true)}</Text></Col>
                                            </Row>
                                        ]
                                    }
                                />
                            </Col>
                        </Row>


                        <Divider orientation="left" className="modal-divider">Venta</Divider>
                        <Row justify="space-between" gutter={[8,8]} >
                            <Col xs={24} lg={{ span: 12, }}>
                                <Form.Item label="Total" >
                                    <Statistic
                                        value={venta.total}
                                        prefix={'$'}
                                        suffix={'MXN'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} lg={{ span: 12 }}>
                                <Form.Item label="Estatus" >
                                    {this.renderTag(venta.status)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </>

        )
    }
}


/**
 *@function ModalDetalle
 *@description 
 */
export default function (props) {

    let { visible, close } = props

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            closable={true}
            onCancel={close}
            onOk={close}
            width={700}
        >
            <ModalDetalle {...props} />
        </Modal>
    )
}