import React from 'react'
import { Route, Routes } from "react-router-dom";
import Templates from '../../Components/Admin/Templates/Templates';
import TemplateForm from '../../Components/Admin/Templates/TemplateForm';



function TemplatesRouter(props) {
  return (
    <Routes>
      <Route path='' element={<Templates />} {...props}/>
      <Route path="crear" element={<TemplateForm />}/>
      <Route path="editar/:_id" element={<TemplateForm />}/>
    </Routes>
  )
}

export default TemplatesRouter