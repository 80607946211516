import React, { Component } from 'react'
import { AutoComplete, Input, Button } from 'antd'
import MapboxGL from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import PropTypes from 'prop-types'

MapboxGL.accessToken = process.env.REACT_APP_MAPBOX_API




/**
 *
 *
 * @export
 * @class Busqueda
 * @extends {Component}
 * 
 * 
 * @property onSelect Cuando se selecciona una dirección
 * @property placeholder Texto que se muestra cuando no hay texto
 * @property inputClassName CLase del Input
 * @property size Tamaño del input
 */
export default class    Busqueda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search_text: null,
            direcciones: [],
            search: null,
            valSearch: null
        }
    }


    componentDidUpdate(){
        
        
        
        if (

            typeof this.props?.value === "object" &&
            (
                this.state.valSearch == null 
                ||
                this.state.valSearch?.id !== this.props?.value?.id  
            )

            // this.state.valSearch?._id !== this.props?.value?.id &&
            // this.state.valSearch !== null &&
            // this.props?.value !== null
        ){
            
            this.setState({
                valSearch: this.props?.value,
                search: this.props?.value,
                search_text: this.props?.value?.place_name_es
            })
        }
    }



    /**
     * 
     * @method busquedaDireccion
     * @param {*} search_text texto a buscar
     * 
     * @description Obtenemos las posibles direcciones.
     */
    busquedaDireccion = (search_text) => {
        fetch(`${MapboxGL.config.API_URL}/geocoding/v5/mapbox.places/${search_text}.json?access_token=${MapboxGL.accessToken}&language=es`)
            .then(response => response.json().then(data => this.setState({
                direcciones: data.features
            })))
    }



    /**
     *
     *
     * @param {*} [{ direcciones, search_text }=this.state]
     * @memberof Busqueda
     * 
     * @description Renderizamos las direcciones coincidentes con las busquedas
     */
    renderDirecciones = ({ direcciones, search_text } = this.state) => {
        if (Array.isArray(direcciones) && direcciones.length > 0) {
            return direcciones.map((direccion, index) => {
                let place_name = ""
                if (search_text !== null) {
                    //El regex, obtenemos las palabras claves de busqueda sin simbolos y sin importar las mayusculas
                    let regex = new RegExp(`(${search_text.toLowerCase().split(/\W|_/).filter(x => x).join('|')})`, 'gi')
                    //Buscamos todas aquellas coincidencias y sustituimos aquellas que coincida con la busqueda mediante un strong
                    place_name = direccion.place_name.split(regex).map(text => text.match(regex) ? <strong>{text}</strong> : text)
                } else
                    place_name = direccion.place_name

                return {
                    object: direccion,
                    value: direccion.place_name,
                    label: <div key={index} value={index}>{place_name}</div>
                }
            })
        } 

        return []
    }



    onSelect = (text, item ) => this.props.onChange(item.object)

    render() {

        const { busquedaDireccion, renderDirecciones, props, onSelect } = this
        const { placeholder, className, inputClassName, } = props
        
        return <AutoComplete
            value={this.state.search_text}
            onChange={e => this.setState({ search_text:e })}
            options={renderDirecciones()}
            onSelect={onSelect}
            onSearch={busquedaDireccion}
            className={className}
            enterButton={null}>
            <Input
                size="large"
                placeholder={placeholder}
                className={inputClassName}
            />
        </AutoComplete>
    }
}

Busqueda.propTypes = {
    onSelect: PropTypes.func,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string,
    inputClassName: PropTypes.object,
    size: PropTypes.string,
}

Busqueda.defaultProps = {
    placeholder: null,
    size: "large",
}