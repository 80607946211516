import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, message, Tag, Space, Menu, Tabs } from 'antd';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import { RightOutlined, FilePdfOutlined, MailOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";

//componentes
import { renderEstatusInversion } from '../../Utils'
import ListaTransacciones from './Secciones/ListaTransacciones'
import ListaReventas from './Secciones/ListaReventas'
import ModalReventa from '../Reventa/ModalReventa'


const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')

/**
 * @export
 * @class InversionDetalle
 * @extends {React.Component}
 */
class InversionDetalle extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			inversion_id: this.props.params.inversion_id,
			inversion: {},
			transacciones: {
				page: 1,
				total: 0,
				limit: 10,
				data: []
			},



			inversion_id: undefined,

			modalEstadoCuenta: false,
			modalReventaVisible: false,
			modalHacienda: false,
			cantidades: {
				pendientes: 0,
				vendidas: 0,
				total: 0,
				revendidas: 0,
				disponibles: 0,
			}
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getInversion();
		this.getTransacciones();
		this.getCantidades();
	}


	getCantidades = () => {

		axios.get('/inversion/cantidades', {
			params: {
				inversion_id: this.props.params.inversion_id
			}
		})
			.then(({ data }) => {
				console.log(data)
				data.data.disponibles = Number(data.data.total - data.data.revendidas)
				this.setState({ cantidades: data.data })
			})
			.catch(err => {

				message.error('No se pudieron cargar las cantidades.')
			})
			.finally()

	}


	/**
	* @method getInversiones
	* @description Obtiene la informacion de una inversion
	*/
	getInversion = () => {

		this.setState({ loading: true })

		axios.get('/customer/inversion', {
			params: {
				id: this.props.params.inversion_id,
			}
		}).then(response => {
			console.log("response", response.data);
			this.setState({ inversion: response.data.data })
		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading: false }))
	}

	/**
	* @method getTransaccion
	* @description Obtiene la lista de transacciones relacionadas a un inversion
	*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
	} = this.state.transacciones) => {

		this.setState({ loading_transaccion: true })

		axios.get('/customer/transaccion', {
			params: {
				page,
				limit,
				inversion_id: this.props.params.inversion_id,
			}
		}).then(response => {
			console.log("response", response.data);
			this.setState({
				transacciones: {
					...this.state.transacciones,
					data: response.data.data.data,
					page: response.data.data.page,
					total: response.data.data.total,
				}
			})

		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading_transaccion: false }))
	}

	/**
	 *
	 * @memberof Transacciones
	 * 
	 * @method getPDF
	 * @description Generamos el PDF del comprobante de pago
	 */
	getPDF = (transaccion_id) => {
		let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante/pdf');
		urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
		urlPDF.searchParams.append('transaccion_id', transaccion_id)
		window.open(urlPDF.href, '_blank');
	}

	render() {
		const { inversion } = this.state

		return (
			<Content className="hm-layout-content layout-dashboard" >
				<PageHeader className="site-page-header" ghost={false} title="Detalle de inversion" />
				<Card className="card-zeus">
					<Row gutter={[8]}>
						<Col xs={24} xl={12} className="flex-left">
						</Col>
						<Col xs={24} xl={12} className="flex-right">
							{renderEstatusInversion({ estatus: inversion.estatus })}
						</Col>
						<Col xs={24} md={12} className="flex-left-column">
							<Text className="text-label">Hacienda</Text>
							<Text ellipsis className="text-info">{inversion.hacienda_id?.nombre}</Text>
						</Col>
						<Col xs={24} md={12} className="flex-left-column">
							<Text className="text-label">Plantas Compradas</Text>
							<Text ellipsis className="text-info">{inversion.cantidad}</Text>
						</Col>
						<Col xs={12} md={8} xl={6} className="flex-left-column">
							<Text className="text-label">Total de la Inversión</Text>
							<Text ellipsis className="text-info">$ {inversion.monto?.toMoney(true)} {inversion.moneda}</Text>
						</Col>
						<Col xs={12} md={8} xl={6} className="flex-left-column">
							<Text className="text-label">Total Pagado</Text>
							<Text ellipsis className="text-green">$ {inversion.monto_pagado?.toMoney(true)} {inversion.moneda}</Text>
						</Col>
						<Col xs={12} md={8} xl={6} className="flex-left-column">
							<Text className="text-label">Cantidad de Plantas</Text>
							{inversion.cantidad_revendidas > 0 ? (
								<Text className="text-info">
									{this.state.cantidades.total || 0} plantas en total. <br />
									{this.state.cantidades.revendidas || 0} para reventa. <br />
									{this.state.cantidades.vendidas || 0} plantas vendidas. <br />
									{this.state.cantidades.pendientes || 0} plantas pendientes <br />
									{this.state.cantidades.disponibles || 0} plantas disponibles <br />
								</Text>
							) : (
								<Text ellipsis className="text-info">{inversion.cantidad}</Text>
							)}
						</Col>
						<Col flex="auto" className="flex-left-column">
							<Text className="text-label">Monto Pendiente</Text>
							<Text ellipsis className="text-red">$ {inversion.monto_pendiente?.toMoney(true)} {inversion.moneda}</Text>
						</Col>
					</Row>
				</Card>

				<Row className="mt-1" style={{ width: "100%", justifyContent: "space-between" }}>
					<Menu mode="horizontal" defaultSelectedKeys={['transacciones']} style={{ minWidth: 340, background: 'none' }}>
						<Menu.Item key="transacciones" onClick={() => this.setState({ activeKey: "transacciones" })}>
							Transacciones
						</Menu.Item>
						<Menu.Item key="plantas-en-venta" onClick={() => this.setState({ activeKey: "plantas-en-venta" })}>
							Plantas en Venta
						</Menu.Item>
					</Menu>

					<Button
						size="large"
						type="primary"
						disabled={inversion.estatus != 2}
						onClick={() => this.setState({ modalReventaVisible: true })}
						icon={<ShoppingCartOutlined />}
					>Vender Plantas</Button>
				</Row>

				<Tabs
					destroyInactiveTabPane
					activeKey={this.state.activeKey}
					renderTabBar={() => null}					>
					<Tabs.TabPane tab="Transacciones" key="transacciones">
						<ListaTransacciones
							inversion_id={this.state.inversion_id}
						/>
					</Tabs.TabPane>
					<Tabs.TabPane tab="Plantas en Venta" key="plantas-en-venta">
						<ListaReventas
							update={this.state.update}
							inversion_id={this.state.inversion_id}
						/>
					</Tabs.TabPane>
				</Tabs>

				<ModalReventa
					visible={this.state.modalReventaVisible}
					onClose={() => {
						this.setState({ modalReventaVisible: false, update: !this.state.update })
						this.getInversion()
					}}
					inversion={this.props.params.inversion_id}
				/>

				{/* <Row gutter={[18, 18]} style={{ marginTop: '2rem', marginLeft: '1rem' }} justify="space-between" align="middle">
					<Space style={{ width: "100%", justifyContent: "space-between" }}>
						<Title className="zeus-title" level={3}>Mis Transacciones</Title>
						<Button type="primary" size="large">VENDER PLANTAS</Button>
					</Space>
				</Row> */}
			</Content>
		)
	}
}


export default function (props) {

	const params = useParams()
	const navigate = useNavigate()

	return <InversionDetalle  {...props} params={params} navigate={navigate} />
}