import React, { Component, useContext } from "react";
import { Row, Col, Typography, Form, Input } from 'antd';
import { User, SetUser } from '../../../Hooks/Logged';
import axios from "axios";

import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import FormatedInput from "../../Widgets/Inputs/FormatedInput";
const { Title, Text } = Typography;

/**
 * @class Credenciales
 * @description Modal que contiene los steps para buscar un credito
 */
const Credenciales = React.forwardRef((props, ref) => {

    let [form] = Form.useForm()
    const setUser = useContext(SetUser)
    let user = useContext(User)

    const submit = () => {
        form.submit()
    }

    const onFinish = (values) => {
        axios.post('/register', {
            status: 2,
            ...values
        })
        .then(({data, headers}) => {
            axios.defaults.headers.post['Authorization'] = headers.authorization
            sessionStorage.setItem('token', headers.authorization);
            axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
            
            setUser(data.data);
        })
        .catch(e => console.log("e",e))
        props.next()
    }

    /**
     *
     *
     * @param {object} _ Valores no necesarios
     * @param {string} email Email a revisar
     * @return {promise} Promesa con peticion 
     * @method checkEmail
     * @description Valida si el email ya esta asigna a un usuario
     */
    const checkEmail = (_, email) => {
        if(!(/[a-z0-9\._]+@[a-z0-9\._]+\.[a-z0-9]+/.test(email))) return Promise.reject(new Error("Formato incorrecto"))

        return new Promise((resolve, reject) => {
            axios.post('/verify-email', { email })
                .then(({data}) => {
                    const flag = data.success
                    
                    if(flag) reject( new Error("No es un email valido") )

                    resolve()
                })
                .catch(error => {
                    
                    reject( new Error("Error al verificar email") )
                }) 
        })
    }

    React.useImperativeHandle(ref, () => ({ submit }));

    return (
        <>
            <Form
                layout={"vertical"}
                form={form}
                onFinish={onFinish}
                style={{ minHeight: '480px' }}
                initialValues={{ ...props.data }}
            >
                <Row justify="center">
                    <Col span={24} className="flex-column mb-3 mt-3">
                        <Title level={3} className="modal-title">Crea tu cuenta</Title>
                        <Text className="modal-subtitle">Ingresa tu correo electrónico y tu contraseña para empezar</Text>
                    </Col>
                    <Col span={18}>
                        
                        <Form.Item
                            name="email"
                            label="E-mail"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'No es un email valido',
                                },
                                {
                                    required: true,
                                    message: 'Ingresa un email',
                                },
                                {
                                    pattern: /[^A-Z].+/,
                                    message: 'El email es sin mayusculas'
                                },
                                ({ getFieldValue }) => ({
                                    validator: checkEmail
                                })
                            ]}
                        >
                            <FormatedInput trim maxLength={60} />
                        </Form.Item>
                        <Form.Item
                            name="telefono"
                            label="Teléfono"
                            className=" width-100 "
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa tu teléfono',
                                },
                            ]}
                        >
                            <PhoneInput />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Contraseña"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa tu  contraseña'
                                },
                                {
                                    min: 8,
                                    message: 'La contraseña debe de tener mínimo 8 caracteres'
                                },
                                {
                                    pattern: new RegExp("^(?=.*[0-9])(?=.*[a-z]|[A-Z]).+$"),
                                    message: 'Debe tener números y letras'
                                },
                                {
                                    pattern: new RegExp("^(?=.*[~`!@#$%^&*()\-_+={}[\]\|\/:;\"'<>,.?¿¡]).+$"),
                                    message: 'Debe de tener un caracter especial'
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password maxLength={60} />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Confirmar Contraseña"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingresa la misma contraseña',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('Las contraseñas no coinciden'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password maxLength={60} />
                        </Form.Item>
                        <div className="text-center text-purple hover" onClick={() => props.openRecovery()}>¿Olvidaste tu contraseña?</div>
                        <div className="text-center text-purple hover" onClick={() => props.changeView()}>¿Ya tienes cuenta? ¡Inicia sesión!</div>
                    </Col>
                </Row>
            </Form>
        </>
    )

})

export default Credenciales

