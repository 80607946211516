import React, { Component } from "react";
import { Row, Col, Form, InputNumber, Input, Select, Typography, Button, Spin, message, Modal, Result } from 'antd';
import axios from 'axios'
import { CloseCircleFilled } from "@ant-design/icons";

// import  from "antd/lib/skeleton/Title";

const { Option } = Select;
const { Text, Title, Paragraph } = Typography;

/**
 *
 *
 * @export
 * @class ModalFicha
 * @extends {Component}
 */
export default class ModalFicha extends Component {

    constructor(props) {
        super(props);
        this.state = {
            card_number: '',
            loading: false,
        }
    }


    componentDidMount() {
        // console.log(this.props.ficha)
    }

    formRef = React.createRef();


    /**
     * @memberof ModalFicha
     * 
     * @method onChangeCard
     * @description Generamos el PDF de la referencia de pago
     */
    onChangeCard = (value) => {
        const card_number = this.formatNumber(value.target.value)
        this.setState({ card_number: value.target.value.replaceAll(' ', '').replaceAll('-', '').slice(0, 16) })
        this.formRef.current.setFieldsValue({
            card_number: card_number
        })
    }


    /**
     * @memberof ModalFicha
     * 
     * @method formatNumber
     * @description Da formato al numero de la tarjeta
     */
    formatNumber = (value) => {
        if (!value) return value

        const phoneNumber = value.toString().replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength <= 4) return phoneNumber;

        if (phoneNumberLength <= 8) {
            return `${phoneNumber.slice(0, 4)} - ${phoneNumber.slice(4)}`
        }

        if (phoneNumberLength <= 12) {
            return `${phoneNumber.slice(0, 4)} - ${phoneNumber.slice(4, 8)} - ${phoneNumber.slice(8, 12)}`
        }

        return `${phoneNumber.slice(0, 4)} - ${phoneNumber.slice(4, 8)} - ${phoneNumber.slice(8, 12)} - ${phoneNumber.slice(12, 16)}`

    }


    /**
     * @memberof ModalFicha
     * 
     * @method onFinish
     * @description Se ejecuta al hacer submit en el formulario
     */
    onFinish = (values) => {
        this.setState({ loading: true })
        axios.post('/authorizenet/pago', {
            ...values,
            card_number: this.state.card_number,
            inversion_id: this.props.ficha?.inversion?._id,
        })
            .then(({ data }) => {
                Modal.success({
                    width: 500,
                    content: <Result
                        icon={<img src="/img/agave-card.svg" width={250} />}
                        title="¡Muchas gracias por su inversión!"
                        subTitle={<>
                            Hemos recibido la inversión con folio <strong>{data?.inversion?.folio}</strong>. Le haremos llegar la información de su inversión a su correo electrónico.
                            <Title level={5}>¡Muchas gracias por confiar en nosotros!</Title>

                            <Title level={5} ><small>Cualquier duda o sugerencia, estamos a tu servicio en: </small></Title>

                            <a href="mailto:soporte@zeusagave.com">soporte@zeusagave.com</a><br/>
                            <a href="tel:+523476881291">+52 347 688 12 91</a>
                            <p>
                                Zeus Oro Azul de los Altos SPR de RL de CV<br />
                                <a href="https://www.google.com/maps/place/Revoluci%C3%B3n+Mexicana+75,+La+Tinajita,+47147+San+Miguel+el+Alto,+Jal.,+Mexico/@21.032753,-102.412697,17z/data=!4m5!3m4!1s0x84296a965956b47b:0xe580034086b9b9e!8m2!3d21.0325129!4d-102.4127503?hl=en&gl=US">
                                    Revolución Mexicana 75, San Miguel El Alto Centro 47140, San Miguel el Alto, Jalisco Mexico
                                </a>
                            </p>
                        </>}
                    />

                })
                if(!data?.enviado){
                    Modal.error({
                        width: 500,
                        content: <Result
                            icon={<CloseCircleFilled style={{ color: "#ff4d4f" }} />}
                            title="Hubo un error al enviar su contrato."
                            subTitle={<>
                                Por favor de contactar con nuestro servicio de soporte para poder hacerle el envio del contrato para la inversion con el folio <strong>{5198498}</strong>.
            
                                <Title level={5} ><small>Datos de contacto del soporte: </small></Title>
            
                                <a href="mailto:soporte@zeusagave.com">soporte@zeusagave.com</a><br/>
                                <a href="tel:+523476881291">+52 347 688 12 91</a>
                            </>}
                        />
            
                    })
                }
                this.props.onClose()
            }).catch(error => {
                // console.log("error", error);
                if (error.response?.status === 403)
                    Modal.error({
                        title: error.response?.data?.message,
                        content: error.response?.data?.descripcion
                    })
                else
                    message.error('Error al realizar le pago')
            }).finally(() => this.setState({ loading: false }))
    }



    render() {

        return (
            <Spin
                spinning={this.state.loading}
            >
                <Typography>
                    <Title level={5}>!Realiza el pago de tu inversión aqui directamente!</Title>
                    <Paragraph>Puedes invertir con nosotros aquí directamente</Paragraph>
                </Typography>
                <Form
                    layout="vertical"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                name="first_name"
                                label="Nombre(s) del Titular"
                                rules={[
                                    { required: true, message: "Ingrese el nombre" }
                                ]}
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="last_name"
                                label="Apellido(s) del Titular"
                                rules={[
                                    { required: true, message: "Ingrese el apellido" }
                                ]}
                            >
                                <Input placeholder="Apellido" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="card_number"
                                label="Tarjeta de credito"
                                rules={[
                                    { required: true, message: "Ingrese el número de la tarjeta" }
                                ]}
                            >
                                <Input
                                    placeholder="Número tarjeta"
                                    onChange={(value) => { this.onChangeCard(value) }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row align="center">
                                <Col span={11}>
                                    <Form.Item
                                        name="expiration_date_month"
                                        label="Mes"
                                        rules={[
                                            { required: true, message: "Ingrese el número de la tarjeta" }
                                        ]}
                                    >
                                        <Select
                                            placeholder="mm"
                                        >
                                            <Option value={'01'}>01</Option>
                                            <Option value={'02'}>02</Option>
                                            <Option value={'03'}>03</Option>
                                            <Option value={'04'}>04</Option>
                                            <Option value={'05'}>05</Option>
                                            <Option value={'06'}>06</Option>
                                            <Option value={'07'}>07</Option>
                                            <Option value={'08'}>08</Option>
                                            <Option value={'09'}>09</Option>
                                            <Option value={'10'}>10</Option>
                                            <Option value={'11'}>11</Option>
                                            <Option value={'12'}>12</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={2} className="center">
                                    <Text>/</Text>
                                </Col>
                                <Col span={11}>
                                    <Form.Item
                                        name="expiration_date_year"
                                        label="Año"
                                        rules={[
                                            { required: true, message: "Ingrese el número de la tarjeta" }
                                        ]}
                                    >
                                        <Select
                                            placeholder="yyyy"
                                        >
                                            <Option value={'23'}>2023</Option>
                                            <Option value={'24'}>2024</Option>
                                            <Option value={'25'}>2025</Option>
                                            <Option value={'26'}>2026</Option>
                                            <Option value={'27'}>2027</Option>
                                            <Option value={'28'}>2028</Option>
                                            <Option value={'29'}>2029</Option>
                                            <Option value={'30'}>2030</Option>
                                            <Option value={'31'}>2031</Option>
                                            <Option value={'32'}>2032</Option>
                                            <Option value={'33'}>2033</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="card_code"
                                label="Código de seguridad"
                                rules={[
                                    { required: true, message: "Ingrese el número de la tarjeta" }
                                ]}
                            >
                                <Input.Password
                                    controls={false}
                                    placeholder="CVV / CVC"
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="btn-submit large"
                                >
                                    Realizar Pago
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



