import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';

import Productos from '../../Components/Admin/Productos/Productos';
import Variantes from '../../Components/Admin/Productos/Variantes/Variantes';
import Inventarios from '../../Components/Admin/Productos/Inventarios/Inventarios'
import Reportes from '../../Components/Admin/Productos/Reportes/Reportes';
import Movimientos from '../../Components/Admin/Productos/Movimientos/Movimientos';
/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterInventarios(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Productos  {...props}  />} />
            <Route path="reportes" element={<Reportes  {...props}  />} />
            <Route path="movimientos" element={<Movimientos  {...props}  />} />
            <Route path=":producto_id/variantes" element={<Variantes   {...props}  />} />
            <Route path=":producto_id/variantes/:variante_id/inventario" element={<Inventarios   {...props}  />} />
    
        </Routes>
    )
}

export default RouterInventarios;