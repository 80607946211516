import React, { Component } from 'react';
import { Button, Spin, PageHeader, Layout, message, Space, Row, Col, Typography, List, Card, Tag } from 'antd'
import axios from 'axios';
import { IconEye } from '../../Widgets/Iconos'

//compoanentes
import { IconArrowBack } from '../../Widgets/Iconos'
//modales
import ModalTransaccionDetalle from '../Transacciones/ModalTransaccionDetalle'

//css
import '../../../Styles/Modules/Admin/empleados.css'
import { useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Text,Title } = Typography
const moment = require('moment')

/**
 * @export
 * @class Haciendas
 * @extends {Component}
 * @description Vista de Haciendas
 */
class EmpleadoDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            drawerHacienda: false,
            empleado: {
                salario: 0
            },
            transacciones: [],

            page: 1,
            total: 0,
            limit: 10,
            search: '',
        }
    }

    /**
     * 
     * @method componentDidMount
     * @description Obtenemos las empleados en cuestión
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEmpleado();
        this.getTransacciones()
    }


     /**
     * @memberOf ModalEmpleado
    * @method getEmpleado
    * @description Obtiene la informaciond el empleado
    */
    getEmpleado = () => {
        this.setState({ loading: true })
        axios.get('/empleado', {
            params: {
                empleado_id: this.props.params.empleado_id,
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                empleado: response.data
            })
            
        }).catch(error => {
            console.log("error", error);
            
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method getTransacciones
    * @param {number} page - Numero de la pagina listada
    * @description Obtiene las transacciones realizadas
    */
    getTransacciones = (page = this.state.page) => {
        
        this.setState({ loading: true })
        axios.get('/transacciones', {
            params: {
                page: page,
                empleado_id: this.props.params.empleado_id
            }
        }).then(response => {
            
            this.setState({
                transacciones: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
            })

        }).catch(error => {
            

        }).finally(() => this.setState({ loading: false }))
    }

    renderModalidad = (modalidad) => {
        switch (modalidad) {
            case '1':
                return 'Salario'
            case '2':
                return 'Destajo'
            default:
                return 'Por hora'
        }
    }



    render() {

        const { empleado } = this.state

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Detalle Empleado"
                        backIcon= {(this.props.params.empleado_id !== undefined) ? < IconArrowBack />:null}
                        onBack={(this.props.params.empleado_id !== undefined) ? this.back:null}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col span={24}>
                                <Card className="card-empleado">
                                    <Row>
                                        <Col span={24} className="mb-1">
                                            <Title level={3}>Información del Empleado</Title>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Nombre</Text>
                                            <Text className="label-text" level={4}>{empleado.nombre} {empleado.apellido ? empleado.apellido : ''}</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Email</Text>
                                            <Text className="label-text" level={4}>{empleado.usuario_id?.email ? empleado.usuario_id?.email : '-' }</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Seguro Social</Text>
                                            <Text className="label-text" level={4}>{empleado.nss}</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Registro Federal de Contribuyentes</Text>
                                            <Text className="label-text" level={4}>{empleado.rfc}</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Salario</Text>
                                            <Text className="label-text" level={4}>$ {empleado.salario?.toMoney(true)} MXN</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Modaldiad Pago</Text>
                                            <Text className="label-text" level={4}>{this.renderModalidad(empleado.modalidad_pago)}</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Frecuencia Pago</Text>
                                            <Text className="label-text" level={4}>{empleado.tipo_pago === 1 ? 'Semanal' : 'Quincenal'}</Text>
                                        </Col>
                                        <Col xs={24} md={12} lg={6} className="flex-left-column">
                                            <Text className="label-title" level={4}>Estatus</Text>
                                            <Text className="label-text" level={4}>{empleado.estatus === 0 ? 'Baja' : empleado.estatus === 1 ? 'Alta' : 'Vacaciones' }</Text>
                                        </Col>

                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Title level={3} className="detalles-section-title">Transacciones</Title>
                        <List
                    loading={this.state.loading}
                    className="component-list pd-1"
                    itemLayout="horizontal"
                    dataSource={this.state.transacciones}
                    locale={{ emptyText: "Sin Transacciones" }}
                    pagination={{
                        current: this.state.page,
                        pageSize: 10,
                        total: this.state.total,
                        position: 'bottom',
                        className: "flex-left ml-1",
                        onChange: (page) => this.getTransacciones(page)
                    }}
                    header={<Row className=" width-100 pl-1 pr-1 ">
                        <Col span={3} className="center">
                            Fecha
                        </Col>
                        <Col span={7} className="center">
                            Concepto
                        </Col>
                        <Col span={3} className="center">
                            Estatus
                        </Col>
                        <Col span={3} className="center">
                            Tipo
                        </Col>
                        <Col span={5} className="center">
                            Monto
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                    }
                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
                                <Row className="width-100 " gutter={[0, 12]}>
                                    <Col className="center" span={3}>
                                        {moment(item.createdAt).format('DD-MM-YYYY')}
                                    </Col>
                                    <Col className="center" span={7}>
                                        {item.concepto}
                                    </Col>
                                    <Col className="center" span={3}>
                                        <Tag className={`admin-tag estatus-${item.estatus}`}  >{item.estatus === 1 ? 'En Proceso' : 'Confirmada'}</Tag>
                                    </Col>
                                    <Col className="center" span={3}>
                                        <Tag className={`admin-tag tipo-${item.tipo}`}  >{item.tipo === 1 ? 'Ingreso' : 'Egreso'}</Tag>
                                    </Col>
                                    <Col className="center" span={5}>
                                        $ {(typeof item.monto === "number") ? item.monto.toMoney(true) : 0.00} MXN
                                    </Col>
                                    <Col className="center" span={3}>
                                        <Space direction="horizontal">
                                            <Button
                                                type="primary"
                                                className='btn-primary'
                                                icon={<IconEye />}
                                                onClick={() => this.setState({ modalTransaccionDetalle: true, transaccion_id: item._id })}
                                            />

                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />

                    </Content>
                    
                </Spin>


                <ModalTransaccionDetalle
                    visible={this.state.modalTransaccionDetalle}
                    onCancel={() => {
                        this.setState({ modalTransaccionDetalle: false, transaccion: undefined })
                        this.getTransacciones(this.state.page)
                    }}
                    transaccion={this.state.transaccion_id}
                />
            </>
        )
    }
}


export default function (props){

    const params = useParams()
    const navigate = useNavigate()

    return <EmpleadoDetalles  {...props} params={params} navigate={navigate}/>
}