import React, { Component } from 'react';
import { Button, Spin, PageHeader, Tag, Layout, message, Space, Row, Col, Typography, List, Card, Tooltip, Modal } from 'antd'

import axios from 'axios';
import { IconArrowBack, } from '../../Widgets/Iconos'
import { Link, useLocation, useNavigate, useSearchParams, } from 'react-router-dom';
import { AiOutlineFileExcel } from 'react-icons/ai'


//componentes

//modales
import moment from 'moment';
import { DeleteOutlined, DollarOutlined, UserOutlined } from '@ant-design/icons';
import DrawerNominas from './DrawerNominas';

// FloatingButton
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import ModalNomina from './ModalNomina';
import { method } from 'lodash';


const { Content } = Layout;
const { Text } = Typography

/**
 * @export
 * @class ListadoNominas
 * @extends {Component}
 * @description Vista de ListadoNominas
 */
class ListadoNominas extends Component {

    back = () => {
        this.props.navigate(-1)
    }

    constructor(props) {
        super(props)
        let { page, limit } = this.props.params
        this.state = {

            loading: false,
            drawer_visible: false,

            modalNomina: false,
            nominas: {
                data: [],
                page: page ?? 1,
                limit: limit ?? 20,
                pages: 0,
                total: 0,
                search: null,
                loading: false,
                id: undefined
            }
        }
    }

    /**
     * 
     * @method componentDidMount
     * @description Obtenemos las nominas en cuestión
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getNominas();
    }

    /**
     *
     *
     * @memberof ListadoNominas
     * @method getNominas
     * @description Obtenemos el listado de nominas paginado
     */
    getNominas = ({

        page = this.state.nominas.page,
        limit = this.state.nominas.limit,
        search = this.state.nominas.search,
    } = this.state.nominas) => {
        console.log(page)


        let urlParams = { page, limit }
        if (search)
            urlParams["search"] = search
        this.props.navigate(`${this.props.location.pathname}?${(new URLSearchParams(urlParams)).toString()}`)


        this.setState({
            nominas: {
                ...this.state.nominas,
                page, limit, search
            }
        })

        axios.get('/nominas', {
            params: {
                page,
                limit,
                search
            }
        }).then(response => {
            // console.log("response", response.data);
            this.setState({
                nominas: {
                    ...this.state.nominas,
                    ...response.data
                }
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener las nominas')
        })

    }

    /**
     *
     *
     * @memberof ModalEstadoCuenta
     * 
     * @method getPDF
     * @description Generamos el PDF del Estado de Cuenta
     */
    getPDF = (id) => {
        let urlPDF = new URL(axios.defaults.baseURL + '/nominas/csv/' + id);
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('nomina_id', id)
        return urlPDF.href
    }

    /**
     *
     *
     * @param {*} nomina_id
     * @memberof ListadoNominas
     */
    deleteNomina = (nomina_id) => {
        axios.delete('/nominas', {
            params: {
                nomina_id
            }
        }).then(response => {
            this.getNominas()

        }).catch(error => {
            message.error('Error al eliminar la nómina')
        })
    }

    /**
     *
     *
     * @param {*} status
     * @memberof ListadoNominas
     * @description Renderiza el estatus de la nómina actual 
     */
    renderEstatus = (status) => {
        switch (status) {
            case 0:
                return <Tag color="#9290FE">En Proceso</Tag>
            case 1:
                return <Tag color="#D9CB50">En Proceso</Tag>
            case 2:
                return <Tag color="#FFB572">Autorizada</Tag>
            case 3:
                return <Tag color="#54BAB9">Concluida</Tag>
        }
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Nóminas"
                        onBack={this.back} backIcon={<IconArrowBack />}
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            style={{ maxWidth: "100%" }}
                            loading={this.state.nominas.loading}
                            className="component-list component-list-group"
                            size="large"
                            itemLayout="horizontal"
                            dataSource={this.state.nominas.data}
                            header={
                                <Row className="width-100" >
                                    <Col span={4} className="center">
                                        <Text strong>Fecha</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Modalidad</Text>
                                    </Col>
                                    <Col span={4} className="center">
                                        <Text strong>Empleados a Pagar</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Monto Pagado</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Monto Pendiente</Text>
                                    </Col>
                                    <Col span={3} className="center">
                                        <Text strong>Estatus</Text>
                                    </Col>
                                </Row>
                            }
                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100">
                                            <Col span={4}>
                                                Del <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>  al <Text strong>{moment(item.fecha_fin).format('DD/MM/YYYY')}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text ellipsis strong>
                                                    {(moment(item.fecha_fin).diff(moment(item.fecha), 'week') <= 1) ? "Semanal" : "Quincena"}
                                                </Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Text ellipsis strong>{item.cantidad_empleados}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text ellipsis strong>{item.monto_total?.toMoney(true)}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text ellipsis strong>{item.monto_pagado?.toMoney(true)}</Text>
                                            </Col>
                                            <Col span={3} className="center">
                                                <Text strong>{this.renderEstatus(item.estatus)}</Text>
                                            </Col>
                                            <Col className="center">
                                                <Space>
                                                    <Tooltip title="Descargar PDF">
                                                        <a href={this.getPDF(item._id)} target="_blank">
                                                            <Button
                                                                icon={<AiOutlineFileExcel style={{ position: "relative", top: "3" }} />}
                                                                type="primary"
                                                                className='btn-primary'
                                                                title={item.estatus < 2 ? "Es necesario que esté autorizado para continuar" : "Ver Detalle"}
                                                            />
                                                        </a>


                                                    </Tooltip>
                                                    <Tooltip title="Empleados a Pagar">
                                                        <Link to={"/admin/nominas/nomina/empleados/" + item._id}>
                                                            <Button
                                                                icon={<UserOutlined />}
                                                                type="primary"
                                                                className='btn-primary'
                                                                title={item.estatus < 2 ? "Es necesario que esté autorizado para continuar" : "Ver Detalle"}
                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip title="Pagos de la Nómina">
                                                        <Link to={"/admin/nominas/nomina/finanzas/" + item._id}>
                                                            <Button
                                                                icon={<DollarOutlined />}
                                                                type="primary"
                                                                className='btn-primary'
                                                                disabled={item.estatus < 2}

                                                                title={item.estatus < 2 ? "Es necesario que esté autorizado para continuar" : "Ver Detalle"}

                                                            />
                                                        </Link>
                                                    </Tooltip>
                                                    <Tooltip title="Pagos de la Nómina">
                                                        <Button
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            type="primary"
                                                            onClick={() => Modal.confirm({
                                                                title: "¿Deseas eliminar la nómina?",
                                                                content: "Los pagos de empleados y la nómina serán eliminados",
                                                                onOk: () => this.deleteNomina(item._id)
                                                            })}
                                                            title="Ver Detalle"
                                                        />
                                                    </Tooltip>

                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                            pagination={{
                                onChange: (page, limit) => this.getNominas({
                                    page, limit
                                }),
                                current: this.state.nominas.page,
                                pageSize: this.state.nominas.limit,
                                total: this.state.nominas.total,
                            }}
                        />
                    </Content>
                </Spin>
                <FloatingButton
                    onClick={() => this.setState({ modalNomina: true })}
                    title="Generar reporte diario" />

                <ModalNomina
                    visible={this.state.modalNomina}
                    onClose={() => {
                        this.setState({ modalNomina: false })
                        this.getNominas()
                    }}
                />
                <DrawerNominas
                    visible={this.state.drawer_visible}
                    onClose={() => {
                        this.setState({ drawer_visible: false })
                    }}
                    //ref={this.DrawerRef}
                    filterInfo={() => {
                        //* Codigo al filtrar aqui
                    }}
                />
            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();
    const location = useLocation()

    return <ListadoNominas {...props} navigate={navigate} params={params} location={location} />
}