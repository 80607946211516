import React, { useState, createRef } from 'react';
import { Drawer, Button, Space } from 'antd';
import TableReporte from "./TablaReporte";



/**
 *
 *
 * @export
 * @param {*} props
 * @returns Drawer Component
 */
export default function (props) {
    
    const { onClose, visible } = props;
    const [reporte, setReporte] = useState("");
    let refTableReporteProductos = createRef()

    const saveValues = async () => {
        await refTableReporteProductos.current.formTableReporteProductos.current.submit()
    }

    
    return <Drawer
        title={"Editar Reporte"}
        placement={"bottom"}
        height="80vh"
        onClose={onClose}
        visible={visible}
        destroyOnClose={true}
    >
        <TableReporte
            ref={refTableReporteProductos}
            {...props}
            reporte={reporte}
            setReporte={setReporte}
            />
    </Drawer>
}