import React, { Component } from 'react';
import { Button, Spin, PageHeader, Layout, message, Space, Row, Col, Typography, List, Card, Popconfirm, Checkbox } from 'antd'
import axios from 'axios';
import { IconArrowBack, IconBtnDelete, IconBtnEdit, IconCashier } from '../../Widgets/Iconos'

import ModalEmpleadoNomina from './ModalEmpleadoNomina'
import moment from 'moment';
import { CardEmpleadoFinanzas, CardStatistics } from '../../Widgets/Cards';
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton';
import { useNavigate, useParams } from 'react-router-dom';

const { Content } = Layout;
const { Text, Title } = Typography

/**
 * @export
 * @class EmpleadosNomina
 * @extends {Component}
 * @description Vista de EmpleadosNomina
 */
class EmpleadosFinanzas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,
            nomina: {
                data: {},
                loading: false
            },
            empleados: {
                data: [],
                page: 1,
                limit: 20,
                pages: 0,
                total: 0,
                search: null,
                loading: false,
                id: undefined
            },
            seleccionados: [],
            nomina_id: this.props.params.nomina_id,
        }
    }

    /**
     * 
     * @method componentDidMount
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getNomina()
        this.getEmpleadosNominas()
    }

    /**
     *
     *
     * @memberof EmpleadosNomina
     * @method getNomina
     * @description Obtiene los datos de la nomina
     */
    getNomina = () => {
        this.setState(state => {
            state.nomina.loading = true
            return state
        })

        axios.get(`/nominas/${this.state.nomina_id}`, {
            params: {
                id: this.state.nomina_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState(state => {
                state.nomina.data = response.data
                return state
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la nómina')
        }).finally(() => {
            this.setState(state => {
                state.nomina.loading = false
                return state
            })
        })
    }

    /**
     *
     *
     * @memberof EmpleadosNomina
     * @method getEmpleadosNominas
     * @description Obtiene los datos de las nominas_empleados de la nomina
     */
    getEmpleadosNominas = ({
        page = this.state.empleados.page,
        limit = this.state.empleados.limit,
        search = this.state.empleados.search
    } = this.state.empleados) => {
        this.setState({ empleados: { ...this.state.empleados, page, limit, search, loading: true } }, () => {
            axios.get('/empleados-nominas', {
                params: {
                    page, limit, search, nomina_id: this.state.nomina_id,
                    pendientes: true
                }
            }).then(response => {
                console.log("response", response.data);
                this.setState(state => {
                    state.empleados.data = response.data.data
                    state.empleados.pages = response.data.pages
                    state.empleados.total = response.data.total
                    return state
                })

            }).catch(error => {
                console.log("error", error);
                message.error('Error al obtener la información')
            }).finally(() => {
                this.setState(state => {
                    state.empleados.loading = false
                    return state
                })
            })
        })
    }

    /**
     *
     *
     * @memberof EmpleadosNomina
     * @method pagarNominas
     * @description Pagar las nominas_empleados seleccionados
     */
    pagarNominas = () => {
        if (this.state.empleados.loading) return

        this.setState(state => {
            state.empleados.loading = true
            return state
        }, () => {
            const empleados_nominas = this.state.seleccionados.map(_id => {
                const empleado_nomina = this.state.empleados.data.find(item => item._id === _id)

                return ({
                    _id: empleado_nomina._id,
                    salario: empleado_nomina.salario,
                    monto_autorizado: empleado_nomina.monto_autorizado
                })
            })

            axios.post('/empleado-nomina/pagar', {
                empleados_nominas
            }).then(response => {
                message.success("Empleados pagados")
            }).catch(error => {
                console.log("error", error);
                message.error('Error al pagar empleados')
            }).finally(() => {
                this.getEmpleadosNominas()
                this.setState(state => {
                    state.empleados.loading = false
                    return state
                })
            })
        })
    }

    /**
     *
     *
     * @memberof EmpleadosNomina
     * @method finalizarNomina
     * @description Actualiza el estatus de la nomina
     */
    finalizarNomina = () => {
        if (this.state.empleados.loading) return

        this.setState(state => {
            state.empleados.loading = true
            return state
        }, () => {
            axios.put('/nomina', {
                id: this.state.nomina_id,
                estatus: 3
            }).then(response => {
                message.success("Nómina Finalizada")
                this.props.navigate('/admin/nominas/lista')
            }).catch(error => {
                console.log("error", error);
                message.error('Error al finalizar nómina')
            }).finally(() => {
                this.setState(state => {
                    state.empleados.loading = false
                    return state
                })
            })
        })
    }

    render() {
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="admin-page-header"
                        title="Finanzas Nómina"
                        onBack={() => this.props.navigate(-1)}
                        backIcon={<IconArrowBack />}
                        extra={
                            <Button type="primary" disabled={this.state.nomina.data.estatus === 3 || this.state.empleados.pendiente} onClick={() => this.finalizarNomina()} > Finalizar </Button>
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[10, 10]}>
                            <Col xs={24}>
                                <List
                                    loading={this.state.empleados.loading}
                                    className="component-list"
                                    itemLayout="horizontal"
                                    dataSource={this.state.empleados.data}
                                    locale={{ emptyText: "Sin Empleados" }}
                                    pagination={{
                                        onChange: (page, limit) => this.getEmpleadosNominas({ page, limit }),
                                        current: this.state.empleados.page,
                                        pageSize: this.state.empleados.limit,
                                        total: this.state.empleados.total,
                                    }}
                                    header={<Row className=" width-100 pl-1 pr-1 " align="middle">
                                        <Col xs={1}>  </Col>
                                        <Col xs={23}>
                                            <Row className="width-100" gutter={[10, 12]}>
                                                <Col xs={12} md={10} className="center">
                                                    NOMBRE
                                                </Col>
                                                <Col xs={12} md={6} className="center">
                                                    SALARIO
                                                </Col>
                                                <Col xs={12} md={6} className="center">
                                                    A PAGAR
                                                </Col>
                                                {/* <Col xs={12} md={4} className="center">
                                                    CUENTA
                                                </Col>
                                                <Col xs={24} md={4} className="center">
                                                    ÁREA
                                                </Col>
                                                <Col xs={24} md={3} className="center">
                                                    RUBRO
                                                </Col> */}
                                                <Col xs={24} md={2}></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    }
                                    renderItem={item => (
                                        <List.Item className="component-list-item">
                                            <CardEmpleadoFinanzas
                                                data={item}
                                                onChange={(id, values) => {
                                                    const empleados = this.state.empleados.data
                                                    const index = empleados.findIndex(empleado => empleado._id === id)

                                                    console.log(index, id, values)
                                                    if (index === -1) return

                                                    empleados[index] = {
                                                        ...empleados[index],
                                                        ...values
                                                    }

                                                    this.setState(state => {
                                                        state.empleados.data = empleados
                                                        return state
                                                    })
                                                }}
                                                onCheck={(id) => {
                                                    const seleccionados = this.state.seleccionados
                                                    const index = seleccionados.findIndex(seleccionado => seleccionado === id)

                                                    if (index !== -1) {
                                                        seleccionados.splice(index, 1)
                                                    } else {
                                                        seleccionados.push(id)
                                                    }

                                                    this.setState({ seleccionados })
                                                }}
                                                finalizado={this.state.nomina.data.estatus === 3}
                                                getData={() => {
                                                    this.getEmpleadosNominas()
                                                }}
                                            />
                                        </List.Item>
                                    )}
                                />

                            </Col>
                        </Row>
                        <FloatingButton
                            title="Pagar a seleccionados"
                            icon={<IconCashier />}
                            disabled={this.state.nomina.data.estatus === 3 || this.state.seleccionados.length < 1}
                            background={'#6100FF'}
                            onClick={() => this.pagarNominas()}
                        />
                    </Content>
                </Spin>
            </>
        )
    }
}


export default function (props){

    const params = useParams()
    const navigate = useNavigate()

    return <EmpleadosFinanzas  {...props} params={params} navigate={navigate}/>
}