import React from "react";
import moment from "moment";
import { IconClock } from "../../Widgets/Iconos";
import { Col, Typography } from "antd";
import { rhumbDistance } from "@turf/turf";

require('moment-precise-range-plugin');

const { Text } = Typography;



export default class EdadCounter extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            diff: moment.preciseDiff(moment(this.props.fecha_creacion), moment(), true),
        }
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                diff: moment.preciseDiff(moment(this.props.fecha_creacion), moment(), true)
            })
        }, 30000);

    }

    componentDidUpdate(prevProps) {
        if (this.props.fecha_creacion !== prevProps.fecha_creacion) {
            this.setState({
                diff: moment.preciseDiff(moment(this.props.fecha_creacion), moment(), true)
            })
        }
    }



    render() {
        return <Col span={this.props.span ?? 12} className="center">
            <IconClock /><Text className="text-cyan">Edad:</Text> &nbsp; <Text>{
                `${this.state.diff.years ? this.state.diff.years + " años " : null}${this.state.diff.months ? this.state.diff.months + " meses " : null}${this.state.diff.days ? this.state.diff.days + " dias" : null}`
            } </Text>
        </Col>
    }

}