import React, { useState, useEffect } from 'react';
import { Select, message, Typography } from 'antd';

const { Text } = Typography;
const { Option } = Select ;
const axios = require("axios");

/**
 * @const SelectEstado
 * @description Select para los estados del pais que se seleccione, solo buscara los estados si el pais_id esta definido
 * 
 * @param pais_id ID id del pais del cual se buscaran sus estados
 */
const SelectEstado = (props) => {

    const { value, onChange, placeholder = "Seleccione el estado", onSelect = () => {}, pais_id, disabled = false, className} = props

    const [ estados, setEstados ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
        pais_id: null
    })


    /**
     * @const getEstados
     * @description Obitiene los estados del pais
     */
    const getEstados = ({
        search,
        pais_id = estados.pais_id
    } = estados) => {

        axios.get('/estados', {
            params: {
                search,
                pais_id
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setEstados({
                ...estados,
                pais_id: pais_id,
                data: response.data.data,
            })

        }).catch(error => {
            message('Error al obtener los estados')
        })
    }
    
    //componentDidUpdate
    useEffect(() => {
        if(pais_id)
            getEstados({pais_id})
    }, [pais_id])


    return (
        <Select
            {...props}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            showSearch
            filterOption={false}
            //labelInValue
            onSearch={(search)=> getEstados({search})}
            onSelect={(estado)=> {
                onChange(estado)
                onSelect(estado)
            }}
            value={value}
        >
            {estados.data.map(({ _id, nombre }) => <Option value={_id}>{nombre}</Option>)}
        </Select>
    );
}



export default SelectEstado;