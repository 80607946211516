import React from 'react'
import { Route, Routes } from "react-router-dom";


// componentes
import Reventas from '../../Components/Customer/Reventas/Reventas';


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterReventas(props) {

    return (
        <Routes>
            <Route path="" element={<Reventas />} />
        </Routes>
    )
}

export default RouterReventas;