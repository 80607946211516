import React, { Component } from 'react';
import { Button, Spin, PageHeader, Layout, message, Space, Row, Col, Typography, List, Card, Popconfirm } from 'antd'
import axios from 'axios';
import { IconBtnDelete, IconBtnEdit, IconArrowBack } from '../../Widgets/Iconos';
import { CheckOutlined} from '@ant-design/icons'

import { useNavigate, useParams } from "react-router-dom";
import { CardStatistics } from '../../Widgets/Cards';
import FloatingButton from "../../Widgets/FloatingButton/FloatingButton";
import ModalEmpleadoNomina from './ModalEmpleadoNomina'


const { Content } = Layout;
const { Text, Title } = Typography

/**
 * @export
 * @class EmpleadosNomina
 * @extends {Component}
 * @description Vista de EmpleadosNomina
 */
class EmpleadosNomina extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modal_visible: false,
            empleados: {
                data: [],
                page: 1,
                limit: 20,
                pages: 0,
                total: 0,
                search: null,
                loading: false,
                id: undefined
            },

            nomina_id: this.props.params.nomina_id,


            contadores: {
                total: 6000,
                pagado: 5000,
                pendiente: 1000
            },

            nomina: {},
            redirect: false,
        }
    }

    /**
     * 
     * @method componentDidMount
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.state.nomina_id !== 'undefined') {
            this.getEmpleadosNominas()
            this.getNomina()
        }
    }


    /**
     * @memberOf EmpleadosNomina
     * @method getNomina
     * @descripcion Obtiene los empleados de la nomina actual
     * 
     * */
    getNomina = () => {

        axios.get(`/nominas/${this.props.params.nomina_id}`, {

        }).then(response => {
            console.log("response", response.data);
            this.setState({
                nomina: response.data
            })

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la información')
        })

    }


    /**
     * @memberOf EmpleadosNomina
     * @method getEmpleadosNominas
     * @descripcion Obtiene los empleados de la nomina actual
     * 
     * */
    getEmpleadosNominas = ({
        page = this.state.empleados.page,
        limit = this.state.empleados.limit,
        total  = this.state.empleados.page
    } = this.state.empleados) => {
        axios.get('/empleados-nominas', {
            params: {
                nomina_id: this.state.nomina_id,
                page,
                limit, 
                total
            }
        }).then(response => {
            console.log("response empleados nomina", response.data);
            this.setState({
                empleados: {
                    ...response.data
                }
            })

        }).catch(error => {
            console.log("error", error.response);
            message.error('Error al obtener la información')
        })

    }

    /**
     * @memberOf EmpleadosNomina
     * @method aprobarNomina
     * @descripcion Cambia el estatus de la Nomina 
     * 
     * */
    aprobarNomina = () => {
        axios.put('/nomina', {
            id: this.props.params.nomina_id,
            estatus: 1
        }).then(response => {
            message.success('Nómina actualizada con éxito')
            this.setState({ redirect: true })

        }).catch(error => {
            console.log("error", error.response);
            message.error('Error al actualizar la Nómina')
        })

    }

    autorizarNomina =()=>{
        axios.put('/nomina', {
            id: this.props.params.nomina_id,
            estatus: 2
        }).then(response => {
            message.success('Nómina autorizada con éxito')
            this.setState({ redirect: true })

        }).catch(error => {
            console.log("error", error.response);
            message.error('Error al autorizar la Nómina')
        })
    }
    render() {

        if (this.state.redirect) {
            this.props.navigate("/admin/nominas")
            return <></>
        }

        console.log('props', this.props)

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title={(!this.props.autorizar)?"Carga Nómina":"Autorizar Nómina"}
                        onBack={this.back} backIcon={<IconArrowBack />}
                        extra={
                            (!this.props.autorizar) ? <Button type="primary" disabled={this.state.nomina.estatus >= 1} onClick={() => this.aprobarNomina()}> Finalizar Carga Nómina </Button>
                                : null
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row gutter={[10, 10]}>
                            <Col xs={24} md={12} lg={8}>
                                <CardStatistics
                                    title="Total"
                                    monto={this.state.nomina.monto_total ? this.state.nomina.monto_total.toMoney(true) : 0}
                                    suffix={"mxn"}
                                />
                            </Col>
                            <Col xs={24} md={12} lg={8}>
                                <CardStatistics
                                    title="Pagado"
                                    monto={this.state.nomina.monto_pagado ? this.state.nomina.monto_pagado.toMoney(true) : 0}
                                    suffix={"mxn"}
                                />
                            </Col>
                            <Col xs={24} md={24} lg={8}>
                                <CardStatistics
                                    title="Por Pagar"
                                    monto={this.state.nomina.monto_total ? (this.state.nomina.monto_total - this.state.nomina.monto_pagado).toMoney(true) : 0}
                                    suffix={"mxn"}
                                />
                            </Col>
                            <Col xs={24}>
                                <Title level={4}>Carga Empleados</Title>
                                <List
                                    loading={this.state.empleados.loading}
                                    className="component-list"
                                    itemLayout="horizontal"
                                    dataSource={this.state.empleados.data}
                                    locale={{ emptyText: "Sin Empleados" }}
                                    pagination={{
                                        onChange: (page, limit) => this.getEmpleadosNominas({ page, limit }),
                                        current: this.state.empleados.page,
                                        pageSize: this.state.empleados.limit,
                                        total: this.state.empleados.total,
                                    }}
                                    header={<Row className=" width-100 pl-1 pr-1 ">
                                        <Col xs={12} md={4} className="center">
                                            NOMBRE
                                        </Col>
                                        <Col xs={12} md={3} className="center">
                                            TRABAJADO
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            SALARIO
                                        </Col>
                                        <Col xs={12} md={4} className="center">
                                            MONTO
                                        </Col>
                                        <Col xs={24} md={4} className="center">
                                            PERCEPCIONES
                                        </Col>
                                        <Col xs={24} md={3} className="center">
                                            DEDUCCIONES
                                        </Col>
                                        <Col xs={24} md={2}></Col>
                                    </Row>
                                    }
                                    renderItem={item => (
                                        <List.Item className="component-list-item">
                                            <Card className="card-list">
                                                <Row className="width-100" gutter={[0, 12]}>
                                                    <Col xs={12} md={4} className="center">
                                                        <Text ellipsis>{item.empleado_id?.nombre} {item.empleado_id?.apellido ? item.empleado_id?.apellido : ''} </Text>
                                                    </Col>
                                                    <Col xs={12} md={3} className="center">
                                                        <Text ellipsis>{item.empleado_id?.modalidad_pago === 3 ? <>{item.horas_trabajadas_total ?? 0} horas</> : <>{item.dias_pagados} días</>} </Text>
                                                    </Col>
                                                    <Col xs={12} md={4} className="center">
                                                        <Text ellipsis>$ {item.salario ? item.salario.toMoney(true) : 0} mxn</Text>
                                                    </Col>
                                                    <Col xs={12} md={4} className="center">
                                                        <Text ellipsis>$ {item.monto_autorizado ? item.monto_autorizado.toMoney(true) : 0} mxn</Text>
                                                    </Col>
                                                    <Col xs={12} md={4} className="center">
                                                        <Text ellipsis>$ {item.percepciones_monto ? item.percepciones_monto.toMoney(true) : 0} mxn</Text>
                                                    </Col>
                                                    <Col xs={12} md={3} className="center">
                                                        <Text ellipsis>$ {item.deducciones_monto ? item.deducciones_monto.toMoney(true) : 0} mxn</Text>
                                                    </Col>
                                                    <Col xs={12} md={2} className="center">
                                                        <Space direction="horizontal" wrap>
                                                            <Button
                                                                type="primary"
                                                                className='btn-secondary'
                                                                icon={<IconBtnEdit />}
                                                                disabled={this.state.nomina.estatus >= 2}
                                                                onClick={() => this.setState({ modal_visible: true, empleado_nomina_id: item._id })}
                                                            />
                                                            <Popconfirm
                                                                placement="topRight"
                                                                title="¿Deseas eliminar este empleado?"
                                                                onConfirm={() => axios.delete('/empleado-nomina', { params: { id: item._id } }).then((response) => {
                                                                    message.success('Empleado eliminado')
                                                                    this.getEmpleadosNominas()
                                                                    this.getNomina()
                                                                }).catch((error) => {
                                                                    let msj = "Error al eliminar el empleado"
                                                                    message.error(msj)
                                                                })
                                                                }
                                                                okText="Sí"
                                                                cancelText="No"
                                                            >
                                                                <Button type="primary" danger disabled={this.state.nomina.estatus >= 2} className='btn-delete' title="Eliminar" icon={<IconBtnDelete />} />
                                                            </Popconfirm>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />

                            </Col>
                        </Row>
                    </Content>

                    {(!this.props.autorizar) ?
                        < FloatingButton
                            title="Nuevo registro"
                            disabled={this.state.nomina.estatus >= 1}
                            onClick={() => this.setState({ modal_visible: true })}
                        />
                        : < FloatingButton
                            title="Autorizar Nómina"
                            onClick={this.autorizarNomina}
                            disabled={this.state.nomina.estatus >= 2}
                            icon={<CheckOutlined style={{ color: 'white' }} />}
                        />}
                </Spin>
                <ModalEmpleadoNomina
                    visible={this.state.modal_visible}
                    onCancel={(flag) => {
                        this.setState({
                            modal_visible: false,
                            empleado_nomina_id: undefined
                        })
                        if (flag) {
                            this.getEmpleadosNominas()
                            this.getNomina()
                        }
                    }}
                    empleado_nomina_id={this.state.empleado_nomina_id}
                    nomina_id={this.state.nomina_id}
                />
            </>
        )
    }
}

export default function (props) {

    const navigate = useNavigate()
    const params = useParams()

    return <EmpleadosNomina  {...props} navigate={navigate} params={params}/>
}