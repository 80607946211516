import React, { useContext, useState, useEffect } from "react";
import { Layout, Menu, message } from "antd";
import {useNavigate, Link, useLocation} from "react-router-dom";

import ModalCliente from "../Customer/Perfil/ModalCliente";

import { User, SetUser } from '../../Hooks/Logged'

import "../../Styles/Global/sidebar.css";
import axios from "axios";

const { Sider } = Layout;

/**
 * @const Sidebar
 * @description Header del sistema
 */
const Sidebar = (props) => {

    let user = useContext(User)
    let setUser = React.useContext(SetUser)

    const navigate = useNavigate()
    const location = useLocation()

    const [redirect, setRedirect] = useState(false)
    const [key, setKey] = useState('')
    const [modal_visible, setModalVisible] = useState(false)

    /**
     * @const cerrarSesion
     * @description Cierra la sesion
     */
    const cerrarSesion = () => {
        axios.get('/logout')
        .then(({ data }) => {
            navigate("/");
            sessionStorage.clear();
            setUser(undefined);
        })

    };

    const toggleModalPerfil = () => {
        console.log('toggleModalPerfil',user)
        setModalVisible(!modal_visible)
    }

    useEffect(() => {
        
        const pathname = location.pathname.replace(/\//g, '-').replace(/^-/, '')
        if(pathname !== key){
            setKey(pathname)
        }

    }, [location.pathname])

        return (
            <Sider 
                theme="light" 
                className="hm-sider" 
                width={250}
                breakpoint="lg"
                collapsedWidth="0"
            >
                {redirect ? navigate('/') : null}
                <div className="center pt-1" >
                    <Link to={"/customer/resume"}>
                        <img src="/img/zeus_logo.svg" className="hm-logo-sider" alt="logo" width="200"></img>
                    </Link>
                </div>

                <Menu 
                    className="hm-menu" 
                    defaultSelectedKeys={['0']} 
                    mode="inline"  
                    onClick={({key}) => setKey(key)}
                    selectedKeys={[key]}
                >

                    <Menu.ItemGroup
                        title="NAVEGACIÓN"
                    >                
                        <Menu.Item  key="customer-resume">
                            <Link to={"/customer/resume"}>
                                Resumen
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="customer-invertir">
                            <Link to={"/customer/invertir"}>
                                Invertir
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="customer-mi-cuenta">
                            <Link to="/customer/mi-cuenta">
                                Mi Cuenta
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="customer-cuentas">
                            <Link to="/customer/cuentas">
                                Cuentas
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="customer-cotizador">
                            <Link to="/customer/cotizador">
                                Cotizador
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="customer-beneficiarios">
                            <Link to={"/customer/beneficiarios"}>
                                Beneficiarios
                            </Link>
                        </Menu.Item> 
                        <Menu.Item key="reventas">
                            <Link to={"/customer/reventas"}>
                                Reventas
                            </Link>
                        </Menu.Item> 
                        <Menu.Item key="customer-perfil" onClick={toggleModalPerfil}>
                            Mi Perfil
                        </Menu.Item> 
                    </Menu.ItemGroup> 
                    <Menu.ItemGroup title="SOPORTE">
                        <Menu.Item key="cerrar-sesion">
                            <Link onClick={cerrarSesion}>
                                Cerrar sesión
                            </Link>
                        </Menu.Item>

                    </Menu.ItemGroup>

                    
                </Menu>

                <ModalCliente
                    visible={modal_visible}
                    cliente_id={user?.cliente?._id}
                    onClose={() => {
                        setModalVisible(false)
                    }}

                />

            </Sider>
        )
}

export default function sider (props){

    return <Sidebar {...props}/>

};