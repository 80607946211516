import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, message, Tag, Space } from 'antd';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import { RightOutlined, FilePdfOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";

//componentes
// import { renderEstatusInversion } from '../../../Utils'


const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')

/**
 * @export
 * @class InversionDetalle
 * @extends {React.Component}
 */
class InversionDetalle extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			inversion_id: this.props.inversion_id,
            transacciones: {
				page: 1,
				total: 0,
				limit: 10,
				data: []
			},
		}
	}

	componentDidMount() {
		this.getTransacciones();
	}

	/**
	* @method getTransaccion
	* @description Obtiene la lista de transacciones relacionadas a un inversion
	*/
	getTransacciones = ({
		page = this.state.transacciones.page,
		limit = this.state.transacciones.limit,
	} = this.state.transacciones) => {

		this.setState({ loading_transaccion: true })

		axios.get('/customer/transaccion', {
			params: {
				page,
				limit,
				inversion_id: this.props.params.inversion_id,
			}
		}).then(response => {
			console.log("response", response.data);
			this.setState({
				transacciones: {
					...this.state.transacciones,
					data: response.data.data.data,
					page: response.data.data.page,
					total: response.data.data.total,
				}
			})

		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading_transaccion: false }))
	}

	/**
	 *
	 * @memberof Transacciones
	 * 
	 * @method getPDF
	 * @description Generamos el PDF del comprobante de pago
	 */
	getPDF = (transaccion_id) => {
		let urlPDF = new URL(axios.defaults.baseURL + '/transaccion/comprobante/pdf');
		urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
		urlPDF.searchParams.append('transaccion_id', transaccion_id)
		window.open(urlPDF.href, '_blank');
	}

	render() {

		const { inversion } = this.state

		return (
            <List
            loading={this.state.loading}
            className="component-list"
            itemLayout="horizontal"
            dataSource={this.state.transacciones.data}
            locale={{ emptyText: "Sin transacciones " }}
            pagination={{
                current: this.state.transacciones.page,
                pageSize: this.state.transacciones.limit,
                total: this.state.transacciones.total,
                position: 'bottom',
                className: "flex-left",
                onChange: (page) => this.getTransacciones({ page })
            }}
            renderItem={item => (
                <List.Item className="component-list-item">
                    <Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
                        <Row className="width-100 ">
                            <Col span={1} className="center">
                                <Avatar src="/img/zeus_logo_sin_texto.svg" />
                            </Col>
                            <Col className="center" xs={{ span: 18 }} md={{ span: 5 }}>
                                <Text>Compra {item?.inversion_id?.cantidad} Planta(s)</Text>
                            </Col>
                            <Col className="center" xs={{ span: 20 }} md={{ span: 5 }}>
                                <Text>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
                            </Col>
                            <Col className="center" xs={{ span: 8 }} md={{ span: 5 }}>
                                <Text>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                            </Col>
                            <Col className="center" xs={{ span: 8 }} md={{ span: 5 }}>
                                <Text>$ {item.monto.toMoney(true)} {item.moneda}</Text>
                            </Col>
                            <Col className="center" xs={{ span: 8 }} md={{ span: 2 }}>
                                <Space>
                                    <Button
                                        type="primary"
                                        className='center'
                                        icon={<FilePdfOutlined />}
                                        onClick={() => this.getPDF(item._id)}
                                    />
                                </Space>
                            </Col>
                        </Row>
                    </Card>
                </List.Item>
            )}
        />
		)
	}
}


export default function (props) {

	const params = useParams()
	const navigate = useNavigate()

	return <InversionDetalle  {...props} params={params} navigate={navigate} />
}