import React, { Component } from "react";
import { Button, Form, Input, message, Modal, Spin, Typography,  Select, Row, Col, Divider, InputNumber, Upload, Empty, DatePicker} from 'antd';
import PhoneInput from '../../Widgets/Inputs/PhoneInput'
import PropTypes from "prop-types";

const axios = require('axios').default;
const moment = require('moment');


const { Title, } = Typography;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};


/**
 *
 *
 * @export
 * @class FormCliente 
 * @extends {Component}
 */
class FormCliente extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    formRef = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.formRef.current.setFieldsValue({
            cliente_id: this.props.cliente_id
        })

        if (this.props.beneficiario_id) {
            this.getBeneficiario()
        }
    }


    

    /**
    * @memberOf FormCliente
    * @method onFinish
    * @description Se ejecuta al hacer submit al formulario
    */
    onFinish = values => {

        if(this.props.beneficiario_id){
            this.updateBeneficiario(values)
        }else{
            this.addBeneficiaro(values)
        }
    }

    /**
    * @memberOf FormCliente
    * @method addCliente
    * @param {object} cliente El objeto cliente
    * @description Crea un nuevo registro de cliente
    */
    addBeneficiaro = beneficiario => {
        axios.post('/customer/beneficiario', beneficiario)
            .then(response => {
                message.success('Se ha guardado exitosamente.')
                this.props.onClose()
            })
            .catch(error => {
                // console.log(error)
                message.error('Ha ocurrido un error al guardar la información.')
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    * @memberOf FormCliente
    * @method updateCliente
    * @param {object} cliente El objeto cliente
    * @description Actualiza la informacion de un cliente
    */
    updateBeneficiario = beneficiario => {
        axios.put('/customer/beneficiario', {
            ...beneficiario,
            beneficiario_id: this.props.beneficiario_id
        }).then(response => {
            message.success('Se ha guardado exitosamente.')
            this.props.onClose()
        })
            .catch(error => {
                let msg = "Ha ocurrido un error al actualizar la información."
                message.error(msg)
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
    * @memberOf FormCliente
    * @method getCliente
    * @param {string} cliente_id Identificador del cliente
    * @description Obtiene la informacion de un cliente
    */
    getBeneficiario = (beneficiario_id = this.props.beneficiario_id) => {

        axios.get('/customer/beneficiario', { params: { id: beneficiario_id } })
            .then(({ data }) => {
                
                // console.log(data)

                

                this.formRef.current.setFieldsValue({
                    ...data,
                })

            })
            .catch(error => {
                message.error(error?.response?.data)
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {
        const {  formRef, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.beneficiario_id ? "Editar Beneficiario" : "Nuevo Beneficiario"}</Title>
                <Form
                    {...formItemLayout}
                    layout="vertical"
                    ref={formRef}
                    initialValues={form}
                    onFinish={this.onFinish}
                    scrollToFirstError={true}
                >
                    <Form.Item name="cliente_id" hidden={true}/>
                    <Row className="w-100" gutter={[16, 16]}>
                        <Col span={24}>
                            <Divider orientation="left">Información Personal</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Nombre Completo"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese los nombres del cliente" }
                                ]} >
                                <Input placeholder="Luis Gerardo Mendez Mendez" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item
                                label="RFC"
                                name="rfc"
                                // rules={[{
                                //     required: true,
                                //     message: "Por favor, ingrese el RFC del empleado"
                                // }]}
                                rules={[
                                    {
                                        pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                        message: 'RFC invalido'
                                    }
                                ]}
                            >
                                <Input placeholder="Nombre" maxLength={50}></Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item
                                label="CURP"
                                name="curp"
                                // rules={[{
                                //     required: true,
                                //     message: "Por favor, ingrese el CURO del empleado"
                                // }]}
                                rules={[
                                    {
                                        pattern: /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
                                        message: 'CURP invalido'
                                    }
                                ]}
                            >
                                <Input placeholder="CURP" />
                            </Form.Item>
                        </Col>
                        
                        <Col span={24}>
                            <Divider orientation="left">Información de Contacto</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[
                                    {required: true, message: "Ingrese el teléfono del beneficiario"}
                                ]}>
                                <PhoneInput />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'Email no valido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el Email',
                                    },
                                ]}
                            >
                                <Input 
                                    controls={false} 
                                    className="width-100"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">Dirección</Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                                >
                                <Input placeholder="123 Dirección, Ciudad, Pais, C.P." />
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Form.Item className="text-center">
                        <Button htmlType="submit" type="primary" loading={this.state.loading} >
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            // closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={600}
            className="modal-zeus"
        >
            <FormCliente {...props} />
        </Modal>
    )
}
