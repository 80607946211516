import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';

import POS from '../../Components/Admin/POS/POS';
/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterInventarios(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<POS  {...props}  />} />
        </Routes>
    )
}

export default RouterInventarios;