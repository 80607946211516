import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, message, Popconfirm, Space, Tooltip, Tag, Popover } from 'antd';
import { List, Avatar } from 'antd';
import ReactECharts from 'echarts-for-react';
import axios from 'axios'
import { Link, useHistory, useNavigate, useSearchParams } from "react-router-dom";
import "../../../Styles/Modules/dashboard.css";
import ModalReventa from "../Reventa/ModalReventa";
import { IconBtnDelete, IconBtnEdit, IconEye } from '../../Widgets/Iconos'
import { QuestionCircleOutlined} from "@ant-design/icons";

const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')



/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {React.Component}
 * 
 * @description Resumen del Cliente
 */
class Reventas extends React.Component {

	constructor(props) {
		super(props)
		this.state = {


			loading: false,
			reventas: {
				page: 1,
				total: 0,
				limit: 10,
				data: []
			},

			modal_visible: false,
			inversion_id: null

		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getReventas()

	}


	renderEstatusInversion = ({ estatus = 0 }, item) => {

		let steps = {
			0: <Tag className="tag-inversion-cancelada">CANCELADA</Tag>,
			1: <Tag className="tag-inversion-pagada">EN PROCESO DE VENTA</Tag>,
			2: <Tag className="tag-inversion-ejecutada">VENDIDA</Tag>,
			4: <Tag className="tag-inversion-reenvieda">REQUIERE MODIFICACIÓN</Tag>,

		}

		return estatus != undefined ? steps[estatus] : 'N/A'

	}



	/**
	* @method getReventas
	* @description Obtiene la lista de inversiones del cliente
	*/
	getReventas = ({
		page = this.state.reventas.page,
		limit = this.state.reventas.limit,
	} = this.state.reventas) => {

		this.setState({ loading: true })

		axios.get('/customer/reventas', {
			params: {
				page: page,
				limit: limit,
			}
		}).then(response => {
			console.log("response", response.data);
			this.setState({
				reventas: {
					...this.state.reventas,
					data: response.data.data,
					page: response.data.page,
					limit: response.data.limit,
					total: response.data.total
				},
			})



		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las transacciones')
		}).finally(() => this.setState({ loading: false }))
	}

	render() {

		return (
			<Content className="hm-layout-content layout-dashboard" >
				<PageHeader className="site-page-header" ghost={false} title="Mis Reventas" />

				<List
					loading={this.state.loading}
					className="component-list"
					itemLayout="horizontal"
					dataSource={this.state.reventas.data}
					locale={{ emptyText: "Sin reventas " }}
					pagination={{
						current: this.state.reventas.page,
						pageSize: this.state.reventas.limit,
						total: this.state.reventas.total,
						position: 'bottom',
						className: "flex-left",
						onChange: (page) => this.getReventas({ page })
					}}
					renderItem={item => (
						<List.Item className="component-list-item">
							<Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
								<Row className="width-100 ">
									<Col span={1} className="center">
										<Avatar src="/img/zeus_logo_sin_texto.svg" />
									</Col>
									<Col className="center" xs={{ span: 18 }} md={{ span: 5 }}>
										<Popover
											overlayStyle={{
												//boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
											}}
											content={
												<>
													{item.cantidad ?? 0} plantas en total. <br />
													{item.cantidad_restante ?? 0} para reventa. <br />
													{item.cantidad_vendida ?? 0} plantas vendidas. <br />
												</>
											}>
											{item.cantidad_vendida} / {item.cantidad} planta{item.cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
										</Popover>
									</Col>
									<Col className="center" xs={{ span: 20 }} md={{ span: 5 }}>
										<Text>{item.hacienda_id ? item.hacienda_id?.nombre : 'N/A'}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 3 }}>
										<Text>{moment(item.createdAt).format('DD-MM-YYYY')}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 5 }}>
										<Text>{this.renderEstatusInversion({ estatus: item.estatus }, item)}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 3 }}>
										<Text>$ {item?.precio_reventa?.toMoney(true)} {item.moneda}</Text>
									</Col>
									<Col className="center" xs={{ span: 8 }} md={{ span: 2 }}>
										<Space wrap>
											<Button
												type="primary"
												disabled={item.estatus === 2}
												className='btn-secondary center'
												icon={<IconBtnEdit />}
												onClick={() => {
													this.setState({ modal_revender: true, reventa: item._id })
												}}
											/>

											<Popconfirm
												placement="topRight"
												title="¿Deseas eliminar esta venta?"
												disabled={item.cantidad_vendida > 0 ? true : false}
												onConfirm={() => axios.delete('/reventa', { params: { id: item._id } }).then((response) => {
													message.success("Reventa eliminada correctamente")
													this.getReventas()
												})
													.catch((error) => {
														message.error("Hubo un error al eliminar la venta");
														this.getReventas();
													})
												}
												okText="Si"
												cancelText="No"
											>
												<Button type="primary" disabled={item.cantidad_vendida > 0 ? true : false} className='btn-delete center' danger title="Eliminar" icon={<IconBtnDelete />} />
											</Popconfirm>
										</Space>
									</Col>
								</Row>
							</Card>
						</List.Item>
					)}
				/>
				<ModalReventa
					visible={this.state.modal_revender}
					reventa={this.state.reventa}
					onClose={
						() => {
							this.setState({ modal_revender: false, reventa: undefined })
							this.getReventas(this.state.page)
						}
					}
				/>
			</Content>

		)
	}
}

export default props => {

	const navigate = useNavigate()
	const [params, setParams] = useSearchParams();

	return <Reventas {...props} navigate={navigate} params={params} />
}