
import React from 'react'
import { Link } from 'react-router-dom'

import { Tag } from 'antd';

const queryString = require('query-string');

const defineFunction = (key, value = "", func = () => { }) => {
    switch (key) {
        case 'roles':
            return { to: '/admin/usuarios/tipos/ver/' + value };

        case 'usuarios':
            return { to: '/admin/usuarios/ver/' + value };
        case 'reportes':
            return { to: '/admin/reportes/edicion/' + value }

        case 'nueva_solicitud':
            return { onClick: () => func(queryString.parse(value)) }
    }
}

const createUrl = (string, func = () => { }) => {
    let link = string.replace(']', ' ').replace('[', '@').split('|')
    return <span style={{ color: "#7121ac", fontWeight: "Bold", cursor: "pointer" }} >{link[0]}</span>
}

//Partimos el STRING en texto normal y texto entre corchest. A los corechetes les crearemos un URL

const reformat = (string, func = () => { }) =>
    string.split(/(\[.*?\])/).map(string => string.match(/(\[.*?\])/) ?
        createUrl(string, func) : string.split(/(\{.*?\})/).map(string => string.match(/{.*}/) ? null
            : string)
    )


const createSpanStatus = (string, func = () => { }) => {
    let status = string.split("}")
    let id = status[0].replace("({", "");
    let nombre = status[1].replace("{", "");
    let color = status[2].replace("{", "");

    //para las actualizaciones pasadas que se guardaron sin el #
    color = color.includes('#') ? color : `#${color}`;
    return <Tag className="hm-tag-msj"
        key={id}
        color={color || "#7a7a7a"}
        key={nombre}
    >
        {nombre.toUpperCase()}
    </Tag>

}

//Partimos el STRING en texto normal y texto entre corchest. A los corechetes les crearemos un URL
const reformatStatus = (string, func = () => { }) =>
    string.split(/(\(.*?\))/).map(string => string.match(/(\(.*?\))/) ?
        createSpanStatus(string, func) : string.split(/(\{.*?\})/).map(string => string.match(/{.*}/) ? null
            : string)
    )

export { reformat, createUrl, reformatStatus, createSpanStatus }