import { Row, Col } from "antd";
import VideoPlayer from "../VideoPlayer";

/**
 * @function Video
 * @description Componente que contiene un video, se
 *              utiliza en la seccion de landing.
 * @returns {JSX.Element} Componente de video.
 * @example
 * <Video />
 */
 const VideoSection = ({
    cover="",
    source=""
 }) => (
  <Row
    className="section mt-50 mb-50"
    justify="center"
  >
    <Col span={24} style={{ maxWidth: "1200px",width:"80vw" }}>
      <VideoPlayer cover={cover}>
        <source src={source} type="video/mp4" />
      </VideoPlayer>
    </Col>
  </Row>
);
export default VideoSection