
import React, { Component } from 'react';
import { Image, Typography, message, List, Col, Row, Card, } from 'antd';
import axios from 'axios';
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class TableReporte
 * @extends {Component}
 */
export default class TableReporte extends Component {

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      data: [],
      page: 1,
      limit: 10,
      total: 0
    }
  }

  componentDidMount() {
    this.getReporte();
  }


  /**
   *
   *
   * @memberof TableReporte
   * @description Obtiene los datos de un reporte
   */
  getReporte = (page = this.state.page, limit = this.state.limit, { reporte_id = this.props.reporte_id } = this.props) => {

    axios.get('/reporte', {
      params: {
        id: reporte_id,
        page,
        limit
      }
    }).then(res => {
      
      this.setState({
        data: res.data.data.itemsList,
        page: res.data.data.paginator.currentPage,
        total: res.data.data.paginator.itemCount,
      })

    }).catch(error => {
      message.error('Error al traer el reportes')
      
    }).finally(() => this.setState({ loading: false }))

  }

  render() {

    return (
      <List
        loading={this.state.loading}
        className="component-list"
        itemLayout="horizontal"
        locale={{ emptyText: "Sin Productos" }}
        dataSource={this.state.data}
        pagination={{
          current: this.state.page,
          pageSize: this.state.limit,
          onChange: page => this.getReporte(page),
          total: this.state.total,
          position: 'bottom',
          className: "flex-left"

        }}
        header={<Row className="width-100 pl-1 pr-1" >
          <Col xs={10} lg={3} >
            <Text strong>Imagen</Text>
          </Col>
          <Col xs={10} lg={5} >
            <Text strong>Nombre</Text>
          </Col>
          <Col xs={12} lg={5} >
            <Text strong>SKU</Text>
          </Col>
          <Col xs={12} lg={7} >
            <Text strong>Descripción</Text>
          </Col>
          <Col xs={12} lg={2} className="center" >
            <Text strong>Existencias</Text>
          </Col>
          <Col xs={12} lg={2} className="center" >
            <Text strong>Faltante</Text>
          </Col>
        </Row>
        }


        renderItem={({ item, reportes_producto }) => (
          <List.Item className="component-list-item">
            <Card className="card-list">
              <Row className="width-100" align='middle'>
                <Col xs={10} lg={3} >
                  <Image
                    placeholder
                    {...(
                      reportes_producto.variante.producto.imagenes.length > 0 ? {
                        src: axios.defaults.baseURL + "/upload/" + reportes_producto.variante.producto.imagenes[0].filename
                      } : {}
                    )}
                    width={50}
                    height={50}
                  />
                </Col>
                <Col xs={10} lg={5} >
                  <Text strong>{reportes_producto.variante.producto.nombre}</Text>
                </Col>
                <Col xs={12} lg={5} >
                  <Text strong>{reportes_producto.variante.sku_variante}</Text>
                </Col>
                <Col xs={12} lg={7} >
                  <Text strong className="text-wrap">{reportes_producto.variante.descripcion.slice(0, 50)}</Text>
                </Col>
                <Col xs={12} lg={2} className="center">
                  <Text strong>{reportes_producto.existencias}</Text>
                </Col>
                <Col xs={12} lg={2} className="center">
                  <Text strong>{reportes_producto.inv_minimo - reportes_producto.existencias}</Text>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    )
  }
}