import React from 'react'
import { Route, Routes } from "react-router-dom";
import Empleados from '../../Components/Admin/Empleados/Empleados';
import EmpleadoDetalles from '../../Components/Admin/Empleados/EmpleadoDetalles';
import { useEffect } from 'react';

/**
 * @export
 * @function RouterEmpleados
 * @extends {Component}
 */
function RouterEmpleados(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Empleados  {...props}  />} />
            <Route path="detalle/:empleado_id" element={<EmpleadoDetalles  {...props}  />} />
        </Routes>
    )
}

export default RouterEmpleados;