import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, Tag, } from 'antd';
import { LinkOutlined } from '@ant-design/icons'

const { Title, Text } = Typography;

const moment = require('moment');
const axios = require('axios').default;


/**
 *
 *
 * @class ModalMovimientoDetalle
 * @extends {Component}
 */
class ModalMovimientoDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: false,
            return: false,
            movimiento: {}
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.movimiento_id) {
            this.getMovimiento()
        }
    }

    /**
    * @method getMovimiento
    * @description Obtiene la informacion del movimiento
    */
    getMovimiento = () => {
        this.setState({ loading: true })
        axios.get('/inventarios/movimiento', {
            params: {
                id: this.props.movimiento_id,
                movimiento: true
            }
        }).then(response => {
            
            this.setState({ movimiento: response.data.data })
        }).catch(error => {
            message.error('Error al obtener la Información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method onFinish
    * @description Cierra la movimiento
    */
    onFinish = () => {
        this.props.onCancel()
    }


    render() {

        const { movimiento } = this.state

        return (
            <Form
                layout="vertical"
                className="pd-1"
            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Producto"
                            >
                                <Text>{movimiento.variante?.producto?.nombre}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Descripción"
                            >
                                <Text>{movimiento.variante?.descripcion}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="SKU"
                            >
                                <Text>{movimiento.variante?.sku_variante}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Tamaño"
                            >
                                <Text>{movimiento.variante?.size?.size}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Fecha"
                            >
                                <Text>{moment(movimiento.createdAt).format('DD-MM-YYYY')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Tipo"
                            >
                                {
                                    movimiento.tipo === 1 ? (
                                        <Tag color="#A2D9CE">Entrada</Tag>
                                    ) : (
                                        <Tag color="#FFC300">Salida</Tag>
                                    )
                                }
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Usuario"
                            >
                                <Text>{movimiento.usuario?.nombres} {movimiento.usuario?.apellido_paterno} {movimiento.usuario?.apellido_materno}</Text>
                            </Form.Item>
                        </Col>
                        {
                            movimiento.venta?.cliente_id ? (
                                <Col xs={24} lg={12} >
                                    <Form.Item
                                        label="Cliente"
                                    >
                                        <Text>{`
                                        ${movimiento.venta?.cliente_id?.usuario_id?.nombres}
                                        ${movimiento.venta?.cliente_id?.usuario_id?.apellido_paterno}
                                        ${movimiento.venta?.cliente_id?.usuario_id?.apellido_materno}`}</Text>
                                    </Form.Item>
                                </Col>
                            ) : null
                        }
                        {
                            movimiento.venta ? (
                                <Col xs={24} lg={12} >
                                    <Form.Item
                                        label="Total"
                                    >
                                        <Text>$ {(movimiento.venta.total.toMoney)?movimiento.venta.total.toMoney(true):0} MXN</Text>
                                    </Form.Item>
                                </Col>
                            ) : null
                        }
                        {
                            movimiento.venta ? (
                                <Col xs={24} lg={12} >
                                    <Form.Item
                                        label="Ticket"
                                    >
                                        <Button type="link"
                                            onClick={() => {
                                                let url = new URL(axios.defaults.baseURL + '/ventas/ticket')
                                                url.searchParams.append('venta_id', movimiento.venta._id)
                                                url.searchParams.append('Authorization', sessionStorage.getItem('token'))
                                                window.open(url, '_blank')
                                            }}
                                        >
                                            Abrir Ticket <LinkOutlined />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            ) : null
                        }

                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
        className="modal-zeus"
    >
        <div className="center">
            <Title level={3}>Movimiento</Title>
        </div>
        <ModalMovimientoDetalle {...props} />
    </Modal>

}