import React from 'react'
import { Route, Routes } from 'react-router-dom'

import PublicRoutes from './Routes/PublicRoutes';
import CustomerRoutes from './Routes/CustomerRoutes';
import AdminRoutes from './Routes/AdminRoutes';
import { Auth } from './Routes/Middlewares/Auth';
import './assets/translations/i18'


// export default function() {
//   return (
//     <Switch>
//       <Route path="/admin" component={AdminRoutes} />
//       <Route path="/customer" component={CustomerRoutes} />
//       <Route path="/" component={PublicRoutes} />
//     </Switch>
    
//   )
// }

/**
 * 
 * @function Routes
 * @description Router principal de la app
 * @export
 */
export default function rutas(){

  return (
    <Routes>
      <Route
        path="/admin/*"
        element={
          <AdminRoutes/>
        }
      />

      <Route
        path="/customer/*"
        element={
          <CustomerRoutes/>
        }
      />

      <Route
        path="/*"
        element={
          <PublicRoutes/>
        }
      />
    </Routes>
  )
}
