import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, DatePicker, Select, Empty } from 'antd';
import { SelectCliente } from "../../Widgets/Inputs/Inputs"

const { Title, Text } = Typography;
const { Option } = Select;
const moment = require('moment');
const axios = require('axios').default;
let Decimal = require('decimal.js')

/**
 *
 *
 * @class ModalInversion
 * @extends {Component}
 */
class ModalInversion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            inversion: {},
            hacienda: undefined,
            cliente: undefined,

            haciendas: {
                data: [],
                page: 1,
                limit: 20,
                total: 0,
                search: undefined,
                loading: false
            },

            clientes: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                search: undefined,
                loading: false
            },
            tipo_cambio_inv: undefined,
            moneda: "MXN"
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.inversion_id) {
            this.getInversion()
        }else{
            this.formRef.current?.setFieldsValue({
                estatus: 1,
                moneda: "MXN",
                fecha: moment()
            })
        }

        this.getHaciendas()
        this.getTipoCambio()

        
    }

    /**
    * @method getInversion
    * @description Obtiene la informacion del inversion
    */
    getInversion = () => {
        this.setState({ loading: true })
        axios.get(`/inversion`, {
            params: {
                id: this.props.inversion_id
            }
        }).then(response => {
            let inversion = response.data.data
            console.log("inversion", inversion);
            let {hacienda_id, cliente_id} = inversion
            
            this.setState({
                inversion, 
                hacienda: hacienda_id,
                moneda: inversion.moneda,
                tipo_cambio_inv: inversion.tipo_cambio,
            })

            this.formRef.current?.setFieldsValue({
                ...inversion,
                fecha: moment(inversion.fecha),
                hacienda_id: hacienda_id._id,
                cliente_id: {
                    value: cliente_id._id,
                    key: cliente_id._id,
                    label: cliente_id.nombre + " " + cliente_id.apellido_paterno,
                },
                hacienda_id: {
                    value: hacienda_id._id,
                    key: hacienda_id._id,
                    label: hacienda_id.nombre + " " + cliente_id.apellido_paterno,
                },
                cantidad: inversion.cantidad,
            })

        }).catch(error => {
            message.error('Error al obtener la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method getTipoCambio
    * @description Obtiene el tipo de cambio actual
    */
    getTipoCambio = () => {
        axios.get('/plantas')
        .then(res => {
            this.setState({tipo_cambio: res.data.data.tipo_cambio })
        })
    }

    /**
    * @method getHaciendas
    * @description Obtiene un listado de haciendas
    */
    getHaciendas = ({
        page = this.state.haciendas.page,
        limit = this.state.haciendas.limit,
        search = this.state.haciendas.search
    } = this.state.haciendas) => {
        this.setState({haciendas: {...this.state.haciendas, page, limit, search, loading: true}})
        axios.get('/haciendas', {
            params: {
                page,
                limit,
                search
            }
        }).then(({data}) => {
            let haciendas = data.data

            this.setState({haciendas: {
                ...this.state.haciendas,
                data: haciendas.data,
                total: haciendas.total
            }})
        }).catch(error => {
            message.error("Error al obtener las haciendas")
        }).finally(() => {
            this.setState(status => {
                status.haciendas.loading = false
                return status
            })
        })
    }

    /**
    * @method getClientes
    * @description Obtiene un listado de clientes
    */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search
    } = this.state.clientes) => {
        this.setState({clientes: {...this.state.clientes, page, limit, search, loading: true}})
        axios.get('/clientes', {
            params: {
                page,
                limit,
                search
            }
        }).then(({data}) => {

            this.setState({clientes: {
                ...this.state.clientes,

                ...data
            }})
        }).catch(error => {
            message.error("Error al obtener los clientes")
        }).finally(() => {
            this.setState(status => {
                status.clientes.loading = false
                return status
            })
        })
    }

    /**
    * @method onFinish
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Cierra la inversion
    */
    onFinish = (values) => {

        if(typeof values.cliente_id !== 'string') values.cliente_id = values.cliente_id?.value
        if(typeof values.hacienda_id !== 'string') values.hacienda_id = values.hacienda_id?.value

        if (this.props.inversion_id)
            this.updateInversion(values)
        else
            this.addInversion(values)
    }

    /**
    * @method updateInversion
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Actualiza una inversion
    */
    updateInversion = (values) => {
        console.log("values", values);
        this.setState({loading: true})
        axios.put('/inversion', {
            ...values,
            id: this.props.inversion_id
        }).then((success) => {
            message.success("Inversión actualizada")
            this.props.onClose(true)
        }).catch(error => {
            if(error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error("Error al actualizar inversión")
        }).finally(() => this.setState({loading: false}))
    }

    /**
    * @method addInversion
    * @param {object} values - Objecto que contiene los valores del formulario
    * @description Crea una inversion
    */
    addInversion = (values) => {
        this.setState({loading: true})
        axios.post('/inversion', {
            ...values,
            tipo_cambio: this.state.tipo_cambio,
        }).then((success) => {
            message.success("Inversión creada")
            this.props.onClose(true)
        }).catch(error => {
            if(error.response.status === 403)
                message.error(error.response.data.message)
            else
                message.error("Error al crear inversión")
        }).finally(() => this.setState({loading: false}))
    }



    render() {

        const { inversion } = this.state
        const { hacienda_id, cliente_id } = inversion
        const { cliente } = this.props

        const disabled = (this.props.inversion_id !== undefined)

        return (
            <Form
                layout="vertical"
                ref={this.formRef}
                onFinish={this.onFinish}
                style={{ padding: '1em' }}
                initialValues={{
                    moneda: "MXN",
                    cliente_id: {
                        value: cliente?._id,
                        key: cliente?._id,
                        label: cliente?.nombre + " " + cliente?.apellido_paterno + " " + cliente?.apellido_materno
                    }
                }}
                onValuesChange={({cantidad, precio_planta, hacienda_id, moneda}, allValues)=>{
                    // //Si el precio o la cantidad cambian, se hace el calculo del monto
                    if (cantidad || hacienda_id || moneda) {

                        if (!hacienda_id)
                            hacienda_id = allValues.hacienda_id?.value ?? allValues.hacienda_id

                        let hacienda = this.state.haciendas.data.find(h => h._id === (hacienda_id || allValues.hacienda_id))
                        precio_planta = (allValues.moneda == "USD") ? Decimal(hacienda.precio).div(this.state.tipo_cambio || 0).toDecimalPlaces(2).toNumber() : hacienda.precio
                        cantidad = allValues.cantidad
                        let monto = Decimal(precio_planta ?? 0).mul(Decimal(cantidad ?? 0)).toDecimalPlaces(2).toNumber()    
                        this.setState({hacienda})
                        this.formRef.current?.setFieldsValue({ 
                            precio_planta, monto, moneda: allValues.moneda
                        })
                    }

                    if (precio_planta) {
                        precio_planta = (allValues.moneda == "USD") ? Decimal(precio_planta).div(this.state.tipo_cambio).toDecimalPlaces(2).toNumber() : precio_planta
                        cantidad = allValues.cantidad
                        let monto = Decimal(precio_planta || allValues.precio_planta || 0).mul(Decimal(cantidad || allValues.cantidad || 0)).toDecimalPlaces(2).toNumber()    
                        this.formRef.current?.setFieldsValue({ 
                            monto, 
                        })
                    }
                }}
            >
                <Spin spinning={this.state.loading}>

                    <Row gutter={[24, 24]}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una cliente"
                                }]}
                            >
                                <SelectCliente
                                    disabled={cliente}
                                />
                            
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Hacienda"
                                name="hacienda_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una hacienda"
                                }]}
                            >
                                <Select
                                    showSearch
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    allowClear={true}
                                    onSearch={(search) => this.getHaciendas({search})}
                                    notFoundContent={<Empty
                                        description="No se encontró ninguna hacienda"
                                    />}

                                    // onSelect={a => {

                                    //     console.log("this.formRef",)
                                    //     // if (this)

                                    // }}
                                >
                                    {
                                        this.state.haciendas.data.map(({ _id, nombre }) => {
                                            return <Option key={_id} value={_id}>{nombre}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Cantidad Plantas"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Ingrese las plantas a comprar"
                                },
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => {
                                            if(value > this.state.hacienda.disponible)
                                                return Promise.reject(new Error(`La hacienda solo tiene ${this.state.hacienda.disponible} plantas`))
                                            return Promise.resolve()
                                        }
                                    })
                                ]}
                            >
                                <InputNumber
                                    disabled={!this.state.hacienda}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                    min={1}
                                    precision={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label={<Text>Precio Planta</Text>}
                                name="precio_planta"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el precio por planta"
                                }]}
                            >
                                <InputNumber
                                    disabled={!this.state.hacienda}
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                    min={0.01}
                                    precision={2}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={this.props.inversion_id ? 8 : 12} >
                            <Form.Item
                                label="Monto"
                                name="monto"
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="width-100"
                                    precision={2}
                                />
                            </Form.Item>
                        </Col>
                        {
                            this.props.inversion_id && (
                                <Col xs={24} md={8} >
                                    <Form.Item
                                        label="Tipo Cambio"
                                        name="tipo_cambio"
                                    >
                                        <InputNumber
                                            disabled
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            className="width-100"
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col xs={24} md={this.props.inversion_id ? 8 : 12} >
                            <Form.Item
                                label="Moneda"
                                name="moneda"
                            >
                                <Select
                                    // onSelect={(value)=>{
                                    //     this.handleMoneda(value)
                                    //     this.setState({moneda: value})
                                    // }}
                                >
                                    <Option value={"MXN"}>MXN</Option>
                                    <Option value={"USD"}>USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} >
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                            >
                                <DatePicker className="width-100"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Estatus"
                                name="estatus"
                            >
                                <Select>
                                    <Option value={0}>Cancelada</Option>
                                    <Option value={1}>Pendiente</Option>
                                    <Option value={2}>Pagada</Option>
                                    <Option value={3}>Ejecutada</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        
                        
                    </Row>

                    <Row>
                        <Col span={24} className="center">
                            <Form.Item className="m-0">
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        title={null}
        footer={null}
        visible={visible}
        onCancel={onClose}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        placement="bottom"
       
    >
        <div className="center">
            <Title level={3}>Inversión</Title>
        </div>
        <ModalInversion {...props} />
    </Modal>

}