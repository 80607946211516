

import React, { Component } from "react";
import { Col, Form, message, Modal, List, Avatar, Row, Divider, Statistic, Spin, Typography, } from 'antd';
import '../../../Styles/Global/modales.css';
const axios = require('axios').default;
const { Title, Text, Paragraph } = Typography;

const colors = ["#00000", "#87d068", "#FA9100"];
const moment = require('moment')

/**
 *
 *
 * @class ModalInversionDetalle
 * @extends {React.Component}
 * @description Formulario Detalle de Venta
 */
class ModalInversionDetalle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            inversion: {
                monto: 0,
                iva: 0,
                hacienda_id: {},
                monto_pendiente: 0,
                plantas_compradas: 0
            }
        }
    }


    /**
     * @methodOf ModalInversionDetalle
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component,
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getInversion(this.props.id)
    }


    /**
     *
     *
     * @memberof ModalInversionDetalle
     * @description Trae la inversion 
     * @id ObjectId de la inversion
     */
    getInversion = (id) => {
        axios.get('/inversion', {
            params: {
                id: this.props.id,
                transacciones: true
            }
        })
            .then(res => {

                console.log('res inv', res)
                this.setState({
                    inversion: res.data.data,
                    transacciones: res.data.data.transacciones
                })
            }).catch(error => {
                console.log('error', error)
                message.error('Error al traer detalle de inversion');

            })
    }






    render() {
        const { inversion, transacciones } = this.state;
        return (

            <>
                <Spin spinning={false}>
                    <Title level={3} className="text-center">Detalle Inversión</Title>
                    <Form layout="vertical">
                        <Row justify="start" gutter={[24, 24]}>
                            <Col span={24} className="center">
                                <Avatar src="/img/zeus_logo_sin_texto.svg" />
                            </Col>
                        </Row>
                        <Divider orientation="left" className="modal-divider">Hacienda de Inversión</Divider>
                        <Row justify="space-between" gutter={[8, 8]} >
                            <Row className="width-100">
                                <Col span={16} ><Text ellipsis strong> Hacienda</Text></Col>
                                <Col span={8} ><Text ellipsis > {inversion.hacienda_id.nombre}</Text></Col>
                            </Row>
                            <Row className="width-100">
                                <Col span={16} ><Text ellipsis strong> Descripción</Text></Col>
                                <Col span={8} ><Text ellipsis > {inversion.hacienda_id.descripcion}</Text></Col>
                            </Row>
                            <Row className="width-100">
                                <Col span={16} ><Text ellipsis strong> Plantas Compradas</Text></Col>
                                <Col span={8} ><Text ellipsis strong  >{inversion.plantas_compradas}</Text></Col>
                            </Row>

                        </Row>
                        <Divider orientation="left" className="modal-divider">Montos de Inversión</Divider>
                        <Row justify="space-between" gutter={[8, 8]} >
                            <Row className="width-100">
                                <Col span={20} ><Text ellipsis strong> Subtotal</Text></Col>
                                <Col span={4} className="text-wrap"><Text  > $ {(inversion.monto ? inversion.monto : 0).toMoney(true)}</Text></Col>
                            </Row>
                            <Row className="width-100">
                                <Col span={20} ><Text ellipsis strong> IVA</Text></Col>
                                <Col span={4} ><Text  > $ {(inversion.iva ? inversion.iva : 0).toMoney(true)}</Text></Col>
                            </Row>
                            <Row className="width-100">
                                <Col span={20} ><Text ellipsis strong> Total</Text></Col>
                                <Col span={4} className="text-wrap"><Text strong type="success" style={{ fontSize: 18 }}> $ {((inversion.monto) ? inversion.monto + (inversion.iva ? inversion.iva : 0) : 0).toMoney(true)}</Text></Col>
                            </Row>

                        </Row>

                        <Divider orientation="left" className="modal-divider">Transacciones</Divider>
                        <Row>
                            <Col xs={24}>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={transacciones}
                                    className="pd-1"
                                    header={
                                        <Row className="width-100 ">
                                            <Col span={8}>Monto</Col>
                                            <Col span={8}>Metodo Pago</Col>
                                            <Col span={8}>Fecha</Col>
                                        </Row>
                                    }
                                    renderItem={item => (
                                        <List.Item key={item._id}>
                                            <Row className="width-100">
                                                <Col span={8} ><Text  >${item.monto.toMoney(true)}</Text></Col>
                                                <Col span={8} ><Text ellipsis >{item.metodo_pago == 1 ? 'Transferencia' : 'Tarjeta'}</Text></Col>
                                                <Col span={8} ><Text ellipsis >{moment(item.fecha).format('YYYY-MM-DD')}</Text></Col>
                                            </Row>
                                        </List.Item>
                                    )}

                                />
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </>

        )
    }
}


/**
 *@function ModalInversionDetalle
 *@description 
 */
export default function (props) {

    let { visible, close } = props

    return (
        <Modal
            visible={visible}
            title={null}
            footer={null}
            maskClosable={true}
            destroyOnClose={true}
            closable={true}
            onCancel={close}
            onOk={close}
            width={700}
            className="modal-zeus"
        >
            <ModalInversionDetalle {...props} />
        </Modal>
    )
}