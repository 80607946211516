import React from 'react'
import { Route, Routes } from "react-router-dom";
import { useEffect } from 'react';

import Dashboard from '../../Components/Admin/CRM/Dashboard';
import Clientes from '../../Components/Admin/CRM/Clientes';
import DetalleCliente from '../../Components/Admin/CRM/DetalleCliente';
import DetalleInversion from '../../Components/Admin/CRM/DetalleInversion';
import ClientesList from '../../Components/Admin/CRM/ClientesList';
import Reventas from '../../Components/Admin/CRM/Reventas';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCRM(props) {

    useEffect(()=> {
        props.clearSearch()
    },[])

    return (
        <Routes>
            <Route path="" element={<Dashboard  {...props}  />} />
            <Route path="clientes" element={<Clientes  {...props}  />} />
            <Route path="clientes/listado" element={<ClientesList  {...props}  />} />
            <Route path="clientes/reventas" element={<Reventas  {...props}  />} />
            <Route path="clientes/detalle/:cliente_id" element={<DetalleCliente  {...props}/>} />
            <Route path="clientes/detalle/inversion/:inversion_id" element={<DetalleInversion  {...props}/>} />
        </Routes>
    )
}

export default RouterCRM;