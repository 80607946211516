import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, message, Tag, Space, Popconfirm, Popover } from 'antd';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import { RightOutlined, FilePdfOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from "react-router-dom";
import Decimal from "decimal.js";
import { IconBtnEdit, IconBtnDelete } from "../../../Widgets/Iconos";
import ModalReventa from "../../Reventa/ModalReventa";
import { QuestionCircleOutlined } from "@ant-design/icons";

//componentes
// import { renderEstatusInversion } from '../../Utils'


const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')

/**
 * @export
 * @class InversionDetalle
 * @extends {React.Component}
 */
class InversionDetalle extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			inversion_id: this.props.inversion_id,
			reventas: {
				page: 1,
				limit: 10,
				total: 0,
				data: []
			},

		}
	}

	componentDidMount() {
		this.getReventas()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.update !== this.props.update) {
			this.getReventas()
		}
	}

	/**
	* @method getTransaccion
	* @description Obtiene la lista de transacciones relacionadas a un inversion
	*/
	getReventas = ({
		page = this.state.reventas.page,
		limit = this.state.reventas.limit,
	} = this.state.reventas) => {

		this.setState({ loading_transaccion: true })

		axios.get('/customer/reventas', {
			params: {
				page,
				limit,
				inversion_id: this.props.params.inversion_id,
			}
		}).then(response => {
			console.log("response reventas", response.data);
			this.setState({
				reventas: {
					...this.state.reventas,
					data: response.data.data,
					page: response.data.page,
					total: response.data.total,
				}
			})

		}).catch(error => {
			if (error.response.status === 403)
				message.error(error.response.data.message)
			else
				message.error('Error al cargar las reventas')
		}).finally(() => this.setState({ loading_transaccion: false }))
	}

	render() {

		const { inversion } = this.state

		return (
			<>
				<List
					loading={this.state.loading}
					className="component-list"
					itemLayout="horizontal"
					locale={{ emptyText: "Sin reventas disponibles " }}

					dataSource={this.state.reventas.data}
					renderItem={item => (
						<List.Item className="component-list-item">
							<Card className="card-list hover" onClick={() => this.setState({ modalReventa: true, reventa: item._id })}>
								<Row className="width-100 ">
									<Col span={2} className="center">
										<Avatar src="/img/zeus_logo_sin_texto.svg" />
									</Col>
									<Col className="center" xs={4} md={7}>
										{moment(item.fecha).format('DD-MM-YYYY')}
										{/* <Text>Compra {item?.inversion_id?.cantidad} Planta(s)</Text> */}
									</Col>
									<Col className="center" xs={4} md={6}>
										<Popover
											overlayStyle={{
												//boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
											}}
											content={
												<>
													{item.cantidad ?? 0} plantas en total. <br />
													{item.cantidad_restante ?? 0} para reventa. <br />
													{item.cantidad_vendida ?? 0} plantas vendidas. <br />
												</>
											}>
											{item.cantidad_vendida} / {item.cantidad} planta{item.cantidad > 1 && 's'} <QuestionCircleOutlined style={{ fontSize: 16 }} />
										</Popover>
									</Col>
									<Col className="center" xs={18} md={3}>
										{item.precio_reventa.toMoney(true)} {item.moneda}
										{/* <Text>Compra {item?.inversion_id?.cantidad} Planta(s)</Text> */}
									</Col>
									<Col className="center" xs={18} md={3}>
										{Decimal(item.precio_reventa).mul(item.cantidad).toFixed(2).toMoney(true)} {item.moneda}
									</Col>
									<Col xs={24} md={3} className="center">
										<Space wrap>
											<Button
												type="primary"
												disabled={item.estatus === 2}
												className='btn-secondary center'
												icon={<IconBtnEdit />}
												onClick={() => {
													this.setState({ modal_revender: true, reventa: item._id })
												}}
											/>

											<Popconfirm
												placement="topRight"
												title="¿Deseas eliminar esta venta?"
												disabled={item.cantidad_vendida > 0 ? true : false}
												onConfirm={() => axios.delete('/reventa', { params: { id: item._id } }).then((response) => {
													message.success("Reventa eliminada correctamente")
													this.getReventas()
												})
													.catch((error) => {
														message.error("Hubo un error al eliminar la venta");
														this.getReventas();
													})
												}
												okText="Si"
												cancelText="No"
											>
												<Button type="primary" disabled={item.cantidad_vendida > 0 ? true : false} className='btn-delete center' danger title="Eliminar" icon={<IconBtnDelete />} />
											</Popconfirm>
										</Space>
									</Col>
								</Row>
							</Card>
						</List.Item>
					)}

				/>

				<ModalReventa
					visible={this.state.modal_revender}
					reventa={this.state.reventa}
					onClose={
						() => {
							this.getReventas()
							this.setState({ modal_revender: false, reventa: undefined })
						}
					}
				/>
			</>

		)
	}
}


export default function (props) {

	const params = useParams()
	const navigate = useNavigate()

	return <InversionDetalle  {...props} params={params} navigate={navigate} />
}