import React, { useState, useEffect } from 'react';
import { Select, message } from 'antd'



const { Option } = Select 
const axios = require("axios");

/**
 * @const SelectRol
 * @description Select para los roles registrados en el sistema
 */
const SelectRol = (props) => {

    const { value, onChange, placeholder = "Seleccione el rol", onSelect = () => {}, disabled = false, className = "" } = props

    const [ roles, setRoles ] = useState({
        data: [],
        page: 1,
        limit: 50,
        total: 0,
        search: null,
    })

    /**
     * @const getRoles
     * @description Obitiene los roles
     */
    const getRoles = ({page, limit, search} = roles) => {

        axios.get('/roles', {
            params: {
                page,
                limit,
                search,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(response => {
            setRoles({
            	data: response.data.data,
            	page: response.data.page,
            	total: response.data.total
            })

        }).catch(error => {
            message('Error al obtener los roles')
        })
    }

    //componentDidMount
    useEffect(() => {
        getRoles()
    }, [])

    return (
        <Select
            {...props}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            //showSearch
            //filterOption={false}
            //labelInValue
            //onSearch={(search)=> getRoles({search})}
            onSelect={(rol)=> {
                onChange(rol)
                onSelect(rol)
            }}
            value={value}
        >
            {
                roles.data.map(({ _id, nombre }) => <Option value={_id}>{nombre}</Option>)
            }
        </Select>
    );
}



export default SelectRol;