import React from 'react'
import { Route, Routes } from "react-router-dom";
import Reventa from '../../Components/Customer/Reventa/Reventa';

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterReventa(props) {

    return (
        <Routes>
            <Route  path="" element={<Reventa  />} />
        </Routes>
    )
}

 export default RouterReventa

