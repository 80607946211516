import { Carousel, Image } from "antd"
import axios from "axios"

import './Gallery.scss'
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons"
import { useState } from "react"
import { createRef } from "react"

const SlideButton = ({ image, onClick }) => {
    return <div className="slide-button">
        <img
            onClick={() => onClick()}
            className="slide-button-image"
            src={axios.defaults.baseURL + '/upload/' + (image?.filename ? image?.filename : 'default.png')}
        />
    </div>
}

const Gallery = ({ images, }) => {
    const galleryRef = createRef()

    return (
        <Image.PreviewGroup className="gallery">
            <Carousel
                // className=""
                // prefixCls="gallery"
                ref={galleryRef}
                dots={{
                    // className: 'gallery-dots'
                }}
            >
                {images?.map((item, index) => {


                    // return <div className="gallery-slide">
                        return <Image className="gallery-image" src={axios.defaults.baseURL + '/upload/' + (item?.filename ? item?.filename : 'default.png')} />
                    // </div>
                })}
            </Carousel>
        </Image.PreviewGroup>
    )
}

export default Gallery