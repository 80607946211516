import React, { Component, useState } from "react";
import { Row, Col, Button, Modal, Typography, Checkbox, Form, Card, InputNumber, Input, message, Spin, Radio, Space, Tooltip, } from "antd";

import axios from "axios";
import { LightenDarkenColor } from "lighten-darken-color";
import { IconAgabe, IconClock, IconPlace, IconAgabeColor, IconSolar, IconRoundMexicanFlag, IconRoundUsaFlag, } from "../../Widgets/Iconos";
import ModalFicha from "./ModalFicha";
import ModalTerminos from "./ModalTerminos";

import "../../../Styles/Global/modales.css";

import { User } from "../../../Hooks/Logged";

const { Title, Text, Paragraph, Link } = Typography;
const moment = require("moment");


/**
 *
 *
 * @class ModalHacienda
 * @extends {Component}
 */
class ModalHacienda extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hacienda: {
				color: "#BABABA",
				reventas: [],
			},
			loading: false,
			monto: 0,
			modal_visible: false,
			facturar: false,
			tipo_cambio: 20.24,
			moneda: null,
			contrato: {
				dias: "",
				mes: "",
				ano: "",
				ano_text: "",
				plantas: "",
				plantas_texto: "",
				precio_planta: "",
				precio_planta_text: "",
				precio_planta_decimales: "",
				total_dolares: "",
				total_dolares_text: "",
				tipo_cambio: "",
				tipo_cambio_text: "",
				tipo_cambio_decimales: "",
				total: "",
				total_text: "",
				total_decimales: "",
				folio_number: "",
				folio: "",
			},
			limite: 0,
			inversiones: 0,
			
		};
	}

	static contextType = User

	compraRef = React.createRef();

	componentDidMount() {
		this.getHacienda()
		this.getLimite()
		let moneda = "USD"//(this.context?.cliente?.pais_id?.nombre === "Mexico") ? "MXN" : "USD"
		this.setState({ moneda }, () => this.getUpdateValues())
		this.compraRef.current.setFieldsValue({ moneda })
	}

	getUpdateValues = (
		{
			plantas = 0,
			moneda = this.state.moneda
		} = {}
	) => {

		axios.get("/hacienda/contrato", {
			params: {
				hacienda_id: this.props.hacienda?._id,
				plantas,
				moneda
			}
		})
			.then(({ data }) => {
				// console.log("data", data);


				this.setState({
					contrato: data,
					tipo_cambio: parseFloat(data.tipo_cambio),
					precio_planta: data.precio_planta,
					monto: data.total,
					moneda: data.moneda,
				})
			})
			.catch(error => {
				// console.log("error", error)
			})
	};

	/**
	 * @method getHacienda
	 * @description Obtiene la informacion de la hacienda seleccionada
	 */
	getHacienda = () => {
		this.setState({ loading: true })
		axios.get('/hacienda', {
			params: {
				_id: this.props.hacienda?._id,
			}
		}).then(response => {
			this.setState({ hacienda: response.data.data })
		}).catch(error => {
			message.error('Error al cargar la Hacienda')
		}).finally(() => this.setState({ loading: false }))

	}


	/**
	 * @method getHacienda
	 * @description Obtiene la informacion de la hacienda seleccionada
	 */
	getLimite = () => {
		this.setState({ loading: true })
		axios.get('/limite').then(({ data }) => {
			this.setState({ limite: data.limite, inversiones: data.inversiones, })
		}).catch(error => {
			message.error('Error al cargar el limimte')
		}).finally(() => this.setState({ loading: false }))

	}

	/**
	 * @method onFinish
	 * @param {object} values - Objecto que contiene los valores del formulario
	 * @description Realiza la transaccion y movimiento para que el cliente compre las plantas, y retorna la referecia de pago
	 */
	onFinish = (values) => {
		this.setState({ loading: true });
		axios
			.post("/hacienda/compra", {
				...values,
				hacienda_id: this.props.hacienda?._id,
				moneda: this.state.moneda,
				monto: this.state.monto,
			})
			.then((response) => {
				this.props.showFicha({ ...response.data, moneda: this.state.moneda, monto: this.state.monto });
			})
			.catch((error) => {
				message.error("Error al realizar la compra");
			})
			.finally(() => {
				this.setState({ loading: false })
			})
	};



	/**
	 *
	 *
	 * @memberof ModalHacienda
	 * @descripcion Actualiza el state e Item del form para terminos y condiciones
	 * @param e Event
	 */
	onChangeTerminos = (e) => {
		this.setState({ terminos: e.target.checked });
		this.compraRef.current.setFieldsValue({
			terminos: e.target.checked,
		});
	};

	/**
	*
	*
	* @memberof ModalHacienda
	* @descripcion Actualiza el state e Item del form para terminos y condiciones
	* @param e Event
	*/
	onChangeFacturar = (e) => {
		this.setState({ facturar: e.target.checked });
		this.compraRef.current.setFieldsValue({
			facturar: e.target.checked,
		});
	};

	/**
	 *
	 *
	 * @memberof ModalHacienda
	 */
	verifyCodigo = (_, codigo) => {
		if (codigo === undefined || codigo === "") return Promise.resolve();

		return new Promise((resolve, reject) => {
			axios
				.get("/customer/codigo-promocional", {
					params: {
						codigo_promocional: codigo,
					},
				})
				.then((request) => resolve())
				.catch((error) => {
					reject(new Error("Código invalido"));
				});
		});
	};

	render() {
		const { hacienda } = this.state;
		return (
			<Spin
				spinning={this.state.loading}
				tip="Este proceso puede demorar. Por favor, sea paciente."
			>
				<Form layout="vertical" ref={this.compraRef} onFinish={this.onFinish} onValuesChange={(valuesChanged, allValues) => this.getUpdateValues(allValues)} className="ml-2 mr-2">
					<Card
						className="card-hacienda-modal"
						style={{ background: `linear-gradient(204.67deg, ${LightenDarkenColor(hacienda?.color, 50)} 9.43%, ${hacienda?.color} 84.49%)`, }}>
						<Row>
							<Col span={19}>
								<Title>Hacienda {hacienda?.nombre}</Title>
							</Col>
							<Col span={5} className="center">
								<IconAgabe />
							</Col>
						</Row>
					</Card>
					<Row>
						<Col span={24} align="center" className="mb-1">
							<div className="center">
								<IconClock />
								<Text style={{ marginLeft: '4px' }} className="text-cyan">Edad:</Text>
							</div>
							<Text>{moment(hacienda.fecha_creacion).fromNow()}</Text>
						</Col>
						<Col span={12} className="flex-column">
							<div className="center">
								<IconPlace />
								<Text style={{ marginLeft: '4px' }} className="text-cyan">Hectareas:</Text>
							</div>
							<Text>{hacienda?.hectareas}</Text>
						</Col >
						{hacienda.solares ? <Col span={12} className="flex-column">
							<div className="center">
								<IconSolar />
								<Text style={{ marginLeft: '4px' }} className="text-cyan">Solares:</Text>
							</div>
							<Text>{hacienda.solares}</Text>
						</Col> : null}
					</Row>
					<Row className="mt-1">
						<Col xs={24} >
							<Form.Item
								name="plantas"
								label={
									<div className="flex-left">
										<IconAgabeColor />
										<Text className="text-input">Cantidad de plantas:</Text>
									</div>
								}
								rules={(() => {


									let rules = [
										{
											required: true,
											message: "Ingresa la cantidad de plantas",
										},
	
										{
											validator: async (_, names) => {
												if (!names || names < 1 || (names % 1 != 0)) {
													return Promise.reject(new Error('Debe ingresar una cantidad válida'));
												}
											},
										},
	
									]

									if (this.state.inversiones < 1)

										rules.push(										{
											validator: async (_, names) => {
												if (names < 25) {
													return Promise.reject(new Error('Debe ingresar una cantidad mayor a 25 plantas en la primera compra'));
												}
											},
										})


									return rules;


								})()}

							>
								<InputNumber
									className="input-landing"
									addonAfter="plantas"
									max={Number(this.state.hacienda?.libres)}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} >
							<Form.Item
								name="moneda"
								label={<Text className="text-input">Moneda:</Text>}
								rules={[
									{
										required: true,
										message: "Ingresa la cantidad de plantas",
									},
								]}
							>
								<Radio.Group
									className="width-100"
									optionType="button"
									buttonStyle="solid"
								>
									<Radio.Button value="MXN" style={{ width: "50%", verticalAlign: "middle", height: 50 }}>
										<Space>
											<IconRoundMexicanFlag style={{ position: "relative", top: 5 }} />
											<Text style={{ color: "currentcolor" }}>Peso Mexicano</Text>
										</Space>
									</Radio.Button>
									<Radio.Button value="USD" style={{ width: "50%", verticalAlign: "middle", height: 50 }}>
										<Space>
											<IconRoundUsaFlag style={{ position: "relative", top: 5 }} />
											<Text style={{ color: "currentcolor" }}>Dolar Americano</Text></Space>
									</Radio.Button>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Text className="text-input">Precio por Planta</Text>
						</Col>
						<Col span={12} align="end">
							<Text className="text-result">$ {this.state?.precio_planta?.toMoney(true)} <small>{this.state.moneda}</small></Text>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Text className="text-input" style={{ fontSize: 24 }}>Total a Invertir</Text>
						</Col>
						<Col span={12} align="end">
							<Text className="text-result" style={{ fontSize: 24 }}>$ {this.state.monto?.toMoney(true)} <small>{this.state.moneda}</small></Text>
						</Col>
					</Row>
					<Row>
						<Col xs={24} >
							<Form.Item name="facturar" noStyle>
								<Checkbox
									style={{ marginTop: "2em" }}
									onChange={this.onChangeFacturar}
									value={this.state.facturar}
									checked={this.state.facturar} >¿Desea facturar?</Checkbox>
							</Form.Item>
						</Col>

						{this.state.facturar ? (
							<Col xs={24} >
								<Form.Item
									name="rfc_factura"
									label={
										<div className="flex-left">
											<IconAgabeColor />
											<Text className="text-input">RFC:</Text>
										</div>
									}
									rules={[
										{
											required: true,
											message: "Por favor, ingrese el RFC",
										},
										{
											pattern:
												/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
											message: "RFC invalido",
										},
									]}
								>
									<Input className="input-landing" />
								</Form.Item>
							</Col>
						) : null}
						<Col xs={24} >
							<Form.Item
								name="contrato"
								valuePropName="checked"
								rules={[
									{
										validator: async (_, value) => {
											if (!value || value != true) {
												return Promise.reject(new Error("Debe de aceptar los terminos y condiciones"));
											}
										},
									},
								]}
							>
								<Checkbox
									onClick={() => this.setState({ modal_visible: true })}
									style={{ marginTop: "1em" }}
								> Acepto los terminos y Condiciones</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Row className="mt-1">
						<Col xs={24} >
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									className="btn-submit large"
								>
									Realizar Compra
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<ModalTerminos
					visible={this.state.modal_visible}
					contrato={this.state.contrato}
					hacienda={hacienda}
					onClose={(value) => {
						this.setState({ modal_visible: false });
						this.compraRef.current.setFieldsValue({ contrato: false })
					}}
					onConfirm={(value) => {
						if (this.compraRef.current.getFieldValue("contrato") != true)
							this.compraRef.current.setFieldsValue({ contrato: true })
						this.setState({ modal_visible: false })
						// (value) => this.setState({ modal_visible: false, terminosAceptados: true })
					}}
				/>
			</Spin>
		);
	}
}

export default function (props) {
	const { visible = false, onClose = () => { } } = props;

	let [ficha, setFicha] = useState(null);

	/**
	 * @method showFicha
	 * @description cambia el state para mostrar las referencia de paga/transaccion y que se pueda descargar
	 */
	const showFicha = (data) => {
		setFicha(data);
	};

	/**
	 * @method onCancel
	 * @description cierra el modal y limipia el state
	 */
	const onCancel = () => {
		setFicha(null);
		onClose();
	};

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			title={null}
			footer={null}
			closable={false}
			destroyOnClose={true}
			zIndex={1000}
			className="modal-zeus"
		>
			<div className="center">
				<img src="/img/zeus_logo.svg" alt="logo Zeus" width="120" />
			</div>
			{ficha ? (
				<ModalFicha ficha={ficha} onCancel={onCancel} {...props} />
			) : (
				<ModalHacienda {...props} showFicha={showFicha} />
			)}
		</Modal>
	);
}
