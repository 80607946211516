import React, { Component } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Spin,
  Layout,
  Select,
  message,
  Card,
  Input,
  Form,
  Switch,
  TreeSelect,
  Divider,
  PageHeader,
} from "antd";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import "../../../Styles/Modules/Admin/Templates/Templates.scss";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class Proyectos
 * @extends {Component}
 * @description Vista donde se crean las automatizaciones
 */
class AutomatizacionesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      automatizacion_id: this.props.params._id,
      haciendas: {
        data: [],

        page: 1,
        limit: 10,

        total: 0,
        pages: 0,
      },
      correos: {
        data: [],

        page: 1,
        limit: 10,

        total: 0,
        pages: 0,
      },
      templates: {
        data: [],

        page: 1,
        limit: 10,

        search: "",
        total: 0,
        pages: 0,
      },

      hacienda: null,
      events: [],
      plantilla: false,
    };
  }

  componentDidMount() {
    this.getHaciendas();
    this.getEventos();
    this.getAcciones();
   this.getTemplates();

    this.formRef.setFieldsValue({
      activo: true,
    });

    if (this.state.automatizacion_id) {
      this.get();
    }
  }

  componentDidUpdate() {}

  editorRef = React.createRef();

  get = () => {
    this.setState({ loading: true });
    return axios
      .get("/automatizacion", {
        params: {
          _id: this.state.automatizacion_id,
        },
      })
      .then(({ data }) => {
        this.formRef.setFieldsValue({
          ...data,
          eventos: data.eventos,
          acciones: data.acciones.map(
            ({ email_template_id, email_id, ...e }) => ({
              ...e,
              email_template_id: email_template_id?._id
                ? {
                    value: email_template_id?._id,
                    key: email_template_id?._id,
                    label: email_template_id?.nombre,
                  }
                : null,
              email_id: email_id?._id
                ? {
                    value: email_id?._id,
                    key: email_id?._id,
                    label: email_id?.nombre,
                  }
                : null,
            })
          ),
          hacienda_id: Array.isArray(data.hacienda_id)
            ? data.hacienda_id.map((hacienda_id) =>
              hacienda_id?._id
                  ? {
                      value: hacienda_id?._id,
                      key: hacienda_id?._id,
                      label: hacienda_id?.nombre,
                    }
                  : null
              )
            : null,
        });
      })
      .catch((e) => {
        console.log("ASD", e);
      })
      .finally((e) => this.setState({ loading: false }));
  };

  getTemplates = (
    {
      page = this.state.templates.page,
      limit = this.state.templates.limit,
      search = this.state.templates.search,
    } = this.state.templates
  ) => {
    this.setState({ loading: true });
    return axios
      .get("/email-templates", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        console.log("data templates", data);
        this.setState({ templates: data });
      })
      .catch(({ response, ...args }) => {
        console.error({ ...response, ...args });
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof ModalRoles
   * @method getPermisos
   * @description Obtenemos los permisos actuales en el sistema
   */
  getEventos = () => {
    this.setState({ loading: true });
    axios
      .get(`/automatizaciones/eventos`)
      .then(({ data }) => {
        //Se formatean los eventos para que se muestren en el select tree

        this.setState({ events: this.formatArrayTreeSelect(data, true) });
      })
      .catch((error) => {
        console.log("eror", error);
        message.error("Error al traer las Acciones");
      })
      .finally(() => this.setState({ loading: false }));
  };

  /**
   * @memberof ModalRoles
   * @method getPermisos
   * @description Obtenemos los permisos actuales en el sistema
   */
  getAcciones = () => {
    this.setState({ loading: true });
    axios
      .get(`/automatizaciones/acciones`)
      .then(({ data }) => {
        //Se formatean los eventos para que se muestren en el select tree
        // let formatActions
        this.setState({ actions: this.formatArrayTreeSelect(data, true) });
      })
      .catch((error) => {
        console.log("eror", error);
        message.error("Error al traer los eventos");
      })
      .finally(() => this.setState({ loading: false }));
  };

  formatArrayTreeSelect = (list, parent = null) => {
    let listData = [];
    list.forEach((elem) => {
      let newElem = {
        value:
          parent && parent != true ? parent.name + "/" + elem.name : elem.name,
        label:
          parent && parent != true
            ? parent.title + " " + elem.title
            : elem.title,
        title: elem.title,
        selectable: !elem.types,
        // disabled: true
      };

      // if (elem.disabled == true){
      //     newElem.selectable = false
      //     newElem.disabled = true
      // }

      if (elem.types) {
        newElem.children = this.formatArrayTreeSelect(elem.types, elem);
      } else {
        newElem.tipo = elem.tipo;
        newElem.collection = elem.collection;
      }
      listData.push(newElem);
    });
    return listData;
  };

  getHaciendas = (
    {
      page = this.state.haciendas.page,
      limit = this.state.haciendas.limit,
      search = this.state.haciendas.search,
    } = this.state.haciendas
  ) => {
    this.setState({ loading: true });
    return axios
      .get("/haciendas", {
        params: {
          page,
          limit,
          search,
        },
      })
      .then(({ data }) => {
        data = data.data;
        this.setState({ haciendas :data});
      })
      .catch((e) => {
        console.log("ASD", e);
      })
      .finally((e) => this.setState({ loading: false }));
  };


  onFinish = (values) => {
    values = this.formatValues(values);
    if (this.state.automatizacion_id) {
      this.update(values);
    } else {
      this.add(values);
    }
  };

  formatValues = (values) => {
    values.correo_electronico_id =
      values.correo_electronico_id?.value || values.correo_electronico_id;
    values.acciones = values.acciones.map(
      ({ email_template_id, email_id, ...e }) => ({
        ...e,
        email_template_id: email_template_id?.value || email_template_id,
        email_id: email_id?.value || email_id,
      })
    );

    if(values.hacienda_id)
    values.hacienda_id = values.hacienda_id?.map(
      (hacienda_id) => hacienda_id?.value || hacienda_id
    );

    return values;
  };

  add = (values) => {
    this.setState({ loading: true });
    console.log("values", values);
    return axios
      .post("/automatizacion", values)
      .then((e) => {
        message.success("Guardado correctamente");
        if (!this.state.devolver) this.props.navigate(-1);
        else {
          this.state.automatizacion_id = this.props.params._id;
          this.formRef.resetFields();
        }
      })
      .catch(({ response, ...args }) => {
        console.error({ ...response, ...args });
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  update = (values) => {
    this.setState({ loading: true });
    console.log("values", values);
    axios
      .put("/automatizacion/" + this.props.params?._id, values)
      .then((e) => {
        message.success("Guardado correctamente");
        if (!this.state.devolver) this.props.navigate(-1);
        else {
          this.state.automatizacion_id = this.props.params._id;
          this.formRef.resetFields();
        }
        //     this.props.navigate('admin/settings/automatizaciones/crear')
      })
      .catch(({ response, ...args }) => {
        console.error({ ...response, ...args });
        if (response.data) message.error(response.data);
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    window.d = this.formRef;
    return (
      <>
        <Spin spinning={this.state.loading}>
          <PageHeader className="admin-page-header" title="Automatizaciones" />
          <Content className="admin-content content-bg pd-1">
            <Card style={{ padding: "12px 16px" }}>
              <Form
                layout="vertical"
                ref={(ref) => (this.formRef = ref)}
                onFinish={this.onFinish}
              >
                <Row style={{ width: "100%" }} gutter={[16, 12]}>
                  <Col sm={24} lg={12}>
                    <Form.Item
                      name="nombre"
                      label="Nombre de la automatización"
                      rules={[
                        {
                          max: 255,
                          message: "No puede ser mayor a 255 caracteres",
                        },
                        {
                          required: true,
                          message:
                            "Debe de indicar el nombre de la automatización",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item name="hacienda_id" label="Hacienda" >
                      <Select
                        placeholder="Todas las haciendas"
                        allowClear
                        showSearch
                        filterOption={false}
                        labelInValue
                        onSelect={(value, option) => {
                          this.setState(
                            { hacienda: value?.value || value },
                          );
                        }}
                        onSearch={(search) =>
                          this.getHaciendas({ search, page: 1 })
                        }
                        mode="multiple"
                      >
                        {this.state.haciendas?.data.map((e) => (
                          <Option value={e._id} key={e._id}>
                            {e.nombre}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="descripcion"
                      label="Descripcion de la automatización"
                      rules={[
                        {
                          required: true,
                          message:
                            "Debe de indicar una descripción breve de la automatización",
                        },
                      ]}
                    >
                      <Input.TextArea />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={24}>
                    <Form.Item
                      name="activo"
                      label="Activo"
                      valuePropName="checked"
                    >
                      <Switch defaultChecked />
                    </Form.Item>
                  </Col>
                  <Col span={24} align="center">
                    <Row gutter={[36, 36]}>
                      <Col sm={24} xl={6}>
                        <Row>
                          <Title
                            level={5}
                            style={{ width: "100%", textAlign: "start" }}
                          >
                            Eventos
                          </Title>
                          <Paragraph style={{ textAlign: "start" }}>
                            Situaciones que ejecutarán todas las acciones de la
                            siguiente lista
                          </Paragraph>
                        </Row>
                        <Form.List
                          name="eventos"
                          label="Eventos"
                          rules={[
                            {
                              validator: async (_, names) => {
                                if (!names || names.length < 1) {
                                  return Promise.reject(
                                    new Error(
                                      "Por favor seleccione al menos un evento"
                                    )
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item key={field.key} required={false}>
                                  <Row
                                    gutter={[8, 0]}
                                    align={"middle"}
                                    justify={"space-evenly"}
                                  >
                                    <Form.Item
                                      name={[field.name, "evento"]}
                                      validateTrigger={["onChange", "onBlur"]}
                                      rules={[
                                        {
                                          required: true,
                                          whitespace: true,
                                          message:
                                            "Debe de seleccionar un evento",
                                        },
                                      ]}
                                      noStyle
                                    >
                                      <TreeSelect
                                        style={{ flex: 1 }}
                                        placeholder="Eventos"
                                        allowClear
                                        treeData={this.state.events}
                                        treeNodeLabelProp="label"
                                        onSelect={(value, option) => {}}
                                      />
                                    </Form.Item>
                                    <Form.Item noStyle name="tipo">
                                      <input hidden />
                                    </Form.Item>
                                    <Form.Item noStyle name="colleccion">
                                      <input hidden />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                      <MinusCircleOutlined
                                        style={{ margin: "0 8px" }}
                                        onClick={() => remove(field.name)}
                                      />
                                    ) : null}
                                  </Row>
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                >
                                  Agregar evento
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Col>
                      <Col
                        span={1}
                        style={{
                          display: "flex",
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <Divider type="vertical" style={{ flex: 1 }} />
                      </Col>
                      <Col sm={24} xl={17}>
                        <Row
                          style={{
                            flexDirection: "column",
                            textAlign: "start",
                          }}
                        >
                          <Title level={5}>Acciones</Title>
                          <Paragraph style={{ textAlign: "start" }}>
                            Cualquiera de los eventos ejecutaran todas las
                            acciones enlistadas:{" "}
                          </Paragraph>
                        </Row>
                        <Form.List
                          name="acciones"
                          label="Acciones"
                          rules={[
                            {
                              validator: async (_, names) => {
                                if (!names || names.length < 1) {
                                  return Promise.reject(
                                    new Error(
                                      "Por favor seleccione al menos un evento"
                                    )
                                  );
                                }
                              },
                            },
                          ]}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <Form.Item key={field.key} required={false}>
                                  <Form.Item
                                    name={[field.name, "collection"]}
                                    noStyle
                                  >
                                    <input type="hidden" />
                                  </Form.Item>
                                  <Form.Item
                                    name={[field.name, "tipo"]}
                                    noStyle
                                  >
                                    <input type="hidden" />
                                  </Form.Item>
                                  <Row
                                    gutter={[8, 0]}
                                    justify={"space-between"}
                                  >
                                    <Col span={12}>
                                      <Form.Item
                                        name={[field.name, "accion"]}
                                        validateTrigger={["onChange", "onBlur"]}
                                        rules={[
                                          {
                                            required: true,
                                            whitespace: true,
                                            message:
                                              "Debe de seleccionar una acción",
                                          },
                                        ]}
                                        noStyle
                                      >
                                        <TreeSelect
                                          dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: "auto",
                                          }}
                                          placeholder="Acción a ejecutar (Enviar correo a...)"
                                          allowClear
                                          treeData={this.state.actions}
                                          treeNodeLabelProp="label"
                                          onSelect={(value, option) => {
                                            const values =
                                              this.formRef.getFieldsValue();
                                            values.acciones[
                                              field.name
                                            ].collection = option.collection;
                                            values.acciones[field.name].tipo =
                                              option.tipo;
                                            this.formRef.setFieldsValue(values);
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                          prevValues.acciones[field.name]
                                            ?.accion !==
                                          curValues.acciones[field.name]?.accion
                                        }
                                      >
                                        {({
                                          getFieldsValue,
                                          getFieldValue,
                                        }) => {
                                          let values = getFieldsValue();
                                          let tipo =
                                            values?.acciones[field.name]?.tipo;

                                          if (tipo == "email")
                                            return (
                                              <Row gutter={[8, 0]}>
                                                <Col span={12}>
                                                  <Form.Item
                                                    name={[
                                                      field.name,
                                                      "email_template_id",
                                                    ]}
                                                    validateTrigger={[
                                                      "onChange",
                                                      "onBlur",
                                                    ]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message:
                                                          "Debe de seleccionar la plantilla",
                                                      },
                                                    ]}
                                                    noStyle
                                                  >
                                                    <Select
                                                      allowClear
                                                      showSearch
                                                      filterOption={false}
                                                      labelInValue
                                                      onSearch={(search) =>
                                                        this.getTemplates({
                                                          search,
                                                          page: 1,
                                                        })
                                                      }
                                                      placeholder="Plantilla de Correo a utilizar"
                                                    >
                                                      {this.state.templates.data.map(
                                                        (e) => (
                                                          <Option
                                                            value={e._id}
                                                            key={e._id}
                                                          >
                                                            {e.nombre}
                                                          </Option>
                                                        )
                                                      )}
                                                    </Select>
                                                  </Form.Item>
                                                </Col>
                                              </Row>
                                            );

                                            if(tipo == "notificaciones")
                                              return <Row gutter={[8, 0]}>
                                            <Col span={12}>
                                              <Form.Item
                                                name={[
                                                  field.name,
                                                  "titulo",
                                                ]}
                                                validateTrigger={[
                                                  "onChange",
                                                  "onBlur",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Debe escribir un mensaje",
                                                  },
                                                ]}
                                                noStyle
                                              >
                                            <Input placeholder="Titulo" className="width-100">
                                            </Input>
                                              </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                              <Form.Item
                                                name={[
                                                  field.name,
                                                  "notificacion",
                                                ]}
                                                validateTrigger={[
                                                  "onChange",
                                                  "onBlur",
                                                ]}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Debe escribir un mensaje",
                                                  },
                                                ]}
                                                noStyle
                                              >
                                            <Input.TextArea placeholder="Mensaje" className="width-100">
                                            </Input.TextArea>
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                        }}
                                      </Form.Item>
                                    </Col>
                                    {fields.length > 1 ? (
                                      <Col span={1}>
                                        <MinusCircleOutlined
                                          style={{ margin: "0 8px" }}
                                          onClick={() => remove(field.name)}
                                        />
                                      </Col>
                                    ) : null}
                                  </Row>
                                </Form.Item>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                >
                                  Agregar acción
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24} align="center">  
                    <Row gutter={[5,5]} className="width-100"  align="center" >
                      <Button
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        style={{ maxWidth: 400 }}
                      >
                        Guardar
                      </Button>
                      <Button
                        block
                        size="large"
                        type="primary"
                        htmlType="submit"
                        style={{ maxWidth: 400 }}
                        onClick={() => {
                          this.state.devolver = true;
                        }}
                      >
                        Guardar y Crear Nuevo
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Content>
        </Spin>
      </>
    );
  }
}

export default function TemplateFormWrapper(props) {
  return (
    <AutomatizacionesForm
      {...props}
      params={useParams()}
      navigate={useNavigate()}
    />
  );
}
