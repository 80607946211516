import React, { Component, useContext } from 'react';
import axios from 'axios';
import ifIsImage from 'if-is-image';
import { FaPaperclip, FaTimes, FaFileAlt, FaFilePdf, FaImage } from 'react-icons/fa';
import SocketIOFileClient from 'socketio-file-upload';
import { Layout, Card, Avatar, Button, Col, Row, Comment, Input, Typography, Tooltip, Modal, Upload, Progress, Image } from 'antd'

//componentes
import SocketContext from '../../../Hooks/Socket';
import CustomAvatar from '../../Widgets/Avatar/Avatar';
import MentionInput from '../../Widgets/Inputs/MentionInput';
import { DeleteOutlined } from '@ant-design/icons';

const moment = require("moment");

const { Title, Text } = Typography;
const { Content } = Layout;

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

/**
 *
 * @class ChatCliente
 * @extends {Component}
 * 
 * @description Componente para el chat de bitacora de los clientes
 */
class ChatCliente extends Component {

    constructor(props) {
        super(props)
        this.state = {
            mensajes: { 
                data:[],
                page: 1,
                limit: 6,
                value: '',
               
            },
            remitente_id: null,
            filesArray: [],
            filesDictionary: {},
        }
    }

    inputRef = React.createRef()

    scrollToBottomOption = true
    loading = false

    static contextType = SocketContext;

    /***
     * @methodOf ChatCliente
     * @function componentDidMount
     * 
     * @description Iniciamos el chat, si se agrega al usuario a la lista de sockets exitomsanete, se encadena los emits iniciales
     */
    componentDidMount() {

        // this.context.emit("cliente_start")
        // this.context.removeListener('successful');
        // this.context.on('successful', this.IO_start)

        //Metodos para subir archivos
        this.uploader = new SocketIOFileClient(this.context);
        this.uploader.addEventListener("start", this.startUpload);
        this.uploader.addEventListener("progress", this.progressUpload);
        this.uploader.addEventListener("complete", this.completeUpload);


        const { page, limit } = this.state;

        // this.setState({ remitente_id: user_id })

        //Entramos al room del cliente
        this.context.emit('cliente_enter_room', { cliente_id: this.props.cliente_id })

        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('mensaje_nuevo');

        //Se declaran de nuevo
        this.context.on('mensaje_nuevo', this.IO_mensaje_nuevo)

        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('delete_message');

        //Se declaran de nuevo
        this.context.on('delete_message', this.IO_delete_mensaje)

        //Obetenemos los mensajes relacionado al cliente
        this.getMensajes({page: 1})

    }

    componentDidUpdate(){
        
        document.getElementById("messages-body").addEventListener('scroll', this.handleScroll, true);
    }

    /***
     *
     * @methodOf ChatCliente
     * @function IO_start
     * 
     * @description Inicio del socket, obtebemos los mensajes, 
     * nos regresa el usuario logeado y lo ponemos el state
     */
    IO_start = ({ user_id }) => {

    }


    /***
     *
     * @methodOf ChatCliente
     * @function getMensajes
     * 
     * @description Obtiene los mensajes relacionados al cliente
     */
    getMensajes = ({
        page = this.state.mensajes.page,
        limit = this.state.mensajes.limit
    } = this.state.mensajes) => {

        axios.get('/mensajes',{
            params: {
                page,
                limit,
                cliente_id: this.props.cliente_id
            }
        }).then(response => {
            let mensajesList = this.state.mensajes.data;
            mensajesList.unshift(...response.data.data)
            this.setState(state => {
                state.mensajes.data = mensajesList;
                state.mensajes.page = response.data.page;
                state.mensajes.limit = response.data.limit;

                //Se hace scroll hasta abajo
                if (this.scrollToBottomOption === true) {
                    setTimeout(this.scrollToBottom, 250)
                }

                return state;
            })
            
        }).catch(error => {
            console.log("error", error);

        })

    }

    /**
     *
     * @methodOf ChatCliente
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje }) => {
        let data = this.state.mensajes.data
        data.push(mensaje)
        this.setState({ 
            mensajes:{
                ...this.state.mensajes,
                data
            }
        })

        //Se hace scroll hasta abajo
        if (this.scrollToBottomOption === true) {
            setTimeout(this.scrollToBottom, 250)
        }
    }

    IO_delete_mensaje = (mensaje_id) => {
        let { data } = this.state.mensajes

        data = data.filter(mensaje => mensaje._id !== mensaje_id)
        this.setState({ mensajes: {...this.state.mensajes, data}})
    }

    /**
     *
     * @methodOf ChatCliente
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar ENTER, enviamos el mensaje y limpiamos el input
     * 
     * */
    onKeyDownPress = (event) => {

        if (event.key === 'Enter' && ((event.target.value && event.target.value !== null && event.target.value !== "") || this.state.filesArray.length > 0)) {
            this.context.emit('cliente_new_message', {
                message: event.target.value,
                remitente_id: this.state.remitente_id,
                cliente_id: this.props.cliente_id,
                files: this.state.filesArray.map(event => event.fileSend.name),
                mentions: this.state.mentions
            })
            this.setState({ filesArray: [], filesDictionary: {},value: '' })
            this.inputRef.current.clean()
        }
    }

    /**
     *
     * @methodOf ChatCliente
     * @function onPress
     * 
     * @description Se ejecuta al presionar el boton de enviar, enviamos el mensaje y limpiamos el input
     * 
     * */
    onPress = (event) => {
        if ((event.target.value && event.target.value !== null && event.target.value !== "") || this.state.filesArray.length > 0) {
            this.context.emit('cliente_new_message', {
                message: event.target.value,
                remitente_id: this.state.remitente_id,
                cliente_id: this.props.cliente_id,
                files: this.state.filesArray.map(event => event.fileSend.name),
                mentions: this.state.mentions
            })
            this.setState({ filesArray: [], filesDictionary: {},value: '' })
            this.inputRef.current.clean()
        }
    }

     /**
     *
     * @methodOf ChatCliente
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el chat hasta, si es hasta arriba carga los mensajes antiguos 
     * 
     * */
    handleScroll = (event) => {
        let { scrollTop, scrollHeight, clientHeight } = document.getElementById("messages-body");
        let { page, limit, search } = this.state.mensajes;
        if (scrollTop === 0) {
            this.scrollToBottomOption = false;
            page = page + 1
            this.getMensajes({page, search, limit})
        }else if (scrollHeight - scrollTop != clientHeight){
            this.scrollToBottomOption = false;
        }

        if (scrollHeight - scrollTop === clientHeight){
            this.scrollToBottomOption = true;
        }
    }

    /**
     *
     * @methodOf ChatCliente
     * @function scrollToBottom
     * 
     * @description Scrolleamos el chat al final de forma automatica
     * 
     * */
    scrollToBottom = () => {
        this.scrollToBottomOption = true;
        const messages_body = document.getElementById("messages-body");
        if (messages_body !== null && messages_body !== undefined && messages_body !== null)
            messages_body.scrollTop = messages_body.scrollHeight;
    }


    /**
     *
     * @methodOf ChatCliente
     * @function uploadFile
     * 
     * @description Sube los archivos al servidor
     * */
    uploadFile = ({ file }) => {
        let fileSplit = file.name.split(".");
        let rename = "";
        for (let index = 0; index < (fileSplit.length - 1); index++)
            rename += fileSplit[index]
        let fileName = rename + "_" + Math.random().toString(36).substring(2, 10) + "." + fileSplit[fileSplit.length - 1];
        this.uploader.submitFiles([renameFile(file, fileName)])
    }

    progressUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = (parseInt(event.bytesLoaded) * 100) / parseInt(event.file.size);
        this.state.filesArray[index - 1].status = "progress";
        this.setState({ update: !this.state.update })
    }

    completeUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = 100;
        this.state.filesArray[index - 1].status = "complete";
        this.state.filesArray[index - 1].fileSend = event.file;
        this.setState({ update: !this.state.update })
    }

    startUpload = (event) => {

        let name = "";
        let fileSplit = event.file.name.split("_");
        for (let index = 0; index < (fileSplit.length - 1); index++) {
            name += ((index == 0) ? "" : '_') + fileSplit[index];
        }
        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


        let index = this.state.filesArray.push({
            file: event.file,
            progress: 0,
            status: 'start',
            name,
            extension
        });

        this.state.filesDictionary[event.file.name] = index;
        this.setState({ update: !this.state.update })
    }

    deleteFile = (file) => {
        let index = this.state.filesDictionary[file];
        this.state.filesArray.splice(index, 1);
        this.context.emit("delete_file", file)
        this.setState({ update: !this.state.update })
    }

    mensajeDic = {}

    render() {

        let { mensajes } = this.state;

        return (
            <Content className="chat-content">
                <Title level={4} className="text-update-title">  Actualizaciones </Title>

                <div className="col-divider">
                    <Content className="mensajes-content" id="messages-body">

                        { mensajes.data.map((comment, index) => {

                            this.mensajeDic[comment._id] = index
                            if(comment.actualizacion){
                                return <Row className="">
                                    <Col span={24} className="center">
                                        <Text>{'actualizo'} el {moment().format('DD-MM-YYYY HH:mm')}</Text>
                                    </Col>
                                </Row>

                            }

                            return <Row>
                                <Col span={4} className="center">
                                    <CustomAvatar
                                        name="Pendiente"
                                    />
                                </Col>
                                <Col span={20}>
                                    <Card className="mensaje-card">
                                        <Button 
                                            onClick={() => {
                                                this.context.emit('delete_message', comment._id)
                                            }}
                                            danger size='small' type='primary' icon={<DeleteOutlined />} style={{ position: "absolute", right: 10, bottom: 10 }}></Button>
                                        <Row>
                                            <Col span={12}>
                                                <Text className="mensaje-text"><strong>{comment.remitente_id?.nombres}</strong> ha escrito:</Text>
                                            </Col>
                                            <Col span={12} className="flex-right">
                                                <Text className="mensaje-text">{moment(comment.createdAt).format('DD-MM-YYYY HH:mm')}</Text>
                                            </Col>
                                            <Col span={24}>
                                                <Text className="mensaje-text">{comment.mensaje}</Text><br/>
                                                    {comment.archivos.map(filename => {
                                                        let name = "";
                                                        let fileSplit = filename.split("_");
                                                        for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                            name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                        }
                                                        let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                        let extensionImage = null;
                                                        if (ifIsImage(filename)) {
                                                            extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                        }
                                                        else if (extension == "pdf" || extension == "PDF") {
                                                            extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                        }
                                                        else {
                                                            extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                        }


                                                        if (ifIsImage(`${name}.${extension}`))
                                                            return <a className="archivito">
                                                                <Image src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '100px', margin: '1em' }} />
                                                            </a>
                                                        else
                                                            return <>
                                                                <br />
                                                                <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                    {extensionImage}{`${name}.${extension}`}
                                                                </a>
                                                            </>
                                                    })}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                        }) } {/*END MAP MENSAJES*/}

                    </Content>
                    <div className={this.state.filesArray.length > 0 ? "filesList" : null}>

                        {this.state.filesArray.map(file => {

                            let extensionImage = null;
                            if (ifIsImage(file.file.name)) {
                                extensionImage = <FaImage />
                            }
                            else if (file.extension == "pdf" || file.extension == "PDF") {
                                extensionImage = <FaFilePdf />
                            }
                            else {
                                extensionImage = <FaFileAlt />
                            }

                            let name = (file.name.length > 10) ? (file.name.slice(0, 10) + '... ') : file.name;
                            return <div className="file-container">
                                <div className="file">
                                    {(file.status != "complete") ? <Progress percent={parseInt(file.progress)} size="small" style={{
                                        position: 'absolute',
                                        top: '36%',
                                        left: '5px',
                                        width: 'calc(100% - 10px)',
                                    }} /> : extensionImage}
                                    <Button onClick={() => this.deleteFile(file.file.name)} className="buttom-close"><FaTimes /></Button>
                                </div>
                                <span style={{
                                    display: 'block',
                                    margin: '0 auto',
                                    textAlign: ' center',
                                    left: 0,
                                    color: '#7b7373',
                                    fontSize: 12

                                }}>{name + '.' + file.extension}</span>
                            </div>
                        })}
                    </div>

                    <div className="chat-content-opciones">                    
                        <MentionInput
                            ref={this.inputRef}
                            updateText={({text, mentions})=>this.setState({value: text, mentions})}
                            onKeyDownPress={this.onKeyDownPress}
                        />
                        

                        <Row style={{marginTop: '12px'}}>
                            <Col span={12} className="flex-left">
                                <Upload
                                    className="btn-attachment"
                                    multiple={true}
                                    showUploadList={false}
                                    customRequest={this.uploadFile}

                                >
                                    <FaPaperclip />
                                </Upload>
                                
                            </Col>
                            <Col span={12} className="flex-right">
                                <Button 
                                    title="Enviar" 
                                    className="btn-send"
                                    onClick={() =>this.onPress({ target: { value: this.state.value } })}>
                                    ENVIAR
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        );
    }
}

export default function (props) { return <ChatCliente {...props} /> }


