import React, { useContext } from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Form, Input, message, } from 'antd';
import { List, Avatar } from 'antd';
import ReactECharts from 'echarts-for-react';
import axios from 'axios'
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { QuestionCircleOutlined } from "@ant-design/icons";
import Socket from "../../Hooks/Socket";
import User from "../../Hooks/Logged";
import MessagesList from "./MessageList";

import '../../Styles/Modules/Customer/soporte.scss'
import { values } from "lodash";

const { Content } = Layout;
const { Title, Text } = Typography;
const moment = require('moment')

class Soporte extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            conversacion: {
                page: 1,
                limit: 20,
                data: []
            },
            can_reply: false,
            visible: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        console.log('iniciando chat', this.props.id)

        if (this.props.id) {
            this.IO_connect(this.props.id)
            this.props.socket.on('connect', this.IO_connect)
        }


        this.props.socket.on('error', this.IO_error)
        this.props.socket.on('new_message', this.IO_newMessage)


        this.props.socket.on('mb_successful', (data) => console.log('conexion exitosa con el id', data))

        this.props.socket.on('sucessful', (data) => this.IO_loadMessages(data))
    }

    formReply = React.createRef()

    toggleVisible = () => {
        this.setState({ visible: !this.state.visible })
    }


    componentWillUnmount() {

        if (this.props.id) {
            this.props.socket.emit('/admin/cliente/leave', this.props.id)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            if (this.props.id) {
                this.IO_connect(this.props.id)
            }
        }
    }

    IO_connect = (id) => {
        console.log('conectando a socket ora si', id)
        this.props.socket.emit('/admin/cliente/join', id)

    }

    IO_error = (message) => {
        message.error(message)
        
    }

    submit = (values) => {
        if (!this.props.id) {
            message.error('Hubo un error al enviar la consulta')
        }

        if (values.content && values.content.length > 0) {
            this.formReply.current.resetFields()
             
            this.props.socket.emit('/admin/cliente/message/add', {
                id: this.props.id,
                entrada: values.content,
                usuario: this.props.user._id,
                cliente_id: this.props.id,
            })
        }
    }

    IO_loadMessages = (res => {

        console.log("res", res)

        this.setState({
            loading: true,
        })

        let old = this.state.conversacion
        let data = (res.page == 1) ? res.data : [...res.data, ...old.data]

        let new_conversacion = {
            data: data,
            page: res.page,
            limit: res.limit,
            total: res.total
        }

        this.setState({
            conversacion: new_conversacion,
            loading: false,
            push: false
        })
    })

    IO_newMessage = (data) => {
        console.log("new message", data)
        let mensajes = this.state.conversacion.data
        mensajes.push(data)
        this.setState({
            mensajes: {
                ...this.state.conversacion,
                data: mensajes
            }
        })
    }

    

    render() {

        return (
            <>

                {/* <Widget
                    title="Soporte"
                    subtitle=""
                    senderPlaceHolder="Escriba su consulta..."
                    profileAvatar={"/android-chrome-144x144.png"}
                /> */}
                <Button
                    id="xxdd"
                    type="primary"
                    style={{
                        position: "fixed",
                        bottom: '0px',
                        left: '0px',
                        zIndex: 100,
                        borderTopRightRadius: '100px',
                    }}
                    onClick={this.toggleVisible}
                    icon={<QuestionCircleOutlined style={{ color: "currentcolor", fontSize: 28, position: "relative", right: 3, top: 3 }} />}
                >

                </Button>
                <div className={`soporte ${this.state.visible ? 'visible' : 'oculto'}`} >

                    <div span={24} className="titulo">
                        CHAT SOPORTE
                    </div>
                    <div className="chat-container width-100">
                        <div className="message-box">
                            <MessagesList
                                data={this.state.conversacion?.data}

                            />
                        </div>
                        <div className="bottom-content">
                            <Form
                                layout="inline"
                                className="width-100 pd-1 "
                                onFinish={this.submit}
                                ref={this.formReply}>


                                <Col xs={15} md={19} >

                                    <Form.Item name='content'>
                                        <Input placeholder="Escribe tu mensaje aqui..." className="width-100" style={{ marginTop: 6 }} />
                                    </Form.Item>


                                </Col>
                                <Col xs={5} md={5}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" style={{ marginTop: '5px' }}>
                                            Enviar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Form>
                        </div>

                    </div>

                </div>
            </>

        )
    }
}


export default function SoporteWrapper(props) {

    const navigate = useNavigate();
    const location = useLocation();
    const socket = useContext(Socket);
    const user = useContext(User)

    console.log("useHistory", navigate)
    console.log("useLocation", location)
    console.log("useSocket", socket)
    console.log("useUser", user)

    return <Soporte
        {...props}
        id={user?.cliente?._id}
        navigate={navigate}
        location={location}
        socket={socket}
        user={user}
    />

}