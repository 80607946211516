import React from "react";
import { Layout, PageHeader, Row, Col, Button, Typography, Card, Avatar, List, Spin, Space, Popconfirm, message } from 'antd';
import axios from 'axios';
import { EditOutlined, DeleteOutlined, } from "@ant-design/icons";
import FloatingButton from '../../Widgets/FloatingButton/FloatingButton'
import ModalCuenta from './ModalCuentas'


const { Content } = Layout;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class Cuentas
 * @extends {React.Component}
 */
export default class Cuentas extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			cuentas: [],

			page: 1,
			total: 0,
			limit: 10
		}
	}

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getCuentas();
	}

	/**
	* @method getCuentas
	* @description Obtiene las Cuentas del usuario
	*/
	getCuentas = (page = this.state.page) => {
		this.setState({ loading: true })
		axios.get('/cuentas', {
			params: {
				page: page,
			}
		}).then(response => {
			
			this.setState({
				cuentas: response.data.data.itemsList,
				page: response.data.data.currentPage,
				total: response.data.data.itemCount,
			})

		}).catch(error => {
			
			message.error('Ocurrio un error al cargar datos')

		}).finally(() => this.setState({ loading: false }))
	}

	render() {

		return (
			<>
				<Spin spinning={false}>
					<Content className="hm-layout-content layout-dashboard" >
						<PageHeader className="site-page-header" ghost={false} title="Cuentas">
						</PageHeader>
						<List
							loading={this.state.loading}
							className="component-list"
							itemLayout="horizontal"
							dataSource={this.state.cuentas}
							locale={{ emptyText: "Sin Cuentas" }}
							pagination={{
								current: this.state.page,
								pageSize: 10,
								total: this.state.total,
								position: 'bottom',
								className: "flex-left",
								onChange: (page) => this.getCuentas(page)
							}}
							header={<Row className=" width-100 pl-1 pr-1 ">
								<Col span={2} className="center">
								</Col>
								<Col xs={{span: 10, offset:2}} md={{span:6, offset:0}} className="center">
									Nombre
								</Col>
								<Col xs={{span: 10, offset:0}} md={{span:6, offset:0}} className="center">
									Banco
								</Col>
								<Col xs={{span: 10, offset:4}} md={{span:7, offset:0}} className="center">
									Cuenta
								</Col>
							</Row>
							}
							renderItem={item => (
								<List.Item className="component-list-item">
									<Card className="card-list hover" onClick={() => this.setState({ modalHacienda: true, transaccion: item })}>
										<Row className="width-100 ">
											<Col span={2}>
												<Avatar src="/img/zeus_logo_sin_texto.svg" />
											</Col>
											<Col className="center" xs={{span: 10, offset:2}} md={{span:6, offset:0}}>
												<Text>{item.nombre}</Text>
											</Col>
											<Col className="center" xs={{span: 10, offset:0}} md={{span:6, offset:0}}>
												<Text>{item.banco}</Text>
											</Col>
											<Col className="center" xs={{span: 10, offset:4}} md={{span:7, offset:0}}>
												<Text>{item.cuenta}</Text>
											</Col>
											<Col xs={{span: 10, offset:0}} md={{span:3, offset:0}} className="center">
												<Space direction="horizontal">
													<Button
														icon={<EditOutlined style={{ color: "currentcolor" }} />}
														type="primary"
														title="Editar"
														onClick={() => this.setState({ modalCuentas: true, cuenta: item._id })}>
													</Button>
													<Popconfirm
														placement="topRight"
														title="¿Deseas eliminar esta Cuenta?"
														onConfirm={() => axios.delete('/cuenta/delete', { params: { _id: item._id } }).then((response) => {
															message.success('Cuenta Eliminada')
															this.getCuentas()
														}).catch((error) => {
															message.error('Cuenta no eliminada')
															this.getCuentas();
														})
														}
														okText="Si"
														cancelText="No"
													>
														<Button
															type="primary"
															danger
															icon={<DeleteOutlined style={{ color: "currentcolor" }} />} title="Eliminar"
														/>
													</Popconfirm>
												</Space>
											</Col>
										</Row>
									</Card>
								</List.Item>
							)}
						/>
					</Content>
					<FloatingButton title="Nuevo Registro" onClick={() => this.setState({ modalCuentas: true, cuenta: undefined })} />

				</Spin>
				<ModalCuenta
					visible={this.state.modalCuentas}
					onCancel={() => {
						this.setState({ modalCuentas: false, cuenta: undefined })
						this.getCuentas(this.state.page)
					}}
					cuenta={this.state.cuenta}
				/>
			</>
		)
	}
}