import React, { Component } from 'react';
import { Row, Col, PageHeader, Layout, Card, Typography, List, Space, Modal, Result } from 'antd'
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { IconWallet, IconGraphic} from './../../Widgets/Iconos'
import { CardStatistics } from './../../Widgets/Cards'
import { useNavigate, useSearchParams } from 'react-router-dom';

import '../../../Styles/Modules/Admin/CRM.scss'
import CustomAvatar from '../../Widgets/Avatar/Avatar';

const { Content } = Layout;
const { Text } = Typography

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            clientes: [],
            ventas: 0,
            margen_ventas: 0,
            pagado: 0,
            margen_pagado: 0,
            haciendas: [],
            years: [],
            vendedores: []
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getDashboard()
    }

    /**
     *
     *
     * @memberof Usuarios
     * @method getDashboard
     * @description Obtiene los datos necesarios para la analitica
     */
    getDashboard = () => {
        axios.get('/crm')
            .then(({ data }) => {
                this.setState({...data.data})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        let haciendaChartOption = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data: this.state.years.map(y => `${y}`)
            },
            xAxis: [
              {
                type: 'category',
                axisTick: { show: false },
                data: this.state.haciendas.map(h => h.hacienda?.nombre)
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: this.state.years.map(y => ( {
                name: `${y}`,
                type: 'bar',
                barGap: 0,
                data: this.state.haciendas.map(h => h[`${y}`])
            }))
          }

        let ventasChartOption = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
              {
                name: 'Ventas por Vendedor',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                labelLine: {
                  show: false
                },
                // data: [
                //   { value: 1048, name: 'V1' },
                //   { value: 735, name: 'V2' },
                //   { value: 580, name: 'V3' },
                //   { value: 484, name: 'V4' },
                //   { value: 300, name: 'V5' }
                // ] 
                data: this.state.vendedores.map(v => ({
                    value: v.monto, name: v.vendedor?.nombre
                }))
              }
            ]
          }

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Agave"
                />
                <Content className="admin-content  crm content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={16}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={24} md={12} className="grid">
                                            <CardStatistics 
                                                title="Ventas Totales" 
                                                suffix={"MXN"} 
                                                color={"#00CD98"}
                                                onClick={() => this.props.navigate('/admin/inversiones')}
                                                monto={this.state.ventas.toMoney ? this.state.ventas.toMoney(true) : 0} 
                                                icon={(color) => <IconWallet color={color} />} 
                                                percent={this.state.margen_ventas}
                                            />
                                        </Col>
                                        <Col xs={24} md={12} className="grid">
                                            <CardStatistics 
                                                title="Total Pagado" 
                                                suffix={"MXN"} 
                                                color={"#0047FF"}
                                                onClick={() => this.props.navigate('/admin/transacciones')}
                                                monto={this.state.pagado?.toMoney ? this.state.pagado.toMoney(true) : 0} 
                                                icon={(color) => <IconGraphic color={color} />} 
                                                percent={this.state.margen_pagado}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                    <Card className='card'>
                                        <Row className='card-header'>
                                            <Text className="card-title">Clientes por Hacienda</Text>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="center">
                                                <ReactECharts
                                                    option={haciendaChartOption}
                                                    style={{ width: '100%', minHeight: 450, height: '100%' }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                

                            </Row>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Row gutter={[12, 12]}>
                                <Col xs={24}>
                                    <Card className='card'>
                                        <Row className='card-header'>
                                            <Text className="card-title">Ventas por Vendedor</Text>
                                        </Row>
                                        <Row>
                                            <Col span={24} className="center">
                                                <ReactECharts
                                                    option={ventasChartOption}
                                                    style={{ width: '100%', maxWidth: 400 }}
                                                />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs={24}>
                                    <Card className='card'>
                                        <Row className='card-header'>
                                            <Text className="card-title">Ultimos Clientes</Text>
                                        </Row>
                                        <List
                                            loading={this.state.loading}
                                            itemLayout="horizontal"
                                            locale={{ emptyText: "Sin Clientes" }}
                                            dataSource={this.state.clientes}

                                            renderItem={item => (
                                                <Card className="cliente width-100">
                                                    <Row className="width-100 " align={"middle"} gutter={[16, 16]}>
                                                        <Col flex="none">
                                                            <CustomAvatar
                                                                className='cliente-avatar'
                                                                image={item.usuario_id?.avatar}
                                                                name={item.nombre}
                                                            />
                                                        </Col>
                                                        <Col flex="auto">
                                                            <Space direction="vertical" size={2}>
                                                                <Text className='cliente-nombre'>{item.nombre || ""} {item.apellido_paterno || ""}</Text>
                                                            </Space>
                                                        </Col>
                                                        <Col flex="none">
                                                            <Text className='cliente-fuente'>{
                                                                item.fuente === 1 ? 'Sistema' : 
                                                                item.fuente === 2 ? 'Facebook' :
                                                                'Instagram'
                                                            }</Text>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            )}
                                            />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();

    return <Dashboard {...props} navigate={navigate} params={params} />
}